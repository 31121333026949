import UtilsString from './String';

export const Authorize = {
  isAuthorize(authorize, userAuthorize) {
    let authorizeLv = 0;
    let userAuthorizeLv = 0;
    let msg = '';

    if (authorize === 'D') {
      authorizeLv = 4;
    } else if (authorize === 'C') {
      authorizeLv = 3;
    } else if (authorize === 'U') {
      authorizeLv = 2;
    } else if (authorize === 'R') {
      authorizeLv = 1;
    }

    if (userAuthorize === 'D') {
      userAuthorizeLv = 4;
    } else if (userAuthorize === 'C') {
      userAuthorizeLv = 3;
    } else if (userAuthorize === 'U') {
      userAuthorizeLv = 2;
    } else if (userAuthorize === 'R') {
      userAuthorizeLv = 1;
    }

    if (userAuthorizeLv < authorizeLv) {
      if (authorize === 'D') {
        msg = '삭제 권한이 없습니다.\n 관리자에게 문의해 주세요';
      } else if(authorize === 'C') {
        msg = '추가 권한이 없습니다.\n 관리자에게 문의해 주세요';
      } else if(authorize === 'U') {
        msg = '수정 권한이 없습니다.\n 관리자에게 문의해 주세요';
      } else {
        msg = '조회 권한이 없습니다.\n 관리자에게 문의해 주세요';
      }
      alert(msg);
      return false;
    }else {
      return true;
    }
  },
  getAuthorize(nav, name){
  /*  const main = [, 'EapMngCha', 'EapMngPay', 'BtocCs'];
    const sub = [ 'EapMngChaAft', 'EapMngChaBil',
            'EapMngPayAft', 'EapMngPayBil', 'BtocCsFaq', 'BtocCsOto']

    if(sub.filter(n=>n === name).length > 0) {
      main.forEach((item, index) => {
        if(name.indexOf(item) > -1) {
          name = item;
          return true;
        }
      });
    }*/

    let authorize = null;
    if (nav) {
      const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
      nav.some(row => {
        if (row.menuView && UtilsString.upperCase(row.menuView.replace(reg, "")) === UtilsString.upperCase(name.replace(reg, ""))) {
          authorize = row.auth;
          return true;
        }
      });
    }
    if(authorize != null) {
      authorize = authorize.substr(authorize.length - 1);
    } else { /*권한 자체가 NULL -> 조회 권한조차 없어 메뉴 리스트에서 누락 됨*/
     /* alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다. 관리자에게 문의해 주세요');*/
    }
    return authorize;
  }
}

export default Authorize;
