<template>
  <!-- modal : 내 센터 목록 start -->
  <div
    id="my_center"
    class="modal fade type_w400 show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="my_center"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            내 센터 목록
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <ul class="mycenter_list">
              <li
                v-for="(row,i) in centerList"
                :key="row.id"
              >
                <input
                  :id="`mycenter_${i}`"
                  v-model="selCenId"
                  type="radio"
                  name="mycenter"
                  :value="row.consCenterId"
                  @change="changeCenter(row)"
                >
                <label :for="`mycenter_${i}`">{{ row.centerNm ? row.centerNm : '-' }}</label>
              </li>
            </ul>
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="confirmDisabled"
            @click="saveData"
          >
            확인
          </button>
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal : 내 센터 목록 end -->
</template>
<script>
import EventBus from '@/eventBus/eventBus.js';
import localData from "@/api/localData";
export default {
    name: 'CenterPopup',
    props: {
      rowData: {
        type: Object,
        default: null,
        required: false
      },
      type: {
        type: String,
        default: null,
        required: false
      },
    },
    data() {
      return {
        centerList:[],
        selCenData:null,
        confirmDisabled:true,
        selCenId:null
      }
    },
    created(){
    },
    mounted(){
      this.selCenData = localData.getSelMyCenterData();
      if(localData.getMyCenterList()!=null){
        this.centerList = localData.getMyCenterList();
        this.fetchData();
      }
      if(this.type === 'login'){
        this.confirmDisabled = false;
      }
    },
    methods:{
      fetchData(){
        if(this.selCenData != null){
          this.selCenId = this.selCenData.consCenterId;
        }
      },
      changeCenter(item){
        this.selCenId = item.consCenterId;
        if(this.selCenData){
          if(this.selCenId !== this.selCenData.consCenterId){
            if(this.type != 'login'){
              this.confirmDisabled = false;
            }
          }else{
            if(this.type != 'login'){
              this.confirmDisabled = true;
            }
          }
        }else{
          if(this.type != 'login'){
            this.selCenId !== null ? this.confirmDisabled = false : this.confirmDisabled = true;
          }
        }
      },
      saveData(){
        if(this.selCenId === null){
          alert('센터를 선택해 주세요.');
          return;
        }
        if(confirm('센터를 변경 하시겠습니까?')){
          localData.setSelMyCenterData(this.centerList.find(n=>n.consCenterId === this.selCenId));
          alert('변경되었습니다.');
          EventBus.$emit('ExpMyCenterChange',{});
          this.close();
        }
      },
      close(){
          this.$emit("close");
      }
    }
}
</script>
