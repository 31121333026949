$(function(){


//네비 초기 설정 (활성화, arrow표시, depth class추가/설정)

	$('.navi > li').has('.sub_wrap').children('a').append('<i class="fas fa-angle-down"></i>');
	$('.navi > li > div > ul > li').has('.sub_wrap').children('a').append('<span></span>'); 

	$('.navi').find('a.on').parents('.sub_wrap').prev('a').addClass('open').addClass('on_view');
	if(!$('#wrapper').hasClass('off')){
		$('.navi').find('a.on').parents('.sub_wrap').show();
	}
	
	$('.navi > li > .sub_wrap').addClass('depth2_wrap');
	$('.navi > li > .sub_wrap > ul > li > .sub_wrap').addClass('depth3_wrap');

	var $navi_a = $('.navi a');


//네비 클릭
	$navi_a.click(function(){

		if($('#wrapper').hasClass('off')){	//접힌상태

			if(!$(this).next('.sub_wrap').length){
				$('.navi').find('a').removeClass('on on_view');
				$(this).addClass('on_view');
				$(this).addClass('on').parents('div').prev('a').addClass('on on_view');
			};
		}else{	//펼쳐진 상태

			if($(this).next('.sub_wrap').length){	//하위뎁스 있을 경우

				if($(this).hasClass('open')){	//하위가 열려있을경우
					$(this).closest('li').find('.sub_wrap').slideUp('fast').find('a').removeClass('open');		
					$(this).removeClass('open').next('.sub_wrap').slideUp('fast');
					
				}else{	//하위가 닫혀있을경우
					$(this).closest('ul').find('a').removeClass('open').next('.sub_wrap').slideUp('fast');

					if(!$(this).next('.depth3_wrap').length && $(this).closest('li').find('a.on').closest('.sub_wrap').hasClass('depth3_wrap')){		//활성화 노출
						$(this).closest('li').find('a.on').closest('.sub_wrap').show().prev('a').addClass('open');		
					};

					$(this).addClass('open').next('.sub_wrap').slideDown('fast');
				};

			}else{ //하위뎁스 없을 경우
					$navi_a.removeClass('on');
					$('.navi .on_view').removeClass('on_view');
					$(this).closest('ul').children('li').children('a').removeClass('open').next('.sub_wrap').slideUp('fast');
					$(this).addClass('on');		
					$(this).closest('.navi').children('li').children('a.open').addClass('on_view');					
			};
		};
	});


//메뉴 접기/펼치기

	$(document).on('click', '.btn_navi', function(){
		if($('#wrapper').hasClass('off')){ //펼치기
			$('#wrapper').removeClass('off');
			$(this).attr('title','메뉴 접기');
			$('.navi').find('.on_view').removeClass('on_view').addClass('on');
			$('.navi').find('.open').removeClass('open');
			$('.navi').find('a.on').parents('.sub_wrap').show().prev('a').addClass('open on_view');
			$('.navi').find('.depth3_wrap').find('a.on').closest('.sub_wrap').prev('a').removeClass('on');
			
		}else{	//접기
			$('#wrapper').addClass('off');
			$(this).attr('title','메뉴 펼치기');
			$('.navi').find('.depth3_wrap').find('a.on').closest('.sub_wrap').prev('a').addClass('on');
			$('.navi > li > a.on').addClass('on_view').removeClass('on');
			$('.navi > li > a.open').next('.sub_wrap').hide();
			$('.navi > li > div .sub_wrap').css('display','')
		};
		return false;
	});



//접었을때 3depth 위치
	$('.navi > li > .sub_wrap > ul > li').on('mouseover', function() {
		if($('#wrapper').hasClass('off')){
			$(this).find('.sub_wrap').css('top', $(this).position().top);
		};
	});

//접힌메뉴 1depth 오버
	$('.navi > li ').mouseenter(function(){
		if($('#wrapper').hasClass('off')){
			$(this).children('a').addClass('on');
			$(this).children('.sub_wrap').show()
		};
	});

	$('.navi > li ').mouseleave(function(){
		if($('#wrapper').hasClass('off')){
			$(this).children('a').removeClass('on');
			$(this).children('.sub_wrap').hide()
		};
	});

});
