<template>
  <!-- modal : 기존 사내상담사 start -->
  <div
    id="member_info"
    class="modal fade type_w700 m-hide-modal show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="member_info"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            회원정보 수정
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <!-- tab start -->
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  :class="{ active: v.tabIdx === 1 }"
                  @click.prevent.stop="moveTab(1)"
                >
                  기본 정보
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  :class="{ active: v.tabIdx === 2 }"
                  @click.prevent.stop="moveTab(2)"
                >
                  상담 정보
                </a>
              </li>
            </ul>
            <!--// tab end -->

            <!-- tab panes start -->
            <div class="tab-content">
              <!-- 기본 설정 start -->
              <div
                id="tab_cont11"
                class="tab-pane container fade  p-0 mt-30px"
                :class="{ active: v.tabIdx === 1, show: v.tabIdx === 1 }"
              >
                <!-- 항목 : 전문가 ID start -->
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10 necessity">전문가 ID</p><!-- 필수항목에 .necessity 추가 -->
                    <div class="flex_item">
                      <input
                        v-model="tab1.mbrId"
                        type="text"
                        class="form-control"
                        placeholder="전문가 ID 입력"
                        readonly
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 전문가 ID end -->

                <!-- 항목 : 이름 start -->
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10">이름</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.nm"
                        type="text"
                        class="form-control"
                        placeholder="이름 입력"
                        readonly
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 이름 end -->

                <!-- 항목 : 비밀번호 수정시 start -->
                <div class="form-item h-40">
                  <div class="type_label type_flex align-items-center">
                    <p class="w-100px pr-10">
                      비밀번호
                    </p>
                    <div class="flex_item input-group">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="showPassChange"
                      >
                        비밀번호 변경
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="sendTemplateEmail"
                      >
                        비밀번호 초기화
                      </button>
                    </div>
                  </div>
                </div>
                <!--// 항목 : 비밀번호 수정시 end -->

                <!-- 항목 : 비밀번호 start
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10">비밀번호</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.pass"
                        type="password"
                        class="form-control"
                        placeholder="비밀번호 입력"
                      >
                    </div>
                  </label>
                </div>
               // 항목 : 비밀번호 end

                 항목 : 비밀번호 start
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10">비밀번호 확인</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.passCheck"
                        type="password"
                        class="form-control"
                        placeholder="비밀번호 재입력"
                      >
                    </div>
                  </label>
                </div>
               // 항목 : 비밀번호 end -->

                <!-- 항목 : 생년월일/성별 start -->
                <div class="form-item mb-10px">
                  <div class="type_label type_flex align-items-center">
                    <p class="w-100px pr-10">
                      생년월일/성별
                    </p>
                    <div class="flex_item row">
                      <!-- 캘린더 -->
                      <label>
                        <div class="datepicker bx-ib none-drop-tui w-210px">
                          <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
                            <date-picker
                              ref="stDt"
                              v-model="tab1.birth"
                              :config="stDtConfOpt"
                              title="날짜 선택"
                              placeholder="날짜 선택"
                            />
                            <span class="tui-ico-date datepicker_icon" />
                            <div id="startpicker-containe2" />
                          </div>
                        </div>
                      </label>
                      <!-- //캘린더 -->

                      <div class="row align-items-center pl-30px m-pt-5px">
                        <div class="custom-control custom-radio mr-20px">
                          <input
                            id="customRadio11-1"
                            v-model="tab1.gen"
                            type="radio"
                            name="customRadio11"
                            class="custom-control-input"
                            value="XY"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio11-1"
                          >남자</label>
                        </div>
                        <div class="custom-control custom-radio mr-20px">
                          <input
                            id="customRadio11-2"
                            v-model="tab1.gen"
                            type="radio"
                            name="customRadio11"
                            class="custom-control-input"
                            value="XX"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio11-2"
                          >여자</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--// 항목 : 생년월일/성별 end -->

                <!-- 항목 : 휴대폰 start -->
                <div class="form-item mb-10px">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10">휴대폰</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.tel"
                        type="text"
                        class="form-control"
                        placeholder="휴대폰 번호 입력 ( - 포함 입력 )"
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 휴대폰 end -->

                <!-- 항목 : 이메일 start -->
                <div class="form-item mb-10px">
                  <label class="type_flex align-items-center">
                    <p class="w-100px pr-10">이메일</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.email"
                        type="text"
                        class="form-control"
                        placeholder="이메일 입력"
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 이메일 end -->

                <!-- 항목 : 프로필 사진 start -->
                <div class="form-item mb-10px">
                  <div class="type_label type_flex">
                    <p class="w-100px pr-10 pt-8px">
                      프로필 사진
                    </p>
                    <div class="flex_item">
                      <div class="row form-group">
                        <input
                          type="text"
                          :value="tab1.profileFileItem.profileFileNm ? tab1.profileFileItem.profileFileNm : '선택된 파일 없음'"
                          class="pseudo_input"
                          readonly=""
                        >
                        <label for="inputTypeFile11">파일 선택</label>
                        <input
                          id="inputTypeFile11"
                          ref="tab1.profileFile"
                          type="file"
                          class="form-control-file"
                          accept=".jpg, .png, .gif"
                          @change="previewProfileFile($event)"
                        >
                      </div>
                    </div>
                  </div>

                  <!-- 이미지 preview start -->
                  <div class=" pl-100px mt-10px m-p-0">
                    <div class="modal_preview">
                      <!-- 이미지 없을경우 a 에 .disabled 추가, pic_preview 에 .no-img 추가 -->
                      <a
                        :href="tab1.profileFileItem.profileFileUrl"
                        target="_blank"
                        class="d-inline-block"
                        title="프로필 사진"
                        :class="tab1.profileFileItem.profileFileUrl? '' : 'disabled'"
                      ><div
                        :class="tab1.profileFileItem.profileFileUrl? '' : 'no-img'"
                        class="border pic_preview vertical small m-auto"
                        :style="`background-image:url(${tab1.profileFileItem.profileFileUrl});`"
                      /></a>
                      <button
                        v-if="tab1.profileFileItem.profileFileUrl"
                        type="button"
                        class="btn_tbl_icon fas fa-trash-alt align-top"
                        title="삭제"
                        @click="deleteProfileFile"
                      />
                    </div>
                  </div>
                  <!--// 이미지 preview end -->
                </div>
                <!--// 항목 : 프로필 사진 end -->
              </div>
              <!--//  기본 설정 end -->



              <!-- 상담 정보 start -->
              <div
                id="tab_cont12"
                class="tab-pane container fade p-0 mt-30px"
                :class="{ active: v.tabIdx === 2, show: v.tabIdx === 2 }"
              >
                <!-- 전문가 정보 start -->
                <div>
                  <h5>전문가 정보</h5>

                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:180px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          전문가 구분<div class="text-lightgray mt-5px">
                            (중복 체크 가능)
                          </div>
                        </th>
                        <td class="text-center align-middle">
                          <div class="row">
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck13_1"
                                v-model="tab2.checkDepartCdmngId1"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck13_1"
                              >심리상담</label>
                            </div>
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck13_2"
                                v-model="tab2.checkDepartCdmngId2"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck13_2"
                              >언어치료</label>
                            </div>
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck13_3"
                                v-model="tab2.checkDepartCdmngId3"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck13_3"
                              >발달치료</label>
                            </div>
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck13_4"
                                v-model="tab2.checkDepartCdmngId4"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck13_4"
                              >재무상담</label>
                            </div>
                          </div>
                          <div class="mt-5px text-left text-danger pl-10px">
                            * 언어치료 및 발달치료 전문가는 관련 자격증 필수
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 전문가 정보end -->


                <!-- 자격정보 start -->
                <div class="mt-30px">
                  <h5>자격증 정보<span class="tit_desc">'대표 자격증' 선택시 최우선 노출됨</span></h5>

                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:80px">
                      <col>
                      <col style="width:130px">
                      <col style="width:160px">
                      <col style="width:40px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          대표 자격증
                        </th>
                        <th scope="col">
                          자격증
                        </th>
                        <th scope="col">
                          발급년도
                        </th>
                        <th scope="col">
                          자격증 파일
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click.prevent.stop="addCert"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab2.certList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="5"
                          >
                            등록된 자격증이 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(cert, index) in tab2.certList"
                          :key="cert.expCertId"
                        >
                          <td class="text-center align-middle">
                            <div class="custom-control custom-radio ml-10px">
                              <input
                                :id="`customRadio12-1_${index}`"
                                v-model="cert.mainViewYn"
                                type="radio"
                                name="customRadio12"
                                class="custom-control-input"
                                :value="1"
                              >
                              <label
                                class="custom-control-label"
                                :for="`customRadio12-1_${index}`"
                              />
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="cert.cdmngId"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectLicenseList"
                                placeholder="선택"
                                @input="changeLicense(cert, index)"
                              />
                            </div>
                            <!-- 자격증명 직접입력 선택시 노출  -->
                            <template v-if="cert.textOn === '1'">
                              <input
                                v-model="cert.text"
                                type="text"
                                class="form-control mt-5px"
                                placeholder="자격증명 직접 입력"
                              >
                            </template>
                          </td>
                          <td class="text-center">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="cert.certIssueDt"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectYearList"
                                :searchable="false"
                                :clearable="false"
                                placeholder="선택"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="form-group">
                                <input
                                  type="text"
                                  :value="cert.certificateFile.certFileNm ? cert.certificateFile.certFileNm : '선택된 파일 없음'"
                                  class="pseudo_input d-none"
                                  readonly=""
                                >
                                <label :for="`inputTypeFile2_1_${index}`">
                                  파일 선택
                                </label>
                                <input
                                  :id="`inputTypeFile2_1_${index}`"
                                  :ref="tab2.certificate"
                                  type="file"
                                  class="form-control-file"
                                  @change="previewCert($event, index)"
                                >
                              </div>
                              <!-- 자격증 파일 - 파일이 없는 경우 -->
                              <template v-if="!cert.certificateFile.certFileNm">
                                <span class="pl-10px flex_item text-lightgray">
                                  파일없음
                                </span>
                              </template>
                              <!-- 첨부파일이 등록되었을 경우 아래 버튼 2개 표시 -->
                              <template v-else-if="cert.certificateFile.certFileNm">
                                <span class="pl-10px flex_item">
                                  <button
                                    type="button"
                                    class="btn_tbl_icon fas fa-trash-alt m-0"
                                    title="첨부파일 삭제"
                                    @click="deleteCertFile(index)"
                                  />
                                  <button
                                    type="button"
                                    class="btn_tbl_icon fas fa-search-plus m-0"
                                    title="첨부파일 보기"
                                    @click="openBrWindow(cert.certificateFile.certFileUrl, '첨부파일 보기')"
                                  />
                                </span>
                              </template>
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click.prevent.stop="removeCert(cert.expCertId, index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 자격정보 end -->

                <!-- 최종학력 start -->
                <div class="mt-30px">
                  <h5>최종학력<span class="tit_desc">작성시 공공기관 대상 심리상담 사업에 자동 참여 혜택이 주어집니다.</span></h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col>
                      <col style="width:150px">
                      <col style="width:150px">
                      <col style="width:130px">
                      <col style="width:40px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          학교명
                        </th>
                        <th scope="col">
                          전공
                        </th>
                        <th scope="col">
                          학위
                        </th>
                        <th scope="col">
                          취득년도
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click.prevent.stop="addEducation"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab2.educationList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="5"
                          >
                            등록된 학력이 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(education, index) in tab2.educationList"
                          :key="index"
                        >
                          <td class="text-center align-middle">
                            <input
                              v-model="education.school"
                              type="text"
                              class="form-control"
                              placeholder="학교명 입력"
                              @input="education.school = $event.target.value;"
                            >
                          </td>
                          <td class="text-center align-middle">
                            <input
                              v-model="education.major"
                              type="text"
                              class="form-control"
                              placeholder="전공 입력"
                              @input="education.major = $event.target.value;"
                            >
                          </td>
                          <td class="text-center align-middle">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="education.degree"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectEducationList"
                                placeholder="선택"
                              />
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="education.degreeAcqYear"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectYearList"
                                placeholder="선택"
                              />
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click.prevent.stop="removeEducation(index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 최종학력 end -->


                <!-- 상담 관련 경력 start -->
                <div class="mt-30px">
                  <h5>상담 관련 경력<span class="tit_desc">작성시 마음의숲 기업상담사 채용시 우대합니다.</span></h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:270px">
                      <col>
                      <col style="width:40px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          입사/퇴사 년월
                        </th>
                        <th scope="col">
                          조직명 / 직책 및 업무내용
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click="addCareer"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab2.careerList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="3"
                          >
                            등록된 경력이 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(career, index) in tab2.careerList"
                          :key="index"
                        >
                          <td class="text-center align-middle">
                            <div class="w-150px d-inline-block">
                              <label>
                                <div class="datepicker bx-ib none-drop-tui mb-5px">
                                  <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
                                    <date-picker
                                      ref="caStDt"
                                      v-model="career.joinDt"
                                      :config="tab2.careerDtConfOpt"
                                      title="입사날짜 선택"
                                      placeholder="입사날짜 선택"
                                    />
                                    <span class="tui-ico-date datepicker_icon" />
                                    <div id="career-startpicker-container" />
                                  </div>
                                </div>
                              </label>
                              <label>
                                <div class="datepicker bx-ib none-drop-tui">
                                  <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
                                    <date-picker
                                      ref="caEnDt"
                                      v-model="career.leaveDt"
                                      :disabled="career.emplYn == 1"
                                      :config="tab2.careerDtConfOpt"
                                      title="퇴사날짜 선택"
                                      placeholder="퇴사날짜 선택"
                                    />
                                    <span class="tui-ico-date datepicker_icon" />
                                    <div id="career-endpicker-container" />
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox d-inline-block align-bottom mb-3px">
                              <input
                                :id="`customCheck5_1_${index}`"
                                v-model="career.emplYn"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="-1"
                                @change="inputEmplYn(index)"
                              >
                              <label
                                class="custom-control-label"
                                :for="`customCheck5_1_${index}`"
                              >재직중</label>
                            </div>
                          </td>

                          <td class="text-center align-middle">
                            <input
                              v-model="career.compNm"
                              type="text"
                              class="form-control"
                              placeholder="조직명 입력"
                              @input="career.compNm = $event.target.value;"
                            >
                            <textarea
                              v-model="career.taskCon"
                              class="form-control mt-5px"
                              rows="2"
                              placeholder="직책 및 업무내용 입력"
                              @input="career.taskCon = $event.target.value;"
                            />
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click="removeCareer(index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 상담 관련 경력 end -->


                <!-- 주제태그 및 전문분야 start -->
                <div class="mt-30px">
                  <h5>전문분야</h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:170px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          전문분야
                        </th>
                        <td>
                          <div>
                            <div class="input-group">
                              <div class="custom-multi-select w-200px">
                                <treeselect
                                  v-model="tab2.selectDepart"
                                  :multiple="true"
                                  :limit="0"
                                  :options="tab2.selectDepartList"
                                  placeholder="선택"
                                  :normalizer="normalizerDepart"
                                  @select="selDepart"
                                  @deselect="deselDepart"
                                  @input="clearDepart"
                                />
                              </div>
                              <input
                                v-model="tab2.selfDepart"
                                type="text"
                                class="form-control w-160px ml-10px"
                                placeholder="태그 직접 입력"
                              >
                              <button
                                type="button"
                                class="btn btn-primary"
                                @click="addSelfDepart"
                              >
                                확인
                              </button>
                            </div>
                            <ul class="mt-5px inline d-block">
                              <li
                                v-for="(tagDepart, i) in tab2.tagDepartList"
                                :key="i"
                              >
                                <div
                                  class="badge small"
                                  :class="tagDepart.cdmngId !== 573 && tagDepart.cdmngId !== 631 ? 'bg-ultramarine' : 'bg-bluegreen'"
                                >
                                  {{ tagDepart.codeNm }}
                                  <button
                                    type="button"
                                    title="삭제"
                                    @click.prevent.stop="removeDepartTag(i)"
                                  >
                                    <i class="fas fa-times-circle text-light" />
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 주제태그 및 전문분야 end -->

                <!-- 외국어 상담 start -->
                <div class="mt-30px">
                  <h5>외국어 상담</h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:170px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          외국어 가능여부
                        </th>
                        <td>
                          <div class="row">
                            <div class="custom-control custom-checkbox">
                              <input
                                id="customCheck6_1"
                                v-model="tab2.forLangConsYn"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="-1"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck6_1"
                              >가능</label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          가능 외국어
                        </th>
                        <td>
                          <input
                            v-model="tab2.speechLang"
                            type="text"
                            class="form-control flex_item"
                            placeholder="예) 영어, 러시아어"
                            @input="tab2.speechLang = $event.target.value;"
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 외국어 상담 end -->

                <!-- 전문가 소개 start -->
                <div class="mt-30px">
                  <div class="clearfix">
                    <div class="float-left pt-8px">
                      <h5 class="d-inline-block">
                        전문가 소개
                      </h5>
                      <div class="d-inline-block d-inline-block ml-20px">
                        <div class="custom-control d-inline-block custom-radio mr-20px">
                          <input
                            id="customRadio16-1"
                            v-model="tab2.easyWrYn"
                            type="radio"
                            name="customRadio16"
                            class="custom-control-input"
                            value="-1"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio16-1"
                          >간단 작성 (항목별 입력)</label>
                        </div>
                        <div class="custom-control d-inline-block custom-radio mr-20px">
                          <input
                            id="customRadio16-2"
                            v-model="tab2.easyWrYn"
                            type="radio"
                            name="customRadio16"
                            class="custom-control-input"
                            value="1"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio16-2"
                          >상세 작성 (에디터 사용)</label>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="tab2.easyWrYn === '1'"
                      class="float-right"
                    >
                      <!-- '상세 작성'일 경우에만 노출 -->
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        @click="showExpertJoinTemplate"
                      >
                        기본 템플릿 수정
                      </button>
                    </div>
                  </div>

                  <!-- 간단 작성 start -->
                  <div
                    v-if="tab2.easyWrYn === '-1'"
                    class="mt-5px"
                  >
                    <table class="tbl_type01 mt-5px">
                      <colgroup>
                        <col style="width:170px">
                        <col>
                      </colgroup>
                      <tbody>
                        <tr>
                          <th scope="row">
                            메인 타이틀
                          </th>
                          <td>
                            <div class="mb-5px">
                              한 문장으로 나의 상담 스타일을 표현해주세요. 최상단에 가장 큰 글씨로 들어갑니다.
                            </div>
                            <input
                              v-model="tab2.easyWrMainTit"
                              type="text"
                              class="form-control"
                              placeholder="메인 타이틀 입력"
                              @input="tab2.easyWrMainTit = $event.target.value;"
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            서브 타이틀 1
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 한 문장으로 표현해주세요.
                            </div>
                            <input
                              v-model="tab2.easyWrSubTit1"
                              type="text"
                              class="form-control"
                              placeholder="서브 타이틀 1 입력"
                              @input="tab2.easyWrSubTit1 = $event.target.value;"
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            본문 1
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 자유롭게 표현해주세요.
                            </div>
                            <textarea
                              v-model="tab2.easyWrCon1"
                              class="form-control"
                              rows="3"
                              placeholder="본문 1 입력"
                              @input="tab2.easyWrCon1 = $event.target.value;"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            서브 타이틀 2
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 한 문장으로 표현해주세요.
                            </div>
                            <input
                              v-model="tab2.easyWrSubTit2"
                              type="text"
                              class="form-control"
                              placeholder="서브 타이틀 2 입력"
                              @input="tab2.easyWrSubTit2 = $event.target.value;"
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            본문 2
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 자유롭게 표현해주세요.
                            </div>
                            <textarea
                              v-model="tab2.easyWrCon2"
                              class="form-control"
                              rows="3"
                              placeholder="본문 2 입력"
                              @input="tab2.easyWrCon2 = $event.target.value;"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            이미지
                          </th>
                          <td>
                            <div class="mb-5px">
                              이미지로 내 소개글을 꾸미고 싶다면 추가해주세요
                            </div>
                            <div class="row form-group">
                              <input
                                type="text"
                                :value="tab2.introFileItem.introFileNm ? tab2.introFileItem.introFileNm : '선택된 파일 없음'"
                                class="pseudo_input"
                                readonly=""
                              >
                              <label for="inputTypeFile1">파일 선택</label>
                              <input
                                id="inputTypeFile1"
                                :ref="tab2.introFile"
                                type="file"
                                class="form-control-file"
                                accept=".jpg, .png, .gif"
                                @change="previewIntroFile($event)"
                              >
                            </div>
                            <!-- 이미지 preview start -->
                            <div class="mt-10px m-p-0">
                              <div class="modal_preview">
                                <!-- 이미지 없을경우 a 에 .disabled 추가, pic_preview 에 .no-img 추가 -->
                                <a
                                  :href="tab2.introFileItem.introFileUrl"
                                  target="_blank"
                                  class="d-inline-block"
                                  title="프로필 사진"
                                  :class="tab2.introFileItem.introFileUrl? '' : 'disabled'"
                                ><div
                                  :class="tab2.introFileItem.introFileUrl? '' : 'no-img'"
                                  class="border pic_preview small m-auto"
                                  :style="`background-image:url(${tab2.introFileItem.introFileUrl});`"
                                /></a>
                                <button
                                  v-if="tab2.introFileItem.introFileUrl"
                                  type="button"
                                  class="btn_tbl_icon fas fa-trash-alt align-top"
                                  title="삭제"
                                  @click="deleteIntroFile"
                                />
                              </div>
                            </div>
                          <!--// 이미지 preview end -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--// 간단 작성 end -->


                  <!-- 상세 작성 start -->
                  <div
                    v-else-if="tab2.easyWrYn === '1'"
                    class="mt-5px border h-350px"
                  >
                    <editor
                      ref="toastuiEditor"
                      height="350px"
                      :options="defaultOptions"
                      initial-edit-type="wysiwyg"
                      :initial-value="tab2.intro"
                      @change="onChange($event)"
                    />
                  </div>
                  <!--// 상세 작성 end -->
                </div>
                <!--// 전문가 소개 end -->
              </div>
              <!--// 상담 정보 end -->
            </div>
            <!--// tab panes end -->
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn"
            :class="waitSave ? 'btn-primary icon_loading disabled' : 'btn-secondary'"
            :disabled="confirmDisabled && !fileChange && !editorChange"
            @click="saveData"
          >
            {{ waitSave ? '수정중' : '확인' }}
          </button><!-- 내용을 수정했을 경우 disabled 삭제하여 버튼 활성화 -->
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal : 기존 사내상담사 end -->
</template>
<script>
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import ExpertComInfoService from "../../services/expert/ExpertComInfoService";
import localData from "@/api/localData";
import moment from "moment";
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import ExpertPassChange from "./ExpertPassChange";
import ExpertInfoService from "../../services/expert/ExpertInfoService";

moment.locale("ko"); // 오전/오후 사용
export default {
  name: 'ExpertComInfoEdit',
  components: {
    editor: Editor,
  },
  data(){
    return {
      normalizerDepart(node) {
        return {
          id: node.cdmngId,
          label: node.codeNm
        }
      },
      defaultOptions:{
        customHTMLRenderer: {
          htmlBlock: {
            iframe(node) {
              return [
                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                { type: 'html', content: node.childrenHTML },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
              ];
            },
          },
          htmlInline: {
            big(node, { entering }) {
              return entering
                ? { type: 'openTag', tagName: 'big', attributes: node.attrs }
                : { type: 'closeTag', tagName: 'big' };
            },
          },
        },
        /*hooks: {
          addImageBlobHook: (blob, callback) => {
            console.log('TESTING TESTING')
            const uploadedImageURL = this.uploadImage(blob)
            callback(uploadedImageURL, 'alt text')
            return false
          },
        },*/
        hideModeSwitch: false,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock'],
          ['scrollSync']
        ],
        plugins:[colorSyntax]
      },
      v: {
        tabIdx: 1
      },
      confirmDisabled: true,
      fileChange: false,
      editorChange:false,
      startFlag:false,
      loginId: '',
      inteMbrId: '',
      keyListTab1: [
        'mbrId', 'nm', 'pass', 'passCheck', 'birth', 'gen', 'tel', 'email'
      ],
      tab1: { // 기본정보
        deleteProfile: -1,
        idCheck: false,
        birth: null,
        gen: 'XY',
        mbrId: '',
        nm: '',
        pass: '',
        email: '',
        tel: '',
        passCheck: '',
        profileFile: null,
        profileFileItem: {
          'profileFile': null,
          'profileFileUrl': null,
          'profileFileNm': null,
          'profileFileDesc': null,
          'profileFileSize': null,
          'profileFileMngId': null
        },
      },
      storeTab1: {
        deleteProfile: -1,
        idCheck: false,
        birth: null,
        gen: 'XY',
        mbrId: '',
        nm: '',
        pass: '',
        email: '',
        tel: '',
        passCheck: '',
        profileFile: null,
        profileFileItem: {
          'profileFile': null,
          'profileFileUrl': null,
          'profileFileNm': null,
          'profileFileDesc': null,
          'profileFileSize': null,
          'profileFileMngId': null
        },
      },
      keyListTab2: [
        'checkDepartCdmngId1', 'checkDepartCdmngId2', 'checkDepartCdmngId3', 'checkDepartCdmngId4',
        'speechLang', 'forLangConsYn',
        'easyWrYn', 'easyWrMainTit', 'easyWrSubTit1', 'easyWrCon1', 'easyWrSubTit2', 'easyWrCon2',

      ],
      tab2: { // 상세정보
        easyWrCon2: '',
        easyWrSubTit2: '',
        easyWrCon1: '',
        easyWrSubTit1: '',
        easyWrMainTit: '',
        intro: '',
        checkDepartCdmngId1: '0',
        checkDepartCdmngId2: '0',
        checkDepartCdmngId3: '0',
        checkDepartCdmngId4: '0',
        checkNonfaceDepart1: '0',
        checkNonfaceDepart2: '0',
        checkNonfaceDepart3: '0',
        selectLicenseList:[],
        selectLicenseListTextOn: 0,
        selectLicenseListText: null,
        selectYearList:[],
        deleteCertList: [],
        certList: [],
        educationList: [],
        selectEducationList:[
          {
            'id': '학사',
            'label':'학사'
          },
          {
            'id': '석사',
            'label':'석사'
          },
          {
            'id': '석사_재학',
            'label':'석사_재학'
          },
          {
            'id': '석사_수료',
            'label':'석사_수료'
          },
          {
            'id': '석사_졸업',
            'label':'석사_졸업'
          },
          {
            'id': '박사',
            'label':'박사'
          },
          {
            'id': '박사_재학',
            'label':'박사_재학'
          },
          {
            'id': '박사_수료',
            'label':'박사_수료'
          }
        ],
        careerList: [],
        careerDtConfOpt: {
          format: "YYYY-MM",
          useCurrent: false,
          maxDate: "2900-01",
          dayViewHeaderFormat:'YYYY년 MMMM',
          locale:'ko',
          showClear: true,
          icons: {
            time: "far fa-clock",
            date: "far fa-calendar",
            up: "fas fa-arrow-up",
            down: "fas fa-arrow-down",
            previous: "fas fa-chevron-left",
            next: "fas fa-chevron-right",
            today: "fas fa-calendar-check",
            clear: "far fa-trash-alt",
            close: "far fa-times-circle"
          }
        },
        selectTopic: null,
        tagTopicList:[],
        selectTopicList:[],
        selfTopic: null,
        tagDepartList:[],
        selectDepartList:[],
        selectDepart: null,
        selfDepart: null,
        forLangConsYn: -1,
        speechLang: null,
        easyWrYn: '-1',
        introFile: null,
        deleteIntroFile: -1,
        introFileItem: {
          'introFile': null,
          'introFileUrl': null,
          'introFileNm': null,
          'introFileDesc': null,
          'introFileSize': null,
          'introFileMngId': null
        },
      },
      storeTab2: {
        easyWrCon2: '',
        easyWrSubTit2: '',
        easyWrCon1: '',
        easyWrSubTit1: '',
        easyWrMainTit: '',
        intro: '',
        checkDepartCdmngId1: '0',
        checkDepartCdmngId2: '0',
        checkDepartCdmngId3: '0',
        checkDepartCdmngId4: '0',
        checkNonfaceDepart1: '0',
        checkNonfaceDepart2: '0',
        checkNonfaceDepart3: '0',
        selectLicenseList:[],
        selectLicenseListTextOn: 0,
        selectLicenseListText: null,
        selectYearList:[],
        deleteCertList: [],
        certList: [],
        educationList: [],
        selectEducationList:[
          {
            'id': '학사',
            'label':'학사'
          },
          {
            'id': '석사',
            'label':'석사'
          },
          {
            'id': '석사_재학',
            'label':'석사_재학'
          },
          {
            'id': '석사_수료',
            'label':'석사_수료'
          },
          {
            'id': '석사_졸업',
            'label':'석사_졸업'
          },
          {
            'id': '박사',
            'label':'박사'
          },
          {
            'id': '박사_재학',
            'label':'박사_재학'
          },
          {
            'id': '박사_수료',
            'label':'박사_수료'
          }
        ],
        careerList: [],
        careerDtConfOpt: {
          format: "YYYY-MM",
          useCurrent: false,
          maxDate: "2900-01",
          dayViewHeaderFormat:'YYYY년 MMMM',
          locale:'ko',
          showClear: true,
          icons: {
            time: "far fa-clock",
            date: "far fa-calendar",
            up: "fas fa-arrow-up",
            down: "fas fa-arrow-down",
            previous: "fas fa-chevron-left",
            next: "fas fa-chevron-right",
            today: "fas fa-calendar-check",
            clear: "far fa-trash-alt",
            close: "far fa-times-circle"
          }
        },
        selectTopic: null,
        tagTopicList:[],
        selectTopicList:[],
        selfTopic: null,
        tagDepartList:[],
        selectDepartList:[],
        selectDepart: null,
        selfDepart: null,
        forLangConsYn: -1,
        speechLang: null,
        easyWrYn: '-1',
        introFile: null,
        deleteIntroFile: -1,
        introFileItem: {
          'introFile': null,
          'introFileUrl': null,
          'introFileNm': null,
          'introFileDesc': null,
          'introFileSize': null,
          'introFileMngId': null
        },
      },
      stDtConfOpt: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        maxDate: "2900-01-01",
        dayViewHeaderFormat:'YYYY년 MMMM',
        locale:'ko',
        showClear: true,
        icons: {
          time: "far fa-clock",
          date: "far fa-calendar",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
          today: "fas fa-calendar-check",
          clear: "far fa-trash-alt",
          close: "far fa-times-circle"
        }
      },
      waitSave:false
    }
  },
  computed:{

  },
  watch: {
    tab1: {
      deep: true,
      handler() {
        let disabled = true;
        this.keyListTab1.some(key=>{
          if((this.storeTab1[key] != null && this.tab1[key] != this.storeTab1[key])||(this.storeTab1[key] == null && this.tab1[key])){
            disabled = false;
            return true;
          }
        })
        this.confirmDisabled = disabled === true;
      }
    },
    tab2: {
      deep: true,
      handler() {
        let disabled = true;
        this.keyListTab2.some(key=>{
          if((this.storeTab2[key] != null && this.tab2[key] != this.storeTab2[key])||(this.storeTab2[key] == null && this.tab2[key])){
            disabled = false;
            return true;
          }
        })
        this.confirmDisabled = disabled === true;
      }
    },
    'tab2.certList': {
      deep:true,
      handler(){
        let disabled = true;
        let currArray, storeArray, currView, storeView = [];
        let currList = [...this.tab2.certList];
        let storeList = [...this.storeTab2.certList];
        currArray = currList.length >0 ? currList.map(n=>n.expCertId): [];
        storeArray = storeList.length >0 ? storeList.map(n=>n.expCertId): [];
        if(currArray.toString() != storeArray.toString()){
          disabled = false;
        }
        currView = currList.length >0 ? currList.sort((a,b)=>a.expCertId-b.expCertId): [];
        storeView = storeList.length >0 ? storeList.sort((a,b)=>a.expCertId-b.expCertId): [];
        if(storeView.length>0){
          storeView.some((row,i)=>{
            if(currView[i]) {
              if(row.mainViewYn != currView[i].mainViewYn){
                disabled = false
                return true;
              }
              if(row.cdmngId != currView[i].cdmngId){
                disabled = false
                return true;
              }
              if(row.text != currView[i].text){
                disabled = false
                return true;
              }
              if(row.certIssueDt != currView[i].certIssueDt){
                disabled = false
                return true;
              }
            }
          });
        }
        this.confirmDisabled = disabled === true;
      }
    },
    'tab2.educationList': {
      deep:true,
      handler(){
        let disabled = true;
        let currArray, storeArray, currView, storeView = [];
        let currList = [...this.tab2.educationList];
        let storeList = [...this.storeTab2.educationList];
        currArray = currList.length >0 ? currList.map(n=>n.school): [];
        storeArray = storeList.length >0 ? storeList.map(n=>n.school): [];
        if(currArray.toString() != storeArray.toString()){
          disabled = false;
        }
        currView = currList.length >0 ? currList.sort((a,b)=>a.school-b.school): [];
        storeView = storeList.length >0 ? storeList.sort((a,b)=>a.school-b.school): [];
        if(storeView.length>0){
          storeView.some((row,i)=>{
            if(currView[i]) {
              if(row.school != currView[i].school){
                disabled = false
                return true;
              }
              if(row.major != currView[i].major){
                disabled = false
                return true;
              }
              if(row.degree != currView[i].degree){
                disabled = false
                return true;
              }
              if(row.degreeAcqYear != currView[i].degreeAcqYear){
                disabled = false
                return true;
              }
            }else {
              disabled = false
              return true;
            }
          });
        }
        this.confirmDisabled = disabled === true;
      }
    },
    'tab2.careerList': {
      deep:true,
      handler(){
        let disabled = true;
        let currArray, storeArray, currView, storeView = [];
        let currList = [...this.tab2.careerList];
        let storeList = [...this.storeTab2.careerList];
        currArray = currList.length >0 ? currList.map(n=>n.compNm): [];
        storeArray = storeList.length >0 ? storeList.map(n=>n.compNm): [];
        if(currArray.toString() != storeArray.toString()){
          disabled = false;
        }
        currView = currList.length >0 ? currList.sort((a,b)=>a.compNm-b.compNm): [];
        storeView = storeList.length >0 ? storeList.sort((a,b)=>a.compNm-b.compNm): [];
        if(storeView.length>0){
          storeView.some((row,i)=>{
            if(currView[i]) {
              if(row.joinDt != currView[i].joinDt){
                disabled = false
                return true;
              }
              if(row.leaveDt != currView[i].leaveDt){
                disabled = false
                return true;
              }
              if(row.emplYn != currView[i].emplYn){
                disabled = false
                return true;
              }
              if(row.compNm != currView[i].compNm){
                disabled = false
                return true;
              }
              if(row.taskCon != currView[i].taskCon){
                disabled = false
                return true;
              }
              if(row.degreeAcqYear != currView[i].degreeAcqYear){
                disabled = false
                return true;
              }
            }else {
              disabled = false
              return true;
            }
          });
        }
        this.confirmDisabled = disabled === true;
      }
    },
    'tab2.tagDepartList': {
      deep:true,
      handler(){
        let disabled = true;
        let currArray, storeArray = [];
        let currList = [...this.tab2.tagDepartList];
        let storeList = [...this.storeTab2.tagDepartList];
        currArray = currList.length >0 ? currList.map(n=>n.cdmngId): [];
        storeArray = storeList.length >0 ? storeList.map(n=>n.cdmngId): [];
        if(currArray.toString() != storeArray.toString()){
          disabled = false;
        }
        this.confirmDisabled = disabled === true;
      }
    },
    'tab2.selectDepart': {
      deep:true,
      handler(){
        let disabled = true;
        let currArray, storeArray = [];
        let currList = [...this.tab2.selectDepart];
        let storeList = [...this.storeTab2.selectDepart];
        currArray = currList.length >0 ? currList.map(n=>n.cdmngId): [];
        storeArray = storeList.length >0 ? storeList.map(n=>n.cdmngId): [];
        if(currArray.toString() != storeArray.toString()){
          disabled = false;
        }
        this.confirmDisabled = disabled === true;
      }
    },
  },
  destroyed(){

  },
  created(){

  },
  mounted(){
    if(localData.getInteMbrId()!=null){
      this.loginId = localData.getInteMbrId();
    }
    this.fetchTab1Data();
  },
  methods:{
    sendTemplateEmail() {
      if(confirm('비밀번호를 초기화 하시겠습니까?')) {
        let inteMbrId = [];
        inteMbrId.push(this.loginId);
        let params = {
          inteMbrId: inteMbrId,
          templateId: 'eap_user_change_password',
        };
        ExpertComInfoService.sendTemplateEmail(params).then(response => {
          const { resultCode } = response.data;
          alert(resultCode === 'OK' ? '초기화된 비밀번호를 등록하신 메일로 보냈습니다.\n잠시후 확인해 주세요.' : '초기화를 실패했습니다.\n 관리자에게 문의해 주세요.');
        })
      }
    },
    showPassChange() {
      this.$modal.show(
        ExpertPassChange,
        {
        },
        {
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    inputEmplYn(index) {
      if(this.tab2.careerList[index].emplYn == 1) {
        this.tab2.careerList[index].leaveDt = null;
      }
    },
    onChange(e){
      let html = this.$refs.toastuiEditor.invoke('getHTML');
      if(this.startFlag === true && (this.storeTab2.intro != null && this.storeTab2.intro != html) || (this.storeTab2.intro == null && html)){
        this.editorChange = true;
      }else{
        this.editorChange = false;
      }
    },
    openBrWindow(imgUrl, name){
      if(!imgUrl) return;
      imgUrl = this.$http.defaults.baseURL + imgUrl;
      let image = new Image();
      image.src = imgUrl;
      let w = window.open("",name,'width=539,height=341');
      w.document.write(image.outerHTML);
    },
    fetchYearList() {
      this.tab2.selectYearList = [];
      let year = moment().format('YYYY');
      for (let i = 1950; i <= year; i++) {
        this.tab2.selectYearList.push({
          label: i,
          id: i
        });
      }
      this.tab2.selectYearList.push({
        label: '정보없음',
        id: -1
      });
      this.tab2.selectYearList = this.tab2.selectYearList.reverse();
    },
    fetchLicenseList() {
      ExpertComInfoService.expertComInfoLicenseList().then(response=> {
        this.tab2.selectLicenseList = [];
        const {data} = response.data;
        data.forEach(item => {
          this.tab2.selectLicenseList.push({
            id: item.cdmngId,
            label: item.code
          });
        });
      });
    },
    deleteIntroFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        if(this.tab2.introFileItem.introFileMngId){
          this.fileChange = true;
          this.tab2.deleteIntroFile = 1;
        }
        this.tab2.introFileItem.introFile = null;
        this.tab2.introFileItem.introFileUrl = null;
        this.tab2.introFileItem.introFileNm = null;
        this.tab2.introFileItem.introFileDesc = null;
        this.tab2.introFileItem.introFileSize = null;
        this.tab2.introFileItem.introFileMngId = null;
      }
    },
    previewIntroFile(event) {
      this.fileChange = true;
      let inputImg = event.target;
      this.tab2.introFileItem.introFile = inputImg.files[0];
      if( inputImg.files && inputImg.files[0] ){
        this.tab2.introFileItem.introFileNm = inputImg.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab2.introFileItem.introFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputImg.files[0]);
      }
    },
    showExpertJoinTemplate() {
    /*  this.$modal.show(
        ExpertJoinTemplate,{
        },
        {
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );*/
    },
    removeDepartTag(index) {
      if(confirm('['+this.tab2.tagDepartList[index].codeNm+'] 전문분야를 삭제하시겠습니까?')) {
        let idx = this.tab2.selectDepart.findIndex(n => n === this.tab2.tagDepartList[index].cdmngId);
        if ( idx !== -1) {
          this.tab2.selectDepart.splice(idx,1);
        }
        this.tab2.tagDepartList.splice(index, 1);
      }
    },
    addSelfDepart() {
      if(this.tab2.selfDepart) {
        this.tab2.tagDepartList.push({
          cdmngId: 631,
          codeNm: this.tab2.selfDepart
        });
        this.tab2.selfDepart = null;
      }
    },
    clearDepart(node) {
      if(node.length === 0){
        this.tab2.tagDepartList = [];
      }
    },
    deselDepart(node) {
      let idx = this.tab2.tagDepartList.findIndex(n => n.cdmngId === node.cdmngId);
      if (idx !== -1) {
        this.tab2.tagDepartList.splice(idx,1);
      }
      let index = this.tab2.selectDepart.findIndex(n => n === node.cdmngId);
      if (index !== -1) {
        this.tab2.selectDepart.splice(index,1);
      }
    },
    selDepart(node) {
      if(this.tab2.tagDepartList.includes(node.cdmngId)) {
        alert('중복된 전문분야입니다.');
      }else {
        this.tab2.tagDepartList.push(node);
      }
    },
    removeCareer(index) {
      this.tab2.careerList.splice(index, 1);
    },
    addCareer() {
      this.tab2.careerList.push({
        joinDt: null,
        leaveDt: null,
        compNm: null,
        taskCon: null,
        emplYn: -1,
      });
    },
    removeEducation(index) {
      this.tab2.educationList.splice(index, 1);
    },
    addEducation() {
      this.tab2.educationList.push({
        school: null,
        major: null,
        degree: null,
        degreeAcqYear: null
      });
    },
    removeCert(expCertId, index) {
      if(this.tab2.certList[index].expCertId) {
        this.tab2.deleteCertList.push(this.tab2.certList[index]);
      }
      this.tab2.certList.splice(index, 1);
    },
    deleteCertFile(index) {
      if(confirm('파일을 삭제하시겠습니까?')) {
        if(this.tab2.certList[index].expCertId) {
          this.fileChange = true;
          this.tab2.certList[index].deleteCertFile = 1;
        }
        this.tab2.certList[index].certificateFile.certFile = null;
        this.tab2.certList[index].certificateFile.certFileUrl = null;
        this.tab2.certList[index].certificateFile.certFileNm = null;
        this.tab2.certList[index].certificateFile.certFileDesc = null;
        this.tab2.certList[index].certificateFile.certFileSize = null;
        this.tab2.certList[index].certificateFile.certFileMngId = null;
      }
    },
    previewCert(event, index) {
      this.fileChange = true;
      let inputFile = event.target;
      this.tab2.certList[index].certificateFile.certFile = inputFile.files[0];
      if( inputFile.files && inputFile.files[0] ){
        this.tab2.certList[index].certificateFile.certFileNm = inputFile.files[0].name;
        this.tab2.certList[index].certificateFile.certFileSize = inputFile.files[0].size;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab2.certList[index].certificateFile.certFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputFile.files[0]);
      }
    },
    changeLicense(cert, index) {
      this.tab2.certList[index].text = null;
      if(this.tab2.certList[index].cdmngId === 557) {
        this.tab2.certList[index].textOn = '1';
      }else {
        this.tab2.certList[index].textOn = '0';
      }
    },
    addCert() {
      this.tab2.certList.push({
        textOn: '0',
        text: null,
        expCertId: null,
        mainViewYn: -1,
        cdmngId: null,
        certNm: null,
        certIssueDt: null,
        certificate: null,
        deleteCertFile: -1,
        certificateFile: {
          'certFile': null,
          'certFileUrl': null,
          'certFileNm': null,
          'certFileDesc': null,
          'certFileSize': null,
          'certFileMngId': null
        }
      });
    },
    saveData() {
      if(this.v.tabIdx === 1){
        this.saveTab1();
      }else if(this.v.tabIdx === 2){
        this.saveTab2();
      }
    },
    saveTab1() {
      if(this.tab1.tel){
        if(this.tab1.tel.indexOf('-') == -1){
          alert("휴대폰 번호를 - 포함해서 입력해주세요.");
          return;
        }
      }

      let tab1Form = new FormData();

      tab1Form.append('expertTab1.expTp', localData.getExpTp());//사내 전문가
      // 프로필 사진 삭제
      if(this.tab1.deleteProfile !== -1) {
        tab1Form.append(`expertTab1.deleteProfile`, this.tab1.deleteProfile);
      }
      // 프로필 사진
      if(this.tab1.profileFileItem.profileFile) {
        tab1Form.append('expertTab1.profileImg', this.tab1.profileFileItem.profileFile);
      }
      // 전문가 ID
      tab1Form.append('expertTab1.inteMbrId', this.loginId);
      // 비밀번호
      if(this.tab1.pass && this.tab1.pass.trim() != ''){
        tab1Form.append('expertTab1.pwd', this.tab1.pass);
      }
      // 이름
      tab1Form.append('expertTab1.nm', this.tab1.nm);
      // 생년월일
      tab1Form.append('expertTab1.birth',this.tab1.birth? this.tab1.birth.replaceAll('-', '') : '');
      // 성별
      tab1Form.append('expertTab1.gen', this.tab1.gen);
      // 휴대폰
      tab1Form.append('expertTab1.tel', this.tab1.tel? this.tab1.tel : '');
      // 이메일
      tab1Form.append('expertTab1.email', this.tab1.email? this.tab1.email : '');
      // 작성자
      tab1Form.append('expertTab1.regId', this.loginId);
      tab1Form.append('expertTab1.modId', this.loginId);

      this.waitSave = true;
      ExpertComInfoService.expertComInfoTab1Update(tab1Form).then(response => {
        const data = response.data;
        this.inteMbrId = data.data.inteMbrId;
        alert(data.resultCode === 'ERROR' ? '수정을 실패했습니다.' : '수정 되었습니다.');
        this.confirmDisabled = true;
        this.fileChange = false;
        this.editorChange = false;
        this.fetchTab1Data();
        this.waitSave = false;
      }).catch(err => {
        this.waitSave = false;
      })
    },
    settingCareerReqDt(data) {
      if(data) {
        return data.replace('-', '');
      }else {
        return null;
      }
    },
    saveTab2() {
      let tab2Form = new FormData();
      tab2Form.append(`ExpertTab2.inteMbrId`, this.loginId);
      tab2Form.append(`ExpertTab2.regId`,  this.loginId);
      tab2Form.append(`ExpertTab2.modId`,  this.loginId);

      // 자격증 정보 리스트 - 대표 자격증, 자격증, 발급년도, 자격증파일
      if(this.tab2.deleteCertList.length > 0) {
        this.tab2.deleteCertList.forEach((item, index) => {
          tab2Form.append(`ExpertTab2.deleteCertList[${index}].expCertId`, item.expCertId);
        })
      }
      this.tab2.certList.forEach((item, index) => {
        if(item.cdmngId) {
          this.tab2.selectLicenseList.forEach((item2, index2) => {
            if(item.cdmngId === item2.id) {
              if(item.expCertId) {
                tab2Form.append(`ExpertTab2.certList[${index}].expCertId`, item.expCertId);
              }
              tab2Form.append(`ExpertTab2.certList[${index}].inteMbrId`, this.loginId);
              tab2Form.append(`ExpertTab2.certList[${index}].certNm`, item2.label);
              tab2Form.append(`ExpertTab2.certList[${index}].certIssueDt`, item.certIssueDt);
              tab2Form.append(`ExpertTab2.certList[${index}].mainViewYn`, item.mainViewYn);
              tab2Form.append(`ExpertTab2.certList[${index}].regId`,  this.loginId);
              tab2Form.append(`ExpertTab2.certList[${index}].cdmngId`,  item.cdmngId);

              if(item.certificateFile.certFile) {
                tab2Form.append(`ExpertTab2.certList[${index}].certificate`,  item.certificateFile.certFile);
              }
              if(item.deleteCertFile !== -1) {
                tab2Form.append(`ExpertTab2.certList[${index}].deleteCertFile`,  item.deleteCertFile);
              }
            }
          });
        }
      });

      // 전문가 구분 -  tpDiv: 11 심리상담, 12 언어치료, 13 발달치료, 14 재무상담
      let expertFieldIndex = 0;
      let deleteExpertFieldIndex = 0;
      if(this.tab2.checkDepartCdmngId1 === '1') { //전문가 구분 + 심리상담 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 11);
        expertFieldIndex++;
      }else {
        tab2Form.append(`ExpertTab2.deleteExpertFieldList[${deleteExpertFieldIndex}].tpDiv`, 11);
        deleteExpertFieldIndex++;
      }
      if(this.tab2.checkDepartCdmngId2 === '1') { //전문가 구분 + 언어치료 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 12);
        expertFieldIndex++;
      }else {
        tab2Form.append(`ExpertTab2.deleteExpertFieldList[${deleteExpertFieldIndex}].tpDiv`, 12);
        deleteExpertFieldIndex++;
      }
      if(this.tab2.checkDepartCdmngId3 === '1') { //전문가 구분 + 발달치료 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 13);
        expertFieldIndex++;
      }else {
        tab2Form.append(`ExpertTab2.deleteExpertFieldList[${deleteExpertFieldIndex}].tpDiv`, 13);
        deleteExpertFieldIndex++;
      }
      if(this.tab2.checkDepartCdmngId4 === '1') { //전문가 구분 + 재무상담 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 14);
        expertFieldIndex++;
      }else {
        tab2Form.append(`ExpertTab2.deleteExpertFieldList[${deleteExpertFieldIndex}].tpDiv`, 14);
        deleteExpertFieldIndex++;
      }

      // 최종 학력 리스트 - 학교명, 전공, 학위, 취득년도
      this.tab2.educationList.forEach((item, index) => {
        if(item.school) {
          tab2Form.append(`ExpertTab2.educationList[${index}].school`, item.school);
          tab2Form.append(`ExpertTab2.educationList[${index}].major`, item.major);
          tab2Form.append(`ExpertTab2.educationList[${index}].degree`, item.degree);
          tab2Form.append(`ExpertTab2.educationList[${index}].degreeAcqYear`, item.degreeAcqYear);
        }
      });

      // 상담 관련 경력 리스트 - 입사년도, 퇴사년도, 재직중여부, 회사명, 직책 및 업무내용
      this.tab2.careerList.forEach((item, index) => {
        if(item.compNm) {
          tab2Form.append(`ExpertTab2.careerList[${index}].joinDt`, this.settingCareerReqDt(item.joinDt) ? this.settingCareerReqDt(item.joinDt) : -1);
          tab2Form.append(`ExpertTab2.careerList[${index}].leaveDt`, this.settingCareerReqDt(item.leaveDt) ? this.settingCareerReqDt(item.leaveDt) : -1);
          tab2Form.append(`ExpertTab2.careerList[${index}].compNm`, item.compNm);
          tab2Form.append(`ExpertTab2.careerList[${index}].taskCon`, item.taskCon);
          tab2Form.append(`ExpertTab2.careerList[${index}].emplYn`, item.emplYn); // 재직중 추가.
        }
      });

      // 전문분야 리스트 departList
      this.tab2.tagDepartList.forEach((item, index) => {
        tab2Form.append(`ExpertTab2.departList[${index}].cdmngId`, item.cdmngId);
        tab2Form.append(`ExpertTab2.departList[${index}].codeNm`, item.codeNm);
        tab2Form.append(`ExpertTab2.departList[${index}].othCodeNm`, '');
      });

      // 외국어 상담 - 외국어 가능 여부(1, -1), 가능 외국어, 외국어 금액
      tab2Form.append(`ExpertTab2.forLangConsYn`, this.tab2.forLangConsYn);
      tab2Form.append(`ExpertTab2.speechLang`, this.tab2.speechLang? this.tab2.speechLang : '');

      // 전문가 상세 소개
      tab2Form.append(`ExpertTab2.easyWrYn`, this.tab2.easyWrYn);
      if(this.tab2.easyWrYn === '-1') { // 간편작성
        if(this.tab2.easyWrMainTit) {
          tab2Form.append(`ExpertTab2.easyWrMainTit`, this.tab2.easyWrMainTit);
        }
        if(this.tab2.easyWrSubTit1) {
          tab2Form.append(`ExpertTab2.easyWrSubTit1`, this.tab2.easyWrSubTit1);
        }
        if(this.tab2.easyWrCon1) {
          tab2Form.append(`ExpertTab2.easyWrCon1`, this.tab2.easyWrCon1);
        }
        if(this.tab2.easyWrSubTit2) {
          tab2Form.append(`ExpertTab2.easyWrSubTit2`, this.tab2.easyWrSubTit2);
        }
        if(this.tab2.easyWrCon2) {
          tab2Form.append(`ExpertTab2.easyWrCon2`, this.tab2.easyWrCon2);
        }
        if(this.tab2.deleteIntroFile !== -1) {
          tab2Form.append(`ExpertTab2.deleteIntroFile`, this.tab2.deleteIntroFile);
        }
        if(this.tab2.introFileItem.introFile) {
          tab2Form.append('ExpertTab2.introFile', this.tab2.introFileItem.introFile);
        }
      }else { // 세부작성 - 에디터
        if(this.$refs.toastuiEditor.invoke('getHTML') != null && this.$refs.toastuiEditor.invoke('getHTML') != ''){
          tab2Form.append('ExpertTab2.intro',this.$refs.toastuiEditor.invoke('getHTML'));
        }
      }
      this.waitSave = true;
      ExpertComInfoService.expertComInfoTab2Update(tab2Form).then(response => {
        const data = response.data;
        alert(data.resultCode === 'ERROR' ? '수정을 실패했습니다.' : '수정 되었습니다.');
        this.fetchTab2Data();
        this.confirmDisabled = true;
        this.editorChange = false;
        this.fileChange = false;
        this.waitSave = false;
      }).catch(err => {
        this.waitSave = false;
      })
    },
    settingBirth(data) {
      if(data && data !== '0') {
        return data.toString().substring(0,4)+'-'+data.toString().substring(4,6)+'-'+data.toString().substring(6,8);
      }else {
        return  null;
      }
    },
    previewProfileFile(event) {
      this.fileChange = true;
      let inputImg = event.target;
      this.tab1.profileFileItem.profileFile = inputImg.files[0];
      if( inputImg.files && inputImg.files[0] ){
        this.tab1.profileFileItem.profileFileNm = inputImg.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab1.profileFileItem.profileFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputImg.files[0]);
      }
    },
    deleteProfileFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        if(this.tab1.profileFileItem.profileFileMngId != null){
          this.fileChange = true;
          this.tab1.deleteProfile = 1; // 파일 삭제 flag 값.
        }
        this.tab1.profileFileItem.profileFile = null;
        this.tab1.profileFileItem.profileFileUrl = null;
        this.tab1.profileFileItem.profileFileNm = null;
        this.tab1.profileFileItem.profileFileDesc = null;
        this.tab1.profileFileItem.profileFileSize = null;
        this.tab1.profileFileItem.profileFileMngId = null;
      }
    },
    fetchTab1Data() {
      ExpertComInfoService.expertComInfoTab1Read(this.loginId).then(response=> {
        const {expertTab1} = response.data.data;
        if(expertTab1.profileFileList.length > 0){
          let expertTab1Profile = expertTab1.profileFileList[0];
          this.tab1.profileFileItem.profileFile = null;
          this.tab1.profileFileItem.profileFileUrl = this.$http.defaults.baseURL + expertTab1Profile.previewUrl;
          this.tab1.profileFileItem.profileFileNm = expertTab1Profile.fileNm;
          this.tab1.profileFileItem.profileFileDesc = expertTab1Profile.fileDesc;
          this.tab1.profileFileItem.profileFileSize = expertTab1Profile.fileSize;
          this.tab1.profileFileItem.profileFileMngId = expertTab1Profile.fileMngId;
        }
        this.tab1.mbrId = expertTab1.mbrId;
        this.tab1.nm = expertTab1.nm;
        this.tab1.birth = this.settingBirth(expertTab1.birth);
        this.tab1.gen = expertTab1.gen;
        this.tab1.tel = expertTab1.tel;
        this.tab1.email = expertTab1.email;


        this.storeTab1 = JSON.parse(JSON.stringify(this.tab1));
        this.startFlag = true;
      });
    },
    fetchDepartList() {
      ExpertComInfoService.expertComInfoDepartList().then(response=> {
        this.tab2.selectDepartList = [];
        const {data} = response.data;
        if(data) {
          /*this.tab2.selectDepartList = data.filter(n=>n.codeNm.indexOf('기타') === -1);*/
          data.forEach((item, index) => {
            if(item.codeNm && item.codeNm.indexOf('기타') === -1) {
              this.tab2.selectDepartList.push(item);
            }
          });
        }
      });
    },
    settingCareerDt(data) {
      if(data) {
        return  String(data).substring(0, 4) + '-' + String(data).substring(4, 6);
      }else {
        return  null;
      }
    },
    fetchTab2Data() {
      this.fetchLicenseList();  // 라이센스 리스트
      this.fetchDepartList(); // 전문분야 리스트
      this.fetchYearList();   // 연도 리스트

      ExpertComInfoService.expertComInfoTab2Read(this.loginId).then(response=> {
        this.tab2.introFileItem={'introFile': null,'introFileUrl': null,'introFileNm': null,'introFileDesc': null,
          'introFileSize': null,'introFileMngId': null};
        const {expertTab2} = response.data.data;

        // 전문가 구분
        expertTab2.expertFieldList.forEach(item => {
          // tp: 1이 전문가
          // tpDiv: 11 심리상담, 12 언어치료, 13 발달치료, 14 재무상담
          if(item.tp === 1 && item.tpDiv === 11) {
            this.tab2.checkDepartCdmngId1 = '1';
          }
          if(item.tp === 1 && item.tpDiv === 12) {
            this.tab2.checkDepartCdmngId2 = '1';
          }
          if(item.tp === 1 && item.tpDiv === 13) {
            this.tab2.checkDepartCdmngId3 = '1';
          }
          if(item.tp === 1 && item.tpDiv === 14) {
            this.tab2.checkDepartCdmngId4 = '1';
          }
        });

        // 자격증 정보
        if(expertTab2.certList.length > 0) {
          this.tab2.certList = [];
          expertTab2.certList.forEach(item => {
            // 대표자격증여부, 자격증 번호 or 자격증명, 발급년도, 자격증파일
            this.tab2.certList.push({
              textOn: item.cdmngId === 557 ? '1' : '0',
              text: null,
              expCertId: item.expCertId,
              mainViewYn: item.mainViewYn,
              cdmngId: item.cdmngId,
              certNm: item.certNm,
              deleteCertFile: -1,
              certIssueDt: item.certIssueDt? item.certIssueDt : null,
              certificateFile:
                item.certificateFile.length > 0? {
                  'certFile': item.certificateFile[0].certFile,
                  'certFileUrl': item.certificateFile[0].previewUrl,
                  'certFileNm': item.certificateFile[0].fileNm,
                  'certFileDesc': item.certificateFile[0].fileDesc,
                  'certFileSize': item.certificateFile[0].fileSize,
                  'certFileMngId': item.certificateFile[0].fileMngId,
                } : {
                  'certFile': null,
                  'certFileUrl': null,
                  'certFileNm': null,
                  'certFileDesc': null,
                  'certFileSize': null,
                  'certFileMngId': null,
                }
            });
          });
        }

        // 학력 정보
        if(expertTab2.educationList.length > 0) {
          this.tab2.educationList = [];
          expertTab2.educationList.forEach(item => {
            this.tab2.educationList.push({
              school: item.school,
              major: item.major,
              degree: item.degree,
              degreeAcqYear: item.degreeAcqYear
            });
          });
        }

        // 경력 정보
        if(expertTab2.careerList.length > 0 ) {
          this.tab2.careerList = [];
          expertTab2.careerList.forEach(item => {
            this.tab2.careerList.push({
              joinDt: this.settingCareerDt(item.joinDt) && item.joinDt != -1 ? this.settingCareerDt(item.joinDt) : null,
              leaveDt: this.settingCareerDt(item.leaveDt) && item.leaveDt != -1 ? this.settingCareerDt(item.leaveDt) : null,
              compNm: item.compNm,
              taskCon: item.taskCon,
              emplYn: item.emplYn
            });
          });
        }

        // 전문분야
        this.tab2.selectDepart = [];
        this.tab2.tagDepartList = [];
        if(expertTab2.departList && expertTab2.departList.length > 0) {
          expertTab2.departList.forEach(item => {
            if(item.cdmngId !== 573 && item.cdmngId !== 631) { // 직접 입력이 아닌 것만
              this.tab2.selectDepart.push(item.cdmngId);
            }
            this.tab2.tagDepartList.push(item);
          });
        }

        // 외국어 상담가능여부 및 외국어
        this.tab2.forLangConsYn = expertTab2.forLangConsYn;
        this.tab2.speechLang = expertTab2.speechLang ? expertTab2.speechLang : '';

        // 전문가 소개
        this.tab2.easyWrYn = expertTab2.easyWrYn;
        if(this.tab2.easyWrYn === '-1') {
          this.tab2.easyWrMainTit = expertTab2.easyWrMainTit ? expertTab2.easyWrMainTit : '';
          this.tab2.easyWrSubTit1 = expertTab2.easyWrSubTit1 ? expertTab2.easyWrSubTit1 : '';
          this.tab2.easyWrCon1 = expertTab2.easyWrCon1 ? expertTab2.easyWrCon1 : '';
          this.tab2.easyWrSubTit2 = expertTab2.easyWrSubTit2 ? expertTab2.easyWrSubTit2 : '';
          this.tab2.easyWrCon2 = expertTab2.easyWrCon2 ? expertTab2.easyWrCon2 : '';

          if(expertTab2.introFileList.length > 0){
            let expertTab3intro = expertTab2.introFileList[0];
            this.tab2.introFileItem.introFile = null;
            this.tab2.introFileItem.introFileUrl = this.$http.defaults.baseURL + expertTab3intro.previewUrl;
            this.tab2.introFileItem.introFileNm = expertTab3intro.fileNm;
            this.tab2.introFileItem.introFileDesc = expertTab3intro.fileDesc;
            this.tab2.introFileItem.introFileSize = expertTab3intro.fileSize;
            this.tab2.introFileItem.introFileMngId = expertTab3intro.fileMngId;
          }
        }else {
          if(expertTab2.intro){      //에디터
            this.tab2.intro = expertTab2.intro;
         /*   this.$refs.toastuiEditor.invoke('setHTML', this.tab2.intro);*/
          }
        }

        this.storeTab2 = JSON.parse(JSON.stringify(this.tab2));
        this.startFlag = true;
      });
    },
    moveTab(tabNum) {
      this.confirmDisabled = true;
      this.fileChange = false;
      if(tabNum === 1){
        this.v.tabIdx = 1;
        this.fetchTab1Data();
      }else if(tabNum === 2) {
        this.v.tabIdx = 2;
        this.fetchTab2Data();
      }
    },
    close(){
      this.$emit("close");
    }
  }
}

</script>
