<template>
  <body class="login_body">
    <div class="login_area_wrap">
      <div class="login_area">
        <h1>mind-on 전문가용 관리도구</h1>
        <div>
          <form
            id="form_login"
            @submit.prevent="login()"
          >
            <div class="d-flex">
              <div class="login_input_wrap flex_item pr-10px">
                <div class="login_input">
                  <input
                    v-model="user"
                    type="text"
                    placeholder="ID"
                  >
                  <span class="login_icon fas fa-user-tag" />
                </div>
                <div class="login_input">
                  <input
                    v-model="password"
                    type="password"
                    placeholder="Password"
                  >
                  <span class="login_icon fas fa-key" />
                </div>

                <div class="form_foot">
                  <div class="custom-control d-inline-block  align-middle">
                    <input
                      id="id_save"
                      v-model="idSave"
                      type="checkbox"
                      class="custom-control-input "
                      true-value="1"
                      false-value="-1"
                    >
                    <label
                      class="custom-control-label"
                      for="id_save"
                    >아이디 저장</label>
                  </div>
                  <button
                    type="button"
                    class="d-inline-block align-middle ml-20px m-d-none"
                    @click="showExpertJoin"
                  >
                    <i class="fas fa-user-plus mr-5px" />회원가입
                  </button>
                </div>
              </div>


              <button
                type="submit"
                class="login_btn btn-secondary"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="login_footer">
      Copyright © <strong>mind-on</strong>. All rights reserved.
    </div>
  </body>
</template>

<script>
import LoginService from '@/services/LoginService';
import EventBus from '@/eventBus/eventBus.js';
import localData from '../api/localData';
import ExpertJoinPop from "../components/expert/ExpertJoinPop";
import CommonService from "../services/common/CommonService";
import {initToken} from "@/utils/FirebaseService";

export default {
  name: 'Login',
  data() {
    return {
      loginSuccess: false,
      loginError: false,
      user: this.$cookies.get("expertId") ? this.$cookies.get("expertId") : '',
      password: '',
      error: false,
      idSave: '-1',
    }
  },
  mounted(){
    document.title = '전문가 관리도구';
  },
  methods: {
    async login() {
      let form = new FormData();
      form.append('username', this.user);
      form.append('password',this.password);
      const params =  {
        'mbrId': this.user,
        'pwd': this.password,
        'loginTp': '20',
      };
      this.$http.post('/api/login', params)
      .then(response => {
        if(response){
          const {data} = response;
          localData.setUserId(data.mbrId);
          localData.setInteMbrId(data.inteMbrId);
          localData.setExpTp(data.expTp); // 전문가타입 (1:외부, -1사내)
          localData.setSaveUserData(data);
          //파이어 베이스 푸시토큰
          initToken();
          /*로그인한 유저 역할에 부여된 권한 리스트 저장*/
          if(localData.getSaveUserData()) {
            let roleId = data.roleId;
            CommonService.authRoleExpList(roleId).then(response => {
              const {data} = response.data;
              if(data && data[0]) {
                localData.setSaveMenuData(data[0].userAuths);
              }
            });
          }
          if(this.idSave == 1) {
            this.$cookies.remove('expertId');
            this.$cookies.set("expertId", this.user);
          }
          this.movePage(data);
        }else{
          alert("아이디 및 비밀번호를 다시 확인해 주세요.");
        }
      }).catch(err => {
        alert("아이디 및 비밀번호를 다시 확인해 주세요.");
      })
    },
    movePage(data){
      LoginService.getMyMenuList().then(response => {
        if(response){
          const {data} = response.data;
          if(data.length>0){
            this.$router.push({ path: data[0].url });
          }
          EventBus.$emit("login", data);
        }
      }).catch(error => {
      })
    },
    showExpertJoin() {
      this.$modal.show(
        ExpertJoinPop,
        {
        },
        {
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
  }
}

</script>
