
export const localData = {
  getUserMenuAuth(routeName) {
    let main = ['ExpCouVou','ExpMyCon','ExpMyCom','ExpAiRec', 'ExpMyRev','ExpCoun'];
    let sub = ['ExpCouVouCus','ExpMyConDetail','ExpMyConCal','ExpMyComDet','ExpAiRecRes', 'ExpMyRevTalk','ExpCounTalk'];

    if(sub.filter(n=>n === routeName).length > 0) {
      main.forEach((item, index) => {
        if(routeName.indexOf(item) !== -1) {
          routeName = item;
        }
      })
    }

    let result = false;
    let saveMenuData = this.getSaveMenuData();
    if(saveMenuData) {
      saveMenuData.forEach((item, index) => {
        if(item.menuView === routeName) {
          result = true;
          return true;
        }
      })
    }
    if(routeName === 'Login') {
      result = true;
    }
    return result;
  },
  setUserId(userId){
    localStorage.setItem('dqMindExpertUserId', JSON.stringify(userId));
  },
  getUserId(){
    let userId = localStorage.getItem('dqMindExpertUserId');
    if(userId) {
      try{
        userId = JSON.parse(userId);
        return userId;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeUserId() {
    localStorage.removeItem('dqMindExpertUserId');
  },
  setInteMbrId(userId){
    localStorage.setItem('dqMindExpertInteMbrId', JSON.stringify(userId));
  },
  getInteMbrId(){
    let userId = localStorage.getItem('dqMindExpertInteMbrId');
    if(userId) {
      try{
        userId = JSON.parse(userId);
        return userId;
      } catch {
        return null;
      }
    }
    return null;
  },
  setExpTp(expTp){
    localStorage.setItem('dqMindExpertUserExpTp', JSON.stringify(expTp));
  },
  getExpTp(){
    let expTp = localStorage.getItem('dqMindExpertUserExpTp');
    if(expTp) {
      try{
        expTp = JSON.parse(expTp);
        return expTp;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeExpTp() {
    localStorage.removeItem('dqMindExpertUserExpTp');
  },
  removeInteMbrId() {
    localStorage.removeItem('dqMindExpertInteMbrId');
  },
  setSaveUserData(userData){
    localStorage.setItem('mindonExpertUserData', JSON.stringify(userData));
  },
  getSaveUserData(){
    let userData = localStorage.getItem('mindonExpertUserData');
    if(userData) {
      try{
        userData = JSON.parse(userData);
        return userData;
      } catch {
        return null;
      }
    }
    return null;
  },
  setSaveMenuData(menuData){
    localStorage.setItem('mindonExpertMenuData', JSON.stringify(menuData));
  },
  getSaveMenuData(){
    let menuData = localStorage.getItem('mindonExpertMenuData');
    if(menuData) {
      try{
        menuData = JSON.parse(menuData);
        return menuData;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeSaveUserData() {
    localStorage.removeItem('mindonExpertUserData');
  },
  removeSaveMenuData() {
    localStorage.removeItem('mindonExpertMenuData');
  },
  //로그인한 전문가 센터목록
  setMyCenterList(centerList){
    localStorage.setItem('mindonExpertMyCenList', JSON.stringify(centerList));
  },
  getMyCenterList(){
    let centerList = localStorage.getItem('mindonExpertMyCenList');
    if(centerList) {
      try{
        centerList = JSON.parse(centerList);
        return centerList;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeMyCenterList() {
    localStorage.removeItem('mindonExpertMyCenList');
  },
  setSelMyCenterData(myCenter){
    localStorage.setItem('mindonExpertSelMyCen', JSON.stringify(myCenter));
  },
  getSelMyCenterData(){
    let myCenter = localStorage.getItem('mindonExpertSelMyCen');
    if(myCenter) {
      try{
        myCenter = JSON.parse(myCenter);
        return myCenter;
      } catch {
        return null;
      }
    }
    return null;
  },
  removeSelMyCenterData() {
    localStorage.removeItem('mindonExpertSelMyCen');
  },
}

export default localData;
