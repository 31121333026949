<template>
  <div
    v-if="type === 'switch'"
    :class="switchClass"
    :style="switchStyle"
  >
    <span class="text-lightgray">{{ falseText }}</span>
    <div
      class="custom-control custom-switch switch-toggle"
      :title="titleText"
    >
      <input
        id="customSwitches1-1"
        v-model="switchModel"
        type="checkbox"
        :class="classes"
        :disabled="disabled"
        :true-value="trueValue"
        :false-value="falseValue"
        @change.prevent.stop="onChange"
      >
      <label
        class="custom-control-label"
        for="customSwitches1-1"
      />
    </div>
    <span class="text-primary">{{ trueText }}</span>
  </div>


  <button
    v-else-if="type === 'mobile'"
    type="button"
    :class="classes"
    :disabled="disabled"
    :title="titleText"
    @click.prevent.stop="onClick"
  >
    <span
      v-if="spanClass"
      :class="spanClass"
    >
      {{ text ? text : '' }}
    </span>
    <template v-else>
      {{ text ? text : '' }}
    </template>
    <span class="m-only-inblock">
      {{ text2 ? text2 : '' }}
    </span>
  </button>

  <span
    v-else-if="type === 'icon'"
    :title="titleText"
  >
    <button
      type="button"
      :class="iconClasses"
      :title="titleText"
      @click.prevent.stop="onClick"
    />
  </span>
  <router-link
    :is="disabled ? 'span' : 'router-link'"
    v-else-if="type === 'router'"
    :to="{ name: routerNm}"
    class="dash-sub-tit"
    :class="{'router-color':disabled}"
    :active-class="''"
  >
    <span>{{ spanText }} <span
      v-if="countText !== null"
      class="text-primary"
    >({{ $n(countText) }}건)</span></span><i
      class="fas fa-arrow-circle-right ml-5px mt-2px"
      :title="titleText"
    />
  </router-link>
  <button
    v-else
    type="button"
    :class="classes"
    :disabled="disabled"
    :title="titleText"
    @click.prevent.stop="onClick"
  >
    <i
      v-if="icon"
      :class="icon"
    />{{ text ? text : '' }}
  </button>
</template>
<script>
export default {
  name: 'UiButton',
  props: {
    text: {
      type: String,
      default: null
    },
    spanClass: {
      type: String,
      default: null
    },
    text2: {
      type: String,
      default: null
    },
    switchModelData: {
      type: String,
      default: null
    },
    trueValue: {
      type: String,
      default: null
    },
    falseValue: {
      type: String,
      default: null
    },
    trueText: {
      type: String,
      default: null
    },
    falseText: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    buttonClass: {
      type: String,
      default: null
    },
    switchClass: {
      type: String,
      default: null
    },
    switchStyle: {
      type: String,
      default: null
    },
    authorize: {
      type: String,
      default: null
    },
    userAuthorize: {
      type: String,
      default: null
    },
    routerNm:{
      type: String,
      default: null
    },
    spanText:{
      type: String,
      default: null
    },
    countText:{
      type: Number,
      default: null
    }
  },
  data() {
    return {
      switchModel:'-1',
      titleText: '',
      classValue: '',
      click: false,
      disabled: false,
      auth: [
        {
          auth: 'D',
          authText: '삭제',
          dep: 4,
        },
        {
          auth: 'C',
          authText: '추가',
          dep: 3,
        },
        {
          auth: 'U',
          authText: '수정',
          dep: 2,
        },
        {
          auth: 'R',
          authText: '조회',
          dep: 1,
        },
      ]
    }
  },
  computed: {
    vModelData() {
      let vModelData = '';
      if(this.vModel) {
        vModelData = this.vModel;
      }
      return vModelData;
    },
    classes() {
      let classValue = ""
      if ( this.type != null ) {
        classValue = `btn btn-${this.type}`;
      }
      if ( this.buttonClass ) {
        classValue = classValue+' '+ this.buttonClass;
      }
      return classValue;
    },
    iconClasses() {
      let classValue = ""
      if ( this.icon != null ) {
        classValue = this.icon;
      }
      if ( this.disabled ) {
        classValue = classValue+' disabled';
      }
      return classValue;
    },
  },
  watch:{
    switchModelData:{
      handler(){
        this.switchModel = this.switchModelData;
      }
    }
  },
  mounted() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      /*
      - 실행(X) > 삭제(D) > 추가(C)  > 수정(U) > 조회(R)
      - 2022-04 기준 실행(X) 사용 안 함. 최상위 권한은 삭제(D)
     */
      if(this.$route.params.saveuserdata.roleId == '16') {
        this.click = true;
        this.titleText = this.title;
      } else {
        let authorizeLv = 0;
        let userAuthorizeLv = 0;
        this.auth.forEach((item, index) => {
          if(this.authorize === item.auth) {
            authorizeLv = item.dep;
          }
          if (this.userAuthorize === item.auth) {
            userAuthorizeLv = item.dep;
          }
        });
        if (userAuthorizeLv < authorizeLv) {
          this.auth.forEach((item, index) => {
            if (this.authorize === item.auth) {
              this.titleText =  item.authText +' 권한이 없습니다.';
              this.disabled = true;
            }
          })
          this.click = false;

        } else {
          this.click = true;
          /*this.titleText = this.title + ' 권한 존재';*/
          this.titleText = this.title;
        }
      }
    },
    onClick(e) {
     if(this.click) {
        this.$emit('click', e);
      }
    },
    onChange(e) {
      this.$emit('switchModelChange', this.switchModel);

      if(this.click) {
        this.$emit('change', e);
      }
    }
  }
}
</script>
<style scoped>
.auth-disabled {
  cursor:pointer  !important;
}
.router-color{
  color: #666;
}
</style>
