export const String = {
  // string 중 줄바꿈(엔터) 문자가 있으면 br 로 치환
  replaceEnter(str) {
    str = str.replace(/(\t)/g, ' ');
    return str.replace(/(\n|\r\n|\r)/g, '<br>');
  },

  replaceComma(str) {
    return str.replace(/,/gi,'%2C');
  },

  // string 중 공백 문자가 있으면 모두 제거후 string 반환
  deleteWhitespace(str) {
    return str.replace(/\s/gi, "");
  },

  // string 이 null, undefined 이면 '' 아니면 string 반환
  replaceNull(str) {
    return (str === null || typeof str === 'undefined') ? '' : str;
  },

  replaceTab(str) {
    return str.replace(/(?:\t)/g,'\n');
  },

  // 2개의 string 이 동일한 문자 인지 체크 ( 대소문자 조건에 포함 )
  equals(str1, str2) {
    return (str1 === str2);
  },

  // 2개의 string 이 동일한 문자 인지 체크 ( 대소문자 무시 )
  equalsIgnoreCase(str1, str2) {
    return (str1.toLowerCase() === str2.toLowerCase());
  },

  // http/https 체크
  isUrl(str) {
    var expUrl = /^http[s]?\:\/\//i;
    if ( expUrl.test(str) ) {
      return true;
    } else {
      return false;
    }
  },

  // string 소문자 변환
  lowerCase(str) {
    return str.toLowerCase();
  },

  // string 대문자 변환
  upperCase(str) {
    return str.toUpperCase();
  },

  // string 좌우 공백 제거
  trim(str) {
    return str.trim();
  }
}

export default String;
