$(function(){

$('html').click(function() {
	hideOptmenu();
 });

//옵션 메뉴 show
function openOptmenu(btn_this) {
	btn_this.addClass('on');
	btn_this.next('ul').fadeIn(100);
	btn_this.closest('.opt_menu').css('z-index','2');
	btn_this.closest('.node').css('z-index','10');
	$('.html_area').hide();
	$('.btn.type_dropdown').removeClass('active');
	return false;
};

//옵션 메뉴 hide
function hideOptmenu() {
	$('.opt_menu ul').fadeOut(100);
	$('.opt_menu').css('z-index','1');
	$('.btn_opt_menu').removeClass('on');
	$('.node').css('z-index','1');
};

//옵션 메뉴 클릭
	$(document).on('click', '.btn_opt_menu', function() {
		var btn_this = $(this);
		openOptmenu(btn_this);
	});


//목록 전체 체크/해제 (th 순서)
	$(".selectall").click(function(){ 
		var this_check_name = $(this).attr('name');
		var check_idx = $(this).closest('tr').find('th').index(this.closest('th'));
		var check_idx_td = $(this).closest('table').find('tbody > tr').find('td:eq('+check_idx+')');

		//alert(check_idx);

		if($(this).prop("checked") == true){
			check_idx_td.find('input[type=checkbox]').prop('checked',true); 
		}else if($(this). prop("checked") == false){
			check_idx_td.find('input[type=checkbox]').prop('checked',false); 
		};
	});

	$('table:not(.tree-table) > tbody input[type=checkbox]').click(function(){ 
		var this_check = $(this);
		master_check(this_check);
	});

	master_check = function(this_check) {

		if(!this_check.hasClass('single_toggle')) {
			var check_idx2 = this_check.closest('tr').find('td').index(this_check.closest('td'));
			var check_idx_td2 = this_check.closest('table').find('tbody > tr').find('td:eq('+check_idx2+')');
			var check_idx_th2 = this_check.closest('table').find('thead > tr').find('th:eq('+check_idx2+')');

			var on_qty = check_idx_td2.find('input[type=checkbox]').length;
			var on_qty_all = check_idx_td2.find('input[type=checkbox]:checked').length;
			
			//alert(on_qty + "/" + on_qty_all);

			if(on_qty != on_qty_all){
				check_idx_th2.find('.selectall').prop('checked',false); 
			}else if (on_qty==on_qty_all){
				check_idx_th2.find('.selectall').prop('checked',true); 
			}
	
		}

	};

//전체체크2
	$('.selectall2').click(function(){
		if($(this).prop("checked") == true){
			$(this).closest('.selectall2_wrap').children('.selectall2_list').find('input[type=checkbox]').prop('checked',true); 
		}else if($(this). prop("checked") == false){
			$(this).closest('.selectall2_wrap').children('.selectall2_list').find('input[type=checkbox]').prop('checked',false); 
		};
	});

	$('.selectall2_list input[type=checkbox]').click(function(){ 
		var this_check = $(this);
		master_check2(this_check);
	});

	master_check2 = function(this_check) {

		var on_qty = this_check.closest('.selectall2_list').find('input[type=checkbox]').length;
		var on_qty_all = this_check.closest('.selectall2_list').find('input[type=checkbox]:checked').length;
		
		//alert(on_qty + "/" + on_qty_all);

		if(on_qty != on_qty_all){
			this_check.closest('.selectall2_wrap').find('.selectall2').prop('checked',false); 
		}else if (on_qty==on_qty_all){
			this_check.closest('.selectall2_wrap').find('.selectall2').prop('checked',true); 
		};

	};

//첨부파일 (input type=file)
	$('.form-control-file').on('change',function(){
		var fileName = $(this).val();
		$(this).closest('.form-group').children('.pseudo_input').val(fileName);
	})

//select value 에 따른 show/hide
	$(document).find('.select_sh').each(function(e){
		var this_value = this.value;
		var this_id = $(this).attr('id');
		$('.'+this_id+'[name='+this_value+']').show();
	});

	$('.select_sh').change(function(){
		var this_value = this.value;
		var this_id = $(this).attr('id');
		$('.'+this_id+'[name='+this_value+']').slideDown('fast');
		$('.'+this_id+':not([name='+this_value+'])').slideUp('fast');
	});

});

//팝업 오픈
function openBrWindow(theURL,winName,features) {
	window.open(theURL,winName,features);
}
