<template>
  <!-- modal : 사용자사전 추가/수정 start -->
  <div class="dialog-content modal type_tab show">
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            class="modal-title"
          >
            {{ title ? title : $t('title') }}
          </h5>
          <button
            type="button"
            class="close"
            @click.prevent.stop="close"
          >
            <span aria-hidden="true">
              ×
            </span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <!-- tab panes start -->
            <div class="tab-content">
              <!-- 기본정보 start -->
              <div
                v-if="text"
                class="tab-pane container active"
              >
                <p v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br />')" />
              </div>
              <!--// 기본정보 end -->
            </div>
            <!--// tab panes end -->
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div
          v-if="buttons != null"
          class="modal-footer"
        >
          <template v-if="buttons.length > 0">
            <button
              v-for="(button, i) in buttons"
              :key="'button_'+i"
              type="button"
              class="btn"
              :class="{'btn-secondary': button.type == 'confirm','btn-info': button.type == 'cancel'}"
              @click.prevent.stop="click(i, $event)"
            >
              {{ button.title }}          
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn btn-secondary"
              @click.prevent.stop="close()"
            >
              확인         
            </button>
          </template>
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UiDialog',
  props: {
    title: {
      type: String,
      default: null,
      required: false
    },
    text: {
      type: String,
      default: null,
      required: false
    },
    buttons: {
      type: Array,
      default: null,
      required: false
    }
  },
  data () {
    return {
    }
  },
  computed: {    
  },
  created() {
    if ( this.buttons != null ) {
      this.buttons.forEach(button => {
        if ( !button.hasOwnProperty('title') ) {
          if ( button.type == 'confirm' ) {
            button.title = '확인';
          } else if ( button.type == 'cancel' ) {
            button.title = '취소';
          }
        }
      });
    }
  },
  methods: {
    click (i, event, source = 'click') {
      const button = this.buttons[i]
      if (button && typeof button.handler === 'function') {
        button.handler(i, event, { source });
        this.$emit('close');
      } else {
        this.$emit('close');
      }
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>