import http from '@/api/http';
import JwtService from "@/common/jwt.service";import {
    LOGIN
  } from "./actions.type";
  import { SET_AUTH } from "./mutations.type";
const state = {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
  };
  const getters = {
    currentUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  };
  const actions = {
    [LOGIN](context, credentials) {
       // console.log('mutations LOGIN : ');
        return new Promise(resolve => {
          let form = new FormData();
          form.append('username', credentials.user)
          form.append('password',credentials.password)
            http.post('/login_proc', form).then(response => {
                if (response !== null && typeof response !== 'undefined') {
                    const {data} = response;
                    context.commit(SET_AUTH, data);
                    resolve(data);
                } else {           
                    alert("아이디 및 비밀번호를 다시확인해 주세요");          
                    JwtService.destroyToken();  
                    resolve({
                        error:true
                    });
                }
            })
            .catch(error => {
              
                context.commit(SET_AUTH, 'testDat');
                resolve('testData');
                if ( error.response.data.hasOwnProperty('error') ){
                    alert(error);
                } else{
                alert("아이디 및 비밀번호를 다시확인해 주세요");
                };
        
               // JwtService.destroyToken();
                        
                /*resolve({
                error:true,
                errorDesc: error.response.data.hasOwnProperty('error') ?   error.response.data.error_description : ''      
                });    */    
                // context.commit(SET_ERROR, response.data.errors);
            });
        });
      },
}
const mutations = {
    [SET_AUTH](state, user) {
       // console.log('setAuth')
      state.isAuthenticated = true;
      state.user = user;
          state.errors = {};
      JwtService.saveToken('testData');
    },
};
  
export default {
    state,
    actions,
    mutations,
    getters
  };