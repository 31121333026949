import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import App from '../App.vue'
import localData from '@/api/localData';

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: 'ExpDash',
    redirect: "/web/exp/dash",
  },
  {
    path: "/web",
    name: 'ExpDash',
    redirect: "/web/exp/dash",
  },
  {
    path: '/web/login',
    name: 'Login',
    component: Login,
    meta: {
      navTitle: '로그인',
    },
  },
  {
    path: '/web/exp/dash',
    name: 'ExpDash',
    component: () => import('../views/common/Dash'),
    meta: {
      type: 'layout',
      navTitle: '대시보드',
      authorize: ['Admin']
    },
    props:true
  },
  {
    path: '/web/exp/chat/coun',
    name: 'ExpChatCoun',
    component: () => import('../views/common/ChatCounsellor'),
    meta: {
      navTitle: '상담 예약실(상담사용)',
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/chat/user',
    name: 'ExpChatUser',
    component: () => import('../views/common/ChatUser'),
    meta: {
      navTitle: '상담 예약실(내담자용)',
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/coun',
    name: 'ExpCoun',
    component: () => import('../views/common/Coun'),
    meta: {
      type: 'layout',
      navTitle: '나의 상담실',
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/coun/talk',
    name: 'ExpCounTalk',
    component: () => import('../views/common/CounTalk'),
    meta: {
      type: 'layout',
      navTitle: '나의 상담실',
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/sch',
    name: 'ExpSch',
    component: () => import('../views/common/ExpSch'),
    meta: {
      type: 'layout',
      navTitle: '상담 일정 관리',
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/eap/ass',
    name: 'ExpEapAss',
    component: () => import('../views/eap/EapAss'),
    meta: {
      type: 'layout',
      navTitle: '상담사 배정하기',
      navDepth: ['마인드온 (EAP)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/eap/per',
    name: 'ExpEapPer',
    component: () => import('../views/eap/EapPer'),
    meta: {
      type: 'layout',
      navTitle: '상담 실적 관리',
      navDepth: ['마인드온 (EAP)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/eap/sign',
    name: 'ExpEapSign',
    component: () => import('../views/eap/EapSign'),
    meta: {
      type: 'layout',
      navTitle: '실적 증빙용 서명 받기',
      navDepth: ['마인드온 (EAP)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/eap/pay',
    name: 'ExpEapPay',
    component: () => import('../views/eap/EapPay'),
    meta: {
      type: 'layout',
      navTitle: '상담료 정산 내역',
      navDepth: ['마인드온 (EAP)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/cou/vou',
    name: 'ExpCouVou',
    component: () => import('../views/cou/CouVou'),
    meta: {
      type: 'layout',
      navTitle: '이용권 관리',
      navDepth: ['마인드톡 (일반상담)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/cou/vou/cus',
    name: 'ExpCouVouCus',
    component: () => import('../views/cou/CouVouCus'),
    meta: {
      type: 'layout',
      navTitle: '이용권 관리',
      navDepth: ['마인드톡 (일반상담)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/cou/bil',
    name: 'ExpCouBil',
    component: () => import('../views/cou/CouBil'),
    meta: {
      type: 'layout',
      navTitle: '상담료 청구',
      navDepth: ['마인드톡 (일반상담)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/cou/pay',
    name: 'ExpCouPay',
    component: () => import('../views/cou/CouPay'),
    meta: {
      type: 'layout',
      navTitle: '상담료 정산 내역',
      navDepth: ['마인드톡 (일반상담)'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/rec',
    name: 'ExpAiRec',
    component: () => import('../views/ai/AiRec'),
    meta: {
      type: 'layout',
      navTitle: 'AI 상담분석',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/rec/res',
    name: 'ExpAiRecRes',
    component: () => import('../views/ai/AiRecRes'),
    meta: {
      type: 'layout',
      navTitle: 'AI 상담분석',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/que',
    name: 'ExpAiQue',
    component: () => import('../views/ai/AiQue'),
    meta: {
      type: 'layout',
      navTitle: '주호소문제 추출',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/risk',
    name: 'ExpAiRisk',
    component: () => import('../views/ai/AiRisk'),
    meta: {
      type: 'layout',
      navTitle: '사례 위험도',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/emp',
    name: 'ExpAiEmp',
    component: () => import('../views/ai/AiEmp'),
    meta: {
      type: 'layout',
      navTitle: '공감지수',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/ana',
    name: 'ExpAiAna',
    component: () => import('../views/ai/AiAns'),
    meta: {
      type: 'layout',
      navTitle: '텍스트 분석',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/ai/eva',
    name: 'ExpAiEva',
    component: () => import('../views/ai/AiEva'),
    meta: {
      type: 'layout',
      navTitle: '상담만족도 자동 평가',
      navDepth: ['AI 상담사'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/con',
    name: 'ExpMyCon',
    component: () => import('../views/my/MyCon'),
    meta: {
      type: 'layout',
      navTitle: '상담센터 정보',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/con/detail',
    name: 'ExpMyConDetail',
    component: () => import('../views/my/MyConDetail'),
    meta: {
      type: 'layout',
      navTitle: '상담센터 정보',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/con/cal',
    name: 'ExpMyConCal',
    component: () => import('../views/my/MyConCal'),
    meta: {
      type: 'layout',
      navTitle: '상담센터 정보',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/com',
    name: 'ExpMyCom',
    component: () => import('../views/my/MyCom'),
    meta: {
      type: 'layout',
      navTitle: '사내 상담센터 정보',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/com/det',
    name: 'ExpMyComDet',
    component: () => import('../views/my/MyComDet'),
    meta: {
      type: 'layout',
      navTitle: '사내 상담센터 정보',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/user',
    name: 'ExpMyUser',
    component: () => import('../views/my/MyUser'),
    meta: {
      type: 'layout',
      navTitle: '회원 정보',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/sta',
    name: 'ExpMySta',
    component: () => import('../views/my/MySta'),
    meta: {
      type: 'layout',
      navTitle: '사내상담센터 운영 보고서',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/rev',
    name: 'ExpMyRev',
    component: () => import('../views/my/MyRev'),
    meta: {
      type: 'layout',
      navTitle: '상담 후기',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/rev/talk',
    name: 'ExpMyRevTalk',
    component: () => import('../views/my/MyRevTalk'),
    meta: {
      type: 'layout',
      navTitle: '상담 후기',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/emp',
    name: 'ExpMyEmp',
    component: () => import('../views/my/MyEmp'),
    meta: {
      type: 'layout',
      navTitle: '기업상담사 채용 공고',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/cs/noti',
    name: 'ExpMyCsNoti',
    component: () => import('../views/my/MyCsNoti'),
    meta: {
      type: 'layout',
      navTitle: '공지사항',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/cs/faq',
    name: 'ExpMyCsFaq',
    component: () => import('../views/my/MyCsFaq'),
    meta: {
      type: 'layout',
      navTitle: 'FAQ',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/cs/oto',
    name: 'ExpMyCsOto',
    component: () => import('../views/my/MyCsOto'),
    meta: {
      type: 'layout',
      navTitle: '1:1 문의',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/exp/my/cou/sta',
    name: 'ExpMyCouSta',
    component: () => import('../views/my/MyCouSta'),
    meta: {
      type: 'layout',
      navTitle: '바로상담 설정',
      navDepth: ['마이페이지'],
      authorize: ['Admin']
    },
    props:true,
    beforeEnter: (to, from, next) => {
      to.matched.some((routeInfo) => {
        if(localData.getUserMenuAuth(routeInfo.name)) {
          next();
        }else {
          next('/dash');
          alert('해당 메뉴에 접근할 수 있는 권한이 설정되어 있지 않습니다.\n관리자에게 문의해 주세요');
        }
      })
    }
  },
  {
    path: '/web/acc',
    name: 'AccessTerms',
    component: () => import('../views/common/AccessTerms'),
    meta: {
      type: 'layout',
      navTitle: '이용약관',
      authorize: ['Admin']
    },
    props:true,
  },
  {
    path: '/web/policy',
    name: 'PersonalInfo',
    component: () => import('../views/common/PersonalInfo'),
    meta: {
      type: 'layout',
      navTitle: '개인정보처리방침',
      authorize: ['Admin']
    },
    props:true,
  },
  {
    path: '*',
    redirect: "/web/exp/dash",
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
