import axios from "axios";
import localData from './localData';
import { RELEASE_API_URL, DEV_API_URL } from "@/common/config";
import router from '../router/index';

const $http = axios.create({
  // timeout: 10000, // ( 기본값 0 no titmeout )
  baseURL: RELEASE_API_URL,
  // baseURL: RELEASE_API_URL
});

$http.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    // config.withCredentials = true;
    //주로 등록,수정(query,formdata)
    if (config.hasOwnProperty("data") && config.data) {
      try{
        //formData 형식 체크
        if(config.data instanceof FormData){
          for (let keyValue of config.data.entries()) {
            if(keyValue[1] && typeof keyValue[1] === 'string'){
              if(keyValue[1].trim() === 'null'){
                config.data.set(keyValue[0],'');
              }else{
                config.data.set(keyValue[0],keyValue[1].trim());
              }
            }

          }
        }else{
          Object.keys(config.data).forEach(row=>{
            if(typeof config.data[row] === 'string'){
              config.data[row] = config.data[row].trim();
            }
          })
        }
      }catch(err){
        console.log('저장오류',err);
      }
    }
    //주로 검색조건
    if (config.hasOwnProperty("params") && config.params) {
      try{
        //URLSearchParams 형식 체크
        if(config.params instanceof URLSearchParams){
          for (let keyValue of config.params.entries()) {
            if(keyValue[1] && typeof keyValue[1] === 'string'){
              if(keyValue[1].trim() === 'null'){
                config.params.set(keyValue[0],'');
              }else{
                config.params.set(keyValue[0],keyValue[1].trim());
              }
            }

          }
        }else{
          Object.keys(config.params).forEach(row=>{
            if(typeof config.params[row] === 'string'){
              config.params[row] = config.params[row].trim();
            }
          })
        }
      }catch(err){
        console.log('저장오류p',err);
      }
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
$http.interceptors.response.use(
  response => {
    /*console.log(' > response : ' + JSON.stringify(response));*/
    return response;
  },
  error => {
    /*console.log(' > error.response : ' + JSON.stringify(error));*/
    if(error.message == 'Network Error' && router.currentRoute.name != 'Login') {
      localData.removeUserId();
      localData.removeInteMbrId();
      localData.removeExpTp();
      localData.removeSaveUserData();
      localData.removeSaveMenuData();
      localData.removeMyCenterList();
      localData.removeSelMyCenterData();
      router.push({ path: '/web/login' });
    }
    if (error.response.status == 401) {
      localData.removeUserId();
      localData.removeInteMbrId();
      localData.removeExpTp();
      localData.removeSaveUserData();
      localData.removeSaveMenuData();
      localData.removeMyCenterList();
      localData.removeSelMyCenterData();
      if(router.currentRoute.name == 'Login'){
        return;
      }else{
        router.push({ path: '/web/login' });
      }
    }
    if (error.response.status == 403) {
      if(router.currentRoute.name != 'Login') {
        alert('권한이 없습니다.');
      }
      if(!localData.getSaveUserData() && router.currentRoute.name != 'Login') {
        router.push({ path: '/web/login' });
      }
    }
    if (error.response.status == 302) {
      localData.removeUserId();
      localData.removeInteMbrId();
      localData.removeExpTp();
      localData.removeSaveUserData();
      localData.removeSaveMenuData();
      localData.removeMyCenterList();
      localData.removeSelMyCenterData();
      router.push({ path: '/web/login' });
    }
    return Promise.reject(error);
  },
);
export default $http;
