<template>
  <!-- modal : 회원가입 start -->
  <div
    id="join"
    class="modal fade width_auto m-hide-modal show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="join"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            회원가입
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <!-- tab start -->
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  :class="{ active: v.tabIdx === 1 }"
                  @click.prevent.stop="moveTab(1)"
                >
                  기본 정보
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  :class="{ active: v.tabIdx === 2 }"
                  @click.prevent.stop="moveTab(2)"
                >
                  상세 정보
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  :class="{ active: v.tabIdx === 3 }"
                  @click.prevent.stop="moveTab(3)"
                >
                  상담 비용 설정
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  href="#"
                  :class="{ active: v.tabIdx === 4 }"
                  @click.prevent.stop="moveTab(4)"
                >
                  부가 정보
                </a>
              </li>
            </ul>
            <!--// tab end -->

            <!-- tab panes start -->
            <div class="tab-content">
              <!-- 기본 설정 start -->
              <div
                id="tab_cont01"
                class="tab-pane container fade  p-0 mt-30px w-650px"
                :class="{ active: v.tabIdx === 1, show: v.tabIdx === 1 }"
              >
                <!-- 항목 : 전문가 ID start -->
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10 necessity">전문가 ID</p><!-- 필수항목에 .necessity 추가 -->
                    <div class="flex_item input-group">
                      <input
                        ref="mbrId"
                        v-model="tab1.mbrId"
                        style="text-transform: lowercase"
                        type="text"
                        class="form-control"
                        placeholder="전문가 ID 입력"
                      >
                      <button
                        type="button"
                        class="btn btn-primary"
                        :disabled="!tab1.mbrId"
                        @click="checkId"
                      >
                        중복 검사
                      </button>
                    </div>
                  </label>
                </div>
                <!--// 항목 : 전문가 ID end -->

                <!-- 항목 : 이름 start -->
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10  necessity">이름</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.nm"
                        type="text"
                        class="form-control"
                        placeholder="이름 입력"
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 이름 end -->

                <!-- 항목 : 비밀번호 start -->
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10  necessity">비밀번호</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.pass"
                        type="password"
                        class="form-control"
                        placeholder="비밀번호 입력"
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 비밀번호 end -->

                <!-- 항목 : 비밀번호 start -->
                <div class="form-item h-40">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10  necessity">비밀번호 확인</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.passCheck"
                        type="password"
                        class="form-control"
                        placeholder="비밀번호 재입력"
                      >
                    </div>
                  </label>
                </div>
                <!--// 항목 : 비밀번호 end -->

                <!-- 항목 : 생년월일/성별 start -->
                <div class="form-item mb-10px">
                  <div class="type_label type_flex align-items-center">
                    <p class="w-100px pr-10  necessity">
                      생년월일/성별
                    </p>
                    <div class="flex_item row">
                      <!-- 캘린더 -->
                      <label>
                        <div class="datepicker bx-ib none-drop-tui">
                          <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
                            <date-picker
                              ref="stDt"
                              v-model="tab1.birth"
                              :config="stDtConfOpt"
                              title="날짜 선택"
                              placeholder="날짜 선택"
                            />
                            <span class="tui-ico-date datepicker_icon" />
                            <div id="startpicker-container" />
                          </div>
                        </div>
                      </label>
                      <!-- //캘린더 -->
                      <div class="row align-items-center pl-30px m-pt-5px m-pl-0">
                        <div class="custom-control custom-radio mr-20px">
                          <input
                            id="customRadio1-1"
                            v-model="tab1.gen"
                            type="radio"
                            name="customRadio1"
                            class="custom-control-input"
                            value="XY"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio1-1"
                          >남자</label>
                        </div>
                        <div class="custom-control custom-radio mr-20px">
                          <input
                            id="customRadio1-2"
                            v-model="tab1.gen"
                            type="radio"
                            name="customRadio1"
                            class="custom-control-input"
                            value="XX"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio1-2"
                          >여자</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-lightgray mt-5px pl-100px m-p-0">
                    * 연령대 기반 전문가 매칭과 생일 이벤트에 활용됩니다.
                  </div>
                </div>
                <!--// 항목 : 생년월일/성별 end -->

                <!-- 항목 : 휴대폰 start -->
                <div class="form-item mb-10px">
                  <label class="type_flex align-items-center col-6">
                    <p class="w-100px pr-10  necessity">휴대폰</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.tel"
                        type="text"
                        class="form-control"
                        placeholder="휴대폰 번호 입력 ( - 포함 입력 )"
                      >
                    </div>
                  </label>
                  <div class="text-lightgray mt-5px pl-100px m-p-0">
                    * 예약, 정산 등 필수 문자 수신에 활용됩니다.
                  </div>
                </div>
                <!--// 항목 : 휴대폰 end -->

                <!-- 항목 : 이메일 start -->
                <div class="form-item mb-10px">
                  <label class="type_flex align-items-center">
                    <p class="w-100px pr-10  necessity">이메일</p>
                    <div class="flex_item">
                      <input
                        v-model="tab1.email"
                        type="text"
                        class="form-control"
                        placeholder="이메일 입력"
                      >
                    </div>
                  </label>
                  <div class="text-lightgray mt-5px pl-100px m-p-0">
                    * 각종 공지와 알림, 온라인 심리상담 정산 내역이 전송되오니 자주쓰는 이메일로 입력해주세요.
                  </div>
                </div>
                <!--// 항목 : 이메일 end -->

                <!-- 항목 : 프로필 사진 start -->
                <div class="form-item mb-10px">
                  <div class="type_label type_flex">
                    <p class="w-100px pr-10 pt-8px  necessity">
                      프로필 사진
                    </p>
                    <div class="flex_item">
                      <div class="row form-group">
                        <input
                          type="text"
                          :value="tab1.profileFileItem.profileFileNm ? tab1.profileFileItem.profileFileNm : '선택된 파일 없음'"
                          class="pseudo_input"
                          readonly=""
                        >
                        <label for="inputTypeFile1">파일 선택</label>
                        <input
                          id="inputTypeFile1"
                          ref="tab1.profileFile"
                          type="file"
                          class="form-control-file"
                          accept=".jpg, .png, .gif"
                          @change="previewProfileFile($event)"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="text-lightgray mt-5px pl-100px mb-10px m-p-0">
                    * 사진관에서 찍은 프로필 이미지로 부탁드립니다.<br>
                    * 이용자는 전문가의 얼굴이 있는 상담사 소개를 더욱 선호합니다
                  </div>

                  <!-- 이미지 preview start -->
                  <div class=" pl-100px mt-10px m-p-0">
                    <div class="modal_preview">
                      <!-- 이미지 없을경우 a 에 .disabled 추가, pic_preview 에 .no-img 추가 -->
                      <a
                        :href="tab1.profileFileItem.profileFileUrl"
                        target="_blank"
                        class="d-inline-block"
                        title="프로필 사진"
                        :class="tab1.profileFileItem.profileFileUrl? '' : 'disabled'"
                      >
                        <div
                          :class="tab1.profileFileItem.profileFileUrl? '' : 'no-img'"
                          class="border pic_preview vertical small m-auto"
                          :style="`background-image:url(${tab1.profileFileItem.profileFileUrl});`"
                        /></a>
                      <button
                        v-if="tab1.profileFileItem.profileFileUrl"
                        type="button"
                        class="btn_tbl_icon fas fa-trash-alt align-top"
                        title="삭제"
                        @click="deleteProfileFile"
                      />
                    </div>
                  </div>
                  <!--// 이미지 preview end -->
                </div>
                <!--// 항목 : 프로필 사진 end -->
              </div>
              <!--//  기본 설정 end -->



              <!-- 상담 정보 start -->
              <div
                id="tab_cont02"
                class="tab-pane container fade p-0 mt-30px w-700px"
                :class="{ active: v.tabIdx === 2, show: v.tabIdx === 2 }"
              >
                <!-- 전문가 정보 start -->
                <div>
                  <h5>전문가 정보</h5>

                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:180px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          전문가 구분<div class="text-lightgray mt-5px">
                            (중복 체크 가능)
                          </div>
                        </th>
                        <td class="text-center align-middle">
                          <div class="row">
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck3_1"
                                v-model="tab2.checkDepartCdmngId1"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck3_1"
                              >
                                심리상담
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck3_2"
                                v-model="tab2.checkDepartCdmngId2"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck3_2"
                              >
                                언어치료
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck3_3"
                                v-model="tab2.checkDepartCdmngId3"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck3_3"
                              >
                                발달치료
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox mr-20px">
                              <input
                                id="customCheck3_4"
                                v-model="tab2.checkDepartCdmngId4"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="0"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck3_4"
                              >
                                재무상담
                              </label>
                            </div>
                          </div>
                          <div class="mt-5px text-left text-danger pl-10px">
                            * 언어치료 및 발달치료 전문가는 관련 자격증 필수
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 전문가 정보end -->


                <!-- 자격정보 start -->
                <div class="mt-30px">
                  <h5>자격증 정보<span class="tit_desc">'대표 자격증' 선택시 최우선 노출됨</span></h5>

                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:80px">
                      <col>
                      <col style="width:130px">
                      <col style="width:160px">
                      <col style="width:40px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          대표 자격증
                        </th>
                        <th scope="col">
                          자격증
                        </th>
                        <th scope="col">
                          발급년도
                        </th>
                        <th scope="col">
                          자격증 파일
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click.prevent.stop="addCert"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab2.certList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="5"
                          >
                            등록된 자격증이 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(cert, index) in tab2.certList"
                          :key="cert.expCertId"
                        >
                          <td class="text-center align-middle">
                            <div class="custom-control custom-radio ml-10px">
                              <input
                                :id="`customRadio2-1_${index}`"
                                v-model="cert.mainViewYn"
                                type="radio"
                                name="customRadio2"
                                class="custom-control-input"
                                :value="1"
                              >
                              <label
                                class="custom-control-label"
                                :for="`customRadio2-1_${index}`"
                              />
                            </div>
                          </td>
                          <td class="text-center">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="cert.cdmngId"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectLicenseList"
                                placeholder="선택"
                                @input="changeLicense(cert, index)"
                              />
                            </div>
                            <!-- 자격증명 직접입력 선택시 노출  -->
                            <template v-if="cert.textOn === '1'">
                              <input
                                v-model="cert.text"
                                type="text"
                                class="form-control mt-5px"
                                placeholder="자격증명 직접 입력"
                              >
                            </template>
                          </td>
                          <td class="text-center">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="cert.certIssueDt"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectYearList"
                                :searchable="false"
                                :clearable="false"
                                placeholder="선택"
                              />
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="form-group">
                                <input
                                  type="text"
                                  :value="cert.certificateFile.certFileNm ? cert.certificateFile.certFileNm : '선택된 파일 없음'"
                                  class="pseudo_input d-none"
                                  readonly=""
                                >
                                <label :for="`inputTypeFile2_1_${index}`">
                                  파일 선택
                                </label>
                                <input
                                  :id="`inputTypeFile2_1_${index}`"
                                  :ref="tab2.certificate"
                                  type="file"
                                  class="form-control-file"
                                  @change="previewCert($event, index)"
                                >
                              </div>
                              <!-- 자격증 파일 - 파일이 없는 경우 -->
                              <template v-if="!cert.certificateFile.certFileNm">
                                <span class="pl-10px flex_item text-lightgray">
                                  파일없음
                                </span>
                              </template>
                              <!-- 첨부파일이 등록되었을 경우 아래 버튼 2개 표시 -->
                              <template v-else-if="cert.certificateFile.certFileNm">
                                <span class="pl-10px flex_item">
                                  <button
                                    type="button"
                                    class="btn_tbl_icon fas fa-trash-alt m-0"
                                    title="첨부파일 삭제"
                                    @click="deleteCertFile(index)"
                                  />
                                  <button
                                    type="button"
                                    class="btn_tbl_icon fas fa-search-plus m-0"
                                    title="첨부파일 보기"
                                    @click="openBrWindow(cert.certificateFile.certFileUrl, '첨부파일 보기')"
                                  />
                                </span>
                              </template>
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click.prevent.stop="removeCert(cert.expCertId, index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 자격정보 end -->

                <!-- 최종학력 start -->
                <div class="mt-30px">
                  <h5>최종학력<span class="tit_desc">작성시 공공기관 대상 심리상담 사업에 자동 참여 혜택이 주어집니다.</span></h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col>
                      <col style="width:175px">
                      <col style="width:150px">
                      <col style="width:130px">
                      <col style="width:40px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          학교명
                        </th>
                        <th scope="col">
                          전공
                        </th>
                        <th scope="col">
                          학위
                        </th>
                        <th scope="col">
                          취득년도
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click.prevent.stop="addEducation"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab2.educationList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="5"
                          >
                            등록된 학력이 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(education, index) in tab2.educationList"
                          :key="index"
                        >
                          <td class="text-center align-middle">
                            <input
                              :ref="`education_${index}_school`"
                              v-model="education.school"
                              type="text"
                              class="form-control"
                              placeholder="학교명 입력"
                              @input="education.school = $event.target.value;"
                            >
                          </td>
                          <td class="text-center align-middle">
                            <input
                              :ref="`education_${index}_major`"
                              v-model="education.major"
                              type="text"
                              class="form-control"
                              placeholder="전공 입력"
                              @input="education.major = $event.target.value;"
                            >
                          </td>
                          <td class="text-center align-middle">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="education.degree"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectEducationList"
                                placeholder="선택"
                              />
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="education.degreeAcqYear"
                                :multiple="false"
                                :limit="0"
                                :options="tab2.selectYearList"
                                placeholder="선택"
                              />
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click.prevent.stop="removeEducation(index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 최종학력 end -->

                <!-- 상담 관련 경력 start -->
                <div class="mt-30px">
                  <h5>상담 관련 경력<span class="tit_desc">작성시 마음의숲 기업상담사 채용시 우대합니다.</span></h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:270px">
                      <col>
                      <col style="width:40px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          입사/퇴사 년월
                        </th>
                        <th scope="col">
                          조직명 / 직책 및 업무내용
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click="addCareer"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab2.careerList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="3"
                          >
                            등록된 경력이 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(career, index) in tab2.careerList"
                          :key="index"
                        >
                          <td class="text-center align-middle">
                            <div class="w-150px d-inline-block">
                              <label>
                                <div class="datepicker bx-ib none-drop-tui mb-5px">
                                  <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
                                    <date-picker
                                      ref="caStDt"
                                      v-model="career.joinDt"
                                      :config="tab2.careerDtConfOpt"
                                      title="입사날짜 선택"
                                      placeholder="입사날짜 선택"
                                    />
                                    <span class="tui-ico-date datepicker_icon" />
                                    <div id="career-startpicker-container" />
                                  </div>
                                </div>
                              </label>
                              <label>
                                <div class="datepicker bx-ib none-drop-tui">
                                  <div class="tui-datepicker-input tui-datetime-input tui-has-focus">
                                    <date-picker
                                      ref="caEnDt"
                                      v-model="career.leaveDt"
                                      :disabled="career.emplYn == 1"
                                      :config="tab2.careerDtConfOpt"
                                      title="퇴사날짜 선택"
                                      placeholder="퇴사날짜 선택"
                                    />
                                    <span class="tui-ico-date datepicker_icon" />
                                    <div id="career-endpicker-container" />
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div class="custom-control custom-checkbox d-inline-block align-bottom mb-3px">
                              <input
                                :id="`customCheck5_1_${index}`"
                                v-model="career.emplYn"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="-1"
                                @change="inputEmplYn(index)"
                              >
                              <label
                                class="custom-control-label"
                                :for="`customCheck5_1_${index}`"
                              >재직중</label>
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <input
                              :ref="`career_${index}_compNm`"
                              v-model="career.compNm"
                              type="text"
                              class="form-control"
                              placeholder="조직명 입력"
                              @input="career.compNm = $event.target.value;"
                            >
                            <textarea
                              :ref="`career_${index}_taskCon`"
                              v-model="career.taskCon"
                              class="form-control mt-5px"
                              rows="2"
                              placeholder="직책 및 업무내용 입력"
                              @input="career.taskCon = $event.target.value;"
                            />
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click="removeCareer(index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 상담 관련 경력 end -->


                <!-- 주제태그 및 전문분야 start -->
                <div class="mt-30px">
                  <h5>주제태그 및 전문분야</h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:170px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          자신있는 상담주제 태그
                        </th>
                        <td>
                          <div>
                            <div class="input-group">
                              <div class="custom-multi-select w-200px">
                                <treeselect
                                  v-model="tab2.selectTopic"
                                  :disabled="tab2.selectTopic && tab2.selectTopic.length === 5"
                                  :multiple="true"
                                  :limit="0"
                                  :options="tab2.selectTopicList"
                                  placeholder="선택"
                                  :normalizer="normalizerTopic"
                                  @select="selTopic"
                                  @deselect="deselTopic"
                                  @input="clearTopic"
                                />
                              </div>
                              <input
                                v-model="tab2.selfTopic"
                                :disabled="topicDisableFlag"
                                type="text"
                                class="form-control w-160px ml-10px"
                                placeholder="태그 직접 입력"
                              >
                              <button
                                type="button"
                                class="btn btn-primary"
                                @click="addSelfTopic"
                              >
                                확인
                              </button>
                            </div>
                            <div class="text-lightgray mt-5px">
                              최대 5개까지 선택 가능 / 직접 입력은 1개만 가능
                            </div>
                            <ul class="mt-5px inline">
                              <li
                                v-for="(tagTopic, i) in tab2.tagTopicList"
                                :key="i"
                              >
                                <div
                                  class="badge small"
                                  :class="tagTopic.cdmngId !== 572 ? 'bg-ultramarine' : 'bg-bluegreen'"
                                >
                                  {{ tagTopic.codeNm }}
                                  <button
                                    type="button"
                                    title="삭제"
                                    @click.prevent.stop="removeTopicTag(i)"
                                  >
                                    <i class="fas fa-times-circle text-light" />
                                  </button>
                                </div>
                              </li><!-- 직접입력한 경우 .bg-bluegreen 으로 수정 -->
                            </ul>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          전문분야
                        </th>
                        <td>
                          <div>
                            <div class="input-group">
                              <div class="custom-multi-select w-200px">
                                <treeselect
                                  v-model="tab2.selectDepart"
                                  :multiple="true"
                                  :limit="0"
                                  :options="tab2.selectDepartList"
                                  placeholder="선택"
                                  :normalizer="normalizerDepart"
                                  @select="selDepart"
                                  @deselect="deselDepart"
                                  @input="clearDepart"
                                />
                              </div>
                              <input
                                v-model="tab2.selfDepart"
                                :disabled="departDisableFlag"
                                type="text"
                                class="form-control w-160px ml-10px"
                                placeholder="태그 직접 입력"
                              >
                              <button
                                type="button"
                                class="btn btn-primary"
                                @click="addSelfDepart"
                              >
                                확인
                              </button>
                            </div>
                            <ul class="mt-5px inline d-block">
                              <li
                                v-for="(tagDepart, i) in tab2.tagDepartList"
                                :key="i"
                              >
                                <div
                                  class="badge small"
                                  :class="tagDepart.cdmngId !== 572 && tagDepart.cdmngId !== 631 ? 'bg-ultramarine' : 'bg-bluegreen'"
                                >
                                  {{ tagDepart.codeNm }}
                                  <button
                                    type="button"
                                    title="삭제"
                                    @click.prevent.stop="removeDepartTag(i)"
                                  >
                                    <i class="fas fa-times-circle text-light" />
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 주제태그 및 전문분야 end -->

                <!-- 외국어 상담 start -->
                <div class="mt-30px">
                  <h5>외국어 상담</h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:170px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          외국어 가능여부
                        </th>
                        <td>
                          <div class="row">
                            <div class="custom-control custom-checkbox">
                              <input
                                id="customCheck6_1"
                                v-model="tab2.forLangConsYn"
                                type="checkbox"
                                class="custom-control-input"
                                true-value="1"
                                false-value="-1"
                              >
                              <label
                                class="custom-control-label"
                                for="customCheck6_1"
                              >가능</label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          가능 외국어
                        </th>
                        <td>
                          <input
                            v-model="tab2.speechLang"
                            type="text"
                            class="form-control flex_item"
                            placeholder="예) 영어, 러시아어"
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 외국어 상담 end -->

                <!-- 전문가 소개 start -->
                <div class="mt-30px">
                  <div class="clearfix">
                    <div class="float-left pt-8px">
                      <h5 class="d-inline-block">
                        전문가 소개
                      </h5>
                      <div class="d-inline-block d-inline-block ml-20px">
                        <div class="custom-control d-inline-block custom-radio mr-20px">
                          <input
                            id="customRadio6-1"
                            v-model="tab2.easyWrYn"
                            type="radio"
                            name="customRadio6"
                            class="custom-control-input"
                            value="-1"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio6-1"
                          >
                            간단 작성 (항목별 입력)
                          </label>
                        </div>
                        <div class="custom-control d-inline-block custom-radio mr-20px">
                          <input
                            id="customRadio6-2"
                            v-model="tab2.easyWrYn"
                            type="radio"
                            name="customRadio6"
                            class="custom-control-input"
                            value="1"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio6-2"
                          >
                            상세 작성 (에디터 사용)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="tab2.easyWrYn === '1'"
                      class="float-right"
                    >
                      <!-- '상세 작성'일 경우에만 노출 -->
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="showExpertJoinTemplate"
                      >
                        기본 템플릿 수정
                      </button>
                    </div>
                  </div>

                  <!-- 간단 작성 start -->
                  <div
                    v-if="tab2.easyWrYn === '-1'"
                    class="mt-5px"
                  >
                    <table class="tbl_type01 mt-5px">
                      <colgroup>
                        <col style="width:170px">
                        <col>
                      </colgroup>
                      <tbody>
                        <tr>
                          <th scope="row">
                            메인 타이틀
                          </th>
                          <td>
                            <div class="mb-5px">
                              한 문장으로 나의 상담 스타일을 표현해주세요. 최상단에 가장 큰 글씨로 들어갑니다.
                            </div>
                            <input
                              v-model="tab2.easyWrMainTit"
                              type="text"
                              class="form-control"
                              placeholder="메인 타이틀 입력"
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            서브 타이틀 1
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 한 문장으로 표현해주세요.
                            </div>
                            <input
                              v-model="tab2.easyWrSubTit1"
                              type="text"
                              class="form-control"
                              placeholder="서브 타이틀 1 입력"
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            본문 1
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 자유롭게 표현해주세요.
                            </div>
                            <textarea
                              v-model="tab2.easyWrCon1"
                              class="form-control"
                              rows="3"
                              placeholder="본문 1 입력"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            서브 타이틀 2
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 한 문장으로 표현해주세요.
                            </div>
                            <input
                              v-model="tab2.easyWrSubTit2"
                              type="text"
                              class="form-control"
                              placeholder="서브 타이틀 2 입력"
                            >
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            본문 2
                          </th>
                          <td>
                            <div class="mb-5px">
                              유저들에게 건네고 싶은 말을 자유롭게 표현해주세요.
                            </div>
                            <textarea
                              v-model="tab2.easyWrCon2"
                              class="form-control"
                              rows="3"
                              placeholder="본문 2 입력"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            이미지
                          </th>
                          <td>
                            <div class="mb-5px">
                              이미지로 내 소개글을 꾸미고 싶다면 추가해주세요
                            </div>
                            <div class="row form-group">
                              <input
                                type="text"
                                :value="tab2.introFileItem.introFileNm ? tab2.introFileItem.introFileNm : '선택된 파일 없음'"
                                class="pseudo_input"
                                readonly=""
                              >
                              <label for="inputTypeFile11">파일 선택</label>
                              <input
                                id="inputTypeFile11"
                                :ref="tab2.introFile"
                                type="file"
                                class="form-control-file"
                                accept=".jpg, .png, .gif"
                                @change="previewIntroFile($event)"
                              >
                            </div>
                            <!-- 이미지 preview start -->
                            <div class="mt-10px m-p-0">
                              <div class="modal_preview">
                                <!-- 이미지 없을경우 a 에 .disabled 추가, pic_preview 에 .no-img 추가 -->
                                <a
                                  :href="tab2.introFileItem.introFileUrl"
                                  target="_blank"
                                  class="d-inline-block"
                                  title="프로필 사진"
                                  :class="tab2.introFileItem.introFileUrl? '' : 'disabled'"
                                >
                                  <div
                                    :class="tab2.introFileItem.introFileUrl? '' : 'no-img'"
                                    class="border pic_preview small m-auto"
                                    :style="`background-image:url(${tab2.introFileItem.introFileUrl});`"
                                  /></a>
                                <button
                                  v-if="tab2.introFileItem.introFileUrl"
                                  type="button"
                                  class="btn_tbl_icon fas fa-trash-alt align-top"
                                  title="삭제"
                                  @click="deleteIntroFile"
                                />
                              </div>
                            </div>
                          <!--// 이미지 preview end -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!--// 간단 작성 end -->


                  <!-- 상세 작성 start -->
                  <div
                    v-else-if="tab2.easyWrYn === '1'"
                    class="mt-5px border h-350px"
                  >
                    <editor
                      ref="toastuiEditor"
                      height="350px"
                      :options="defaultOptions"
                      initial-edit-type="wysiwyg"
                    />
                  </div>
                  <!--// 상세 작성 end -->
                </div>
                <!--// 전문가 소개 end -->

                <!-- 전문가 목소리 업로드 start -->
                <div class="mt-30px">
                  <h5>전문가 목소리 녹음</h5>
                  <div class="box-blue mt-5px p-10px record_control row align-items-center">
                    <div class="col-4 text-center">
                      <button
                        type="button"
                        class="btn btn-primary type_record btn-record"
                        title="녹음"
                        :class="{'on':isRecorder}"
                        @click="onRecorder"
                      /><!-- 녹음버튼 클릭시 .on 추가 / 플레이시 disabled-->
                      <button
                        type="button"
                        class="btn btn-primary type_record btn-play"
                        :class="{'on': wavesurfer && wavesurfer.isPlaying()}"
                        title="플레이"
                        :disabled="!wavesurfer || wavesurfer.isPlaying()"
                        @click.prevent.stop="wavesurfer.playPause()"
                      /><!-- 플레이버튼 클릭시 .on 추가 -->
                      <button
                        type="button"
                        class="btn btn-primary type_record btn-stop"
                        title="스톱"
                        :disabled="!wavesurfer || !wavesurfer.isPlaying()"
                        @click.prevent.stop="wavesurfer.playPause()"
                      /><!-- 플레이시 활성화 -->
                    </div>
                    <div class="col-6">
                      <ul class="list_dot list_b5px">
                        <li>녹음된 음성 길이 : <span class="text-danger">{{ second ? second.toFixed(2) : 0 }}초</span></li>
                      </ul>
                    </div>
                    <div class="col-2 text-right">
                      <button
                        type="button"
                        class="btn btn-primary w-70px my-3px"
                        :disabled="!(file && file.blob && file.name)"
                        @click="deleteVoiceFile"
                      >
                        <i class="fas fa-trash-alt mr-5px" />삭제
                      </button><!-- 등록된 음성이 없을경우 disabled -->
                    </div>
                    <div 
                      class="w-100p"
                      :class="{'mt-20px h-50px': waveFlag}"
                    >
                      <!--녹음 파형 위치 (w100% x h50px)-->
                      <div id="waveform" />
                    </div>
                  </div>
                </div>
                <!--// 전문가 목소리 업로드 end -->

                <!-- 제공가능 비대면 상담 start -->
                <div class="mt-30px">
                  <h5>제공가능 비대면 상담</h5>

                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:180px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          제공가능 비대면 상담<div class="text-lightgray mt-5px">
                            (중복 체크 가능)
                          </div>
                        </th>
                        <td>
                          <div class="custom-control custom-checkbox h-20">
                            <input
                              id="customCheck4_1"
                              v-model="tab2.checkNonfaceDepart1"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="0"
                            >
                            <label
                              class="custom-control-label"
                              for="customCheck4_1"
                            >
                              전화상담
                            </label>
                          </div>
                          <div class="custom-control custom-checkbox h-20">
                            <input
                              id="customCheck4_2"
                              v-model="tab2.checkNonfaceDepart2"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="0"
                            >
                            <label
                              class="custom-control-label"
                              for="customCheck4_2"
                            >채팅상담 <span class="text-lightgray">(분당 300타 이상 권장)</span></label>
                          </div>
                          <div class="custom-control custom-checkbox h-20">
                            <input
                              id="customCheck4_3"
                              v-model="tab2.checkNonfaceDepart3"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="0"
                            >
                            <label
                              class="custom-control-label"
                              for="customCheck4_3"
                            >화상상담 <span class="text-lightgray">(경험자 우대)</span></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 제공가능 비대면 상담 end -->


                <!-- 비대면 상담 가능 일정 start -->
                <div class="mt-30px">
                  <h5>비대면 상담 가능 일정<span class="tit_desc">일정을 입력하신 분들은 선배정 혜택이 있습니다.</span></h5>

                  <table class="tbl_type01 mt-5px">
                    <thead>
                      <tr>
                        <th scope="col">
                          구분
                        </th>
                        <th scope="col">
                          월
                        </th>
                        <th scope="col">
                          화
                        </th>
                        <th scope="col">
                          수
                        </th>
                        <th scope="col">
                          목
                        </th>
                        <th scope="col">
                          금
                        </th>
                        <th scope="col">
                          토
                        </th>
                        <th scope="col">
                          일
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row,i) in tab2.nonfaceGrid.data"
                        :key="row.time"
                      >
                        <th
                          v-if="i===0"
                          scope="row"
                        >
                          08:00 ~ 12:00
                        </th>
                        <th
                          v-if="i===1"
                          scope="row"
                        >
                          12:00 ~ 16:00
                        </th>
                        <th
                          v-if="i===2"
                          scope="row"
                        >
                          16:00 ~ 20:00
                        </th>
                        <th
                          v-if="i===3"
                          scope="row"
                        >
                          <span class="text-danger">
                            (심야)
                          </span> 20:00 ~ 00:00
                        </th>
                        <th
                          v-if="i===4"
                          scope="row"
                        >
                          <span class="text-danger">
                            (심야)
                          </span> 00:00 ~ 04:00
                        </th>
                        <th
                          v-if="i===5"
                          scope="row"
                        >
                          <span class="text-danger">
                            (심야)
                          </span> 04:00 ~ 08:00
                        </th>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_1_${i}`"
                              v-model="row.dayweek1"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_1_${i}`"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_2_${i}`"
                              v-model="row.dayweek2"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_2_${i}`"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_3_${i}`"
                              v-model="row.dayweek3"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_3_${i}`"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_4_${i}`"
                              v-model="row.dayweek4"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_4_${i}`"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_5_${i}`"
                              v-model="row.dayweek5"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_5_${i}`"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_6_${i}`"
                              v-model="row.dayweek6"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_6_${i}`"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck7_1_7_${i}`"
                              v-model="row.dayweek7"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="-1"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck7_1_7_${i}`"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 비대면 상담 가능 일정 end -->


                <!-- 기업 사내상담실 채용 공고 지원 start -->
                <div class="mt-30px">
                  <h5>기업 사내상담실 채용 공고 지원</h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:170px">
                      <col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          채용 공고 수신여부<br>(이메일)
                        </th>
                        <td class="align-middle">
                          <div class="row align-items-center pl-10px">
                            <div class="custom-control custom-radio mr-20px">
                              <input
                                id="customRadio5-1"
                                v-model="tab2.emplNotiYn"
                                type="radio"
                                name="customRadio5"
                                class="custom-control-input"
                                value="1"
                              >
                              <label
                                class="custom-control-label"
                                for="customRadio5-1"
                              >예</label>
                            </div>
                            <div class="custom-control custom-radio mr-20px">
                              <input
                                id="customRadio5-2"
                                v-model="tab2.emplNotiYn"
                                type="radio"
                                name="customRadio5"
                                class="custom-control-input"
                                value="-1"
                              >
                              <label
                                class="custom-control-label"
                                for="customRadio5-2"
                              >아니오</label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          이력서 (자소서 포함)<br><button
                            type="button"
                            class="btn btn-primary btn-sm mt-5px"
                            @click="formFileDown"
                          >
                            양식 다운로드
                          </button>
                        </th>
                        <td class="align-middle">
                          <div class="mb-5px text-danger">
                            * 사내상담사 지원자는 작성 필수
                          </div>
                          <div class="form-group row">
                            <input
                              type="text"
                              :value="tab2.resumeFileItem.resumeFileNm ? tab2.resumeFileItem.resumeFileNm : '선택된 파일 없음'"
                              class="pseudo_input"
                              readonly=""
                            >
                            <label for="inputTypeFile4-1">파일 선택</label>
                            <input
                              id="inputTypeFile4-1"
                              :ref="tab2.resumeFile"
                              type="file"
                              class="form-control-file"
                              @change="previewResume($event)"
                            >
                            <div
                              v-if="tab2.resumeFileItem.resumeFileUrl"
                              class="mt-5px w-100p"
                            >
                              <a
                                :href="tab2.resumeFileItem.resumeFileUrl"
                                target="_blank"
                                class="underline p-0"
                              >
                                {{ tab2.resumeFileItem.resumeFileNm }}
                              </a>
                              <span class="text-lightgray pl-5px">
                                ({{ (tab2.resumeFileItem.resumeFileSize/1024).toFixed(1) }}kb)
                              </span>
                              <button
                                type="button"
                                class="btn_tbl_icon fas fa-trash-alt"
                                title="삭제"
                                @click="deleteResumeFile"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          경력증명서<br>
                          <button
                            type="button"
                            class="btn btn-primary btn-sm mt-5px"
                          >
                            양식 다운로드
                          </button>
                        </th>
                        <td class="align-middle">
                          <div class="mb-5px text-danger">
                            * 사내상담사 지원자는 작성 필수
                          </div>
                          <div class="form-group row">
                            <input
                              type="text"
                              :value="tab2.careerFileItem.careerFileNm ? tab2.careerFileItem.careerFileNm : '선택된 파일 없음'"
                              class="pseudo_input"
                              readonly=""
                            >
                            <label for="inputTypeFile4-2">
                              파일 선택
                            </label>
                            <input
                              id="inputTypeFile4-2"
                              :ref="tab2.careerFile"
                              type="file"
                              class="form-control-file"
                              @change="previewCareer($event)"
                            >
                            <div
                              v-if="tab2.careerFileItem.careerFileUrl != null"
                              class="mt-5px w-100p"
                            >
                              <a
                                :href="tab2.careerFileItem.careerFileUrl"
                                target="_blank"
                                class="underline p-0"
                              >
                                {{ tab2.careerFileItem.careerFileNm }}
                              </a>
                              <span class="text-lightgray pl-5px">
                                ({{ (tab2.careerFileItem.careerFileSize/1024).toFixed(1) }}kb)
                              </span>
                              <button
                                type="button"
                                class="btn_tbl_icon fas fa-trash-alt"
                                title="삭제"
                                @click="deleteCareerFile"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 기업 사내상담실 채용 공고 지원 end -->
              </div>
              <!--// 상담 정보 end -->



              <!-- 상담 비용 설정 start -->
              <div
                id="tab_cont03"
                class="tab-pane container fade p-0 mt-30px w-1000px"
                :class="{ active: v.tabIdx === 3, show: v.tabIdx === 3 }"
              >
                <!-- 마인드톡 이용권 start -->
                <div>
                  <h5>마인드톡 이용권</h5>
                  <div class="box-blue p-10px mt-5px lh-15em">
                    <ul class="list_dot">
                      <li>마인드톡 상담 이용권은 EAP 기업상담과 달리 상담 전문가들이 직접 가격을 책정할 수 있어요. 여러분이 받고 싶은 금액을 적어주세요.</li>
                      <li>마인드톡 플랫폼은 이용권 금액(결제 금액 기준)의  {지급 수수료 설정값(%)}를 수수료로 가져갑니다.</li>
                      <li>참고로, 통계 상 사람들이 가장 선호하는 가격은 1회 기준 대면상담 5~6만원, 비대면상담 3~5만원이랍니다.</li>
                      <li>5회 이상 이용권 등록은 아래 맞춤 이용권 설정을 이용해주세요.</li>
                    </ul>
                  </div>
                  <table class="tbl_type01 mt-10px">
                    <colgroup>
                      <col style="width:200px;">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          기본 이용권 명칭
                        </th>
                        <th scope="col">
                          상담방식
                        </th>
                        <th scope="col">
                          이용권 정가<br>(할인전 표시금액)
                        </th>
                        <th scope="col">
                          이용권 할인가<br>(고객 결제 금액) <span class="text-primary">[선택]</span>
                        </th>
                        <th scope="col">
                          이용권<br>할인율
                        </th>
                        <th scope="col">
                          마인드톡<br>노출 여부
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(defaultVoucher, index) in tab3.defaultVoucherList"
                        :key="defaultVoucher.name"
                      >
                        <td class="text-center align-middle">
                          {{ defaultVoucher.name }}
                        </td>
                        <td class="text-center align-middle">
                          {{ defaultVoucher.tp }}
                        </td>
                        <td class="text-center align-middle">
                          <input
                            :ref="`default_voucher_${index}_price`"
                            v-model="defaultVoucher.price"
                            type="number"
                            class="form-control text-right w-90px d-inline-block mr-10px"
                            :disabled="defaultVoucher.cdmngId === 667"
                            placeholder="금액 입력"
                            @input="inputDefVoucherDisCnt(index)"
                          >원
                        </td>
                        <td class="text-center align-middle">
                          <input
                            :ref="`default_voucher_${index}_discnt`"
                            v-model="defaultVoucher.discnt"
                            type="number"
                            class="form-control text-right w-90px d-inline-block mr-10px"
                            :disabled="defaultVoucher.cdmngId === 667"
                            placeholder="금액 입력"
                            @input="inputDefVoucherDisCnt(index)"
                          >원
                        </td>
                        <td class="text-center align-middle">
                          {{ defaultVoucher.sale }}
                        </td>
                        <td class="text-center align-middle">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck8_1_${index}`"
                              v-model="defaultVoucher.view"
                              type="checkbox"
                              class="custom-control-input"
                              :disabled="defaultVoucher.cdmngId === 667"
                              true-value="1"
                              false-value="0"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck8_1_${index}`"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="mt-30px">
                  <h5>마인드톡 맞춤 이용권</h5>

                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          맞춤 이용권 명칭
                        </th>
                        <th scope="col">
                          맞춤 이용권 설명<br>(최대 80자)
                        </th>
                        <th scope="col">
                          상담방식
                        </th>
                        <th scope="col">
                          상담횟수<br>(최대8회)
                        </th>
                        <th scope="col">
                          이용권 정가<br>(할인전 표시금액)
                        </th>
                        <th scope="col">
                          이용권 할인가<br>(고객 결제 금액) <span class="text-primary">[선택]</span>
                        </th>
                        <th scope="col">
                          이용권<br>할인율
                        </th>
                        <th scope="col">
                          마인드톡<br>노출여부
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click.prevent.stop="addCustomVoucher"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(customVoucher, index) in tab3.customVoucherList"
                        :key="index"
                      >
                        <td class="text-center align-middle">
                          <input
                            :ref="`custom_voucher_${index}_name`"
                            v-model="customVoucher.name"
                            type="text"
                            class="form-control d-inline w-150px"
                            placeholder="이용권 명칭 입력"
                          >
                        </td>
                        <td class="text-center align-middle">
                          <input
                            :ref="`custom_voucher_${index}_expln`"
                            v-model="customVoucher.expln"
                            type="text"
                            class="form-control d-inline w-150px"
                            placeholder="이용권 설명 입력"
                          >
                        </td>
                        <td class="text-center align-middle">
                          <div class="custom-multi-select w-80px">
                            <treeselect
                              v-model="customVoucher.tp"
                              :searchable="false"
                              :clearable="false"
                              :multiple="false"
                              :options="tab3.selectVoucherTpList"
                              placeholder="선택"
                            />
                          </div>
                        </td>
                        <td class="text-center align-middle">
                          <input
                            :ref="`custom_voucher_${index}_num`"
                            v-model="customVoucher.num"
                            type="number"
                            class="form-control text-center w-50px d-inline-block mr-10px"
                          >회
                        </td>
                        <td class="text-center align-middle">
                          <input
                            :ref="`custom_voucher_${index}_price`"
                            v-model="customVoucher.price"
                            type="number"
                            class="form-control text-right w-90px d-inline-block mr-10px"
                            placeholder="금액 입력"
                            @input="inputCusVoucherDisCnt(index)"
                          >원
                        </td>
                        <td class="text-center align-middle">
                          <input
                            :ref="`custom_voucher_${index}_discnt`"
                            v-model="customVoucher.discnt"
                            type="number"
                            class="form-control text-right w-90px d-inline-block mr-10px"
                            placeholder="금액 입력"
                            @input="inputCusVoucherDisCnt(index)"
                          >원
                        </td>
                        <td class="text-center align-middle">
                          {{ customVoucher.sale }}
                        </td>
                        <td class="text-center align-middle">
                          <div class="custom-control custom-checkbox">
                            <input
                              :id="`customCheck9_1_${index}`"
                              v-model="customVoucher.view"
                              type="checkbox"
                              class="custom-control-input"
                              true-value="1"
                              false-value="0"
                            >
                            <label
                              class="custom-control-label"
                              :for="`customCheck9_1_${index}`"
                            />
                          </div>
                        </td>
                        <td class="text-center align-middle">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-minus-circle"
                            title="삭제"
                            @click.prevent.stop="removeCustomVoucher(index)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- 마인드톡 이용권 start -->


                <!-- 무료 프로모션 start -->
                <div class="mt-30px">
                  <h5>무료 프로모션<span class="tit_desc">참여 시 고객의 <span class="text-primary">#첫회무료</span> 키워드 검색 결과에 노출됩니다.</span></h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:200px">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          비대면 상담 첫회 무료
                        </th>
                        <td>
                          <div class="row align-items-center pl-10px">
                            <div class="custom-control custom-radio mr-20px">
                              <input
                                id="customRadio3-1"
                                v-model="tab3.firstConsFreeYn"
                                type="radio"
                                name="customRadio3"
                                class="custom-control-input"
                                value="1"
                              >
                              <label
                                class="custom-control-label"
                                for="customRadio3-1"
                              >예</label>
                            </div>
                            <div class="custom-control custom-radio mr-20px">
                              <input
                                id="customRadio3-2"
                                v-model="tab3.firstConsFreeYn"
                                type="radio"
                                name="customRadio3"
                                class="custom-control-input"
                                value="-1"
                              >
                              <label
                                class="custom-control-label"
                                for="customRadio3-2"
                              >아니오</label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 무료 프로모션 end -->


                <!-- 정산 정보 start -->
                <div class="mt-30px">
                  <h5>정산 정보</h5>

                  <div class="box-blue p-10px mt-5px lh-15em">
                    <ul class="list_dot">
                      <li>프리랜서/개인은 사업소득으로 처리합니다. </li>
                      <li>계산서/세금계산서는 개인 또는 법인 사업자에 해당하는 분만 선택 가능합니다. </li>
                      <li>상담센터의 소속 상담사 개인으로 활동 중인 경우 상담료는 해당 센터로 먼저 정산됩니다. </li>
                      <li>마인드온(EAP)/마인드톡(일반상담) 상담료는 매월 10일 지급되며 지급 관련 안내 메일이 매월 5일 이전에 발송됩니다. </li>
                      <li>계산서/세금계산서를 선택한 분들은 발행 날짜 기준을 '마지막 일자'로 작성해주시기 바랍니다. (예: 10월 실적에 대해서 10월 31일 기준 계산서 발행)</li>
                      <li>세금계산서 발행 시(계산서 아님 주의) 받아야 할 돈의 1.1배(10% 부가세를 더해)로 발행하시기 바랍니다. (예: 30만원을 받아야 한다면 33만원으로 세금계산서 발행)</li>
                    </ul>
                  </div>
                  <table class="tbl_type01 mt-10px">
                    <colgroup>
                      <col style="width:200px">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          정산 유형
                        </th>
                        <td>
                          <div class="pl-10px row">
                            <div class="pr-50px">
                              <div class="mb-5px">
                                개인 프리랜서 정산일 경우
                              </div>
                              <div class="custom-control custom-radio">
                                <input
                                  id="customRadio4-1"
                                  v-model="tab3.consfeeCalTp"
                                  type="radio"
                                  name="customRadio4"
                                  class="custom-control-input"
                                  :value="2"
                                >
                                <label
                                  class="custom-control-label"
                                  for="customRadio4-1"
                                >사업소득</label>
                              </div>
                            </div>
                            <div>
                              <div class="mb-5px">
                                개인/법인 사업자 정산일 경우
                              </div>
                              <div class="custom-control custom-radio">
                                <input
                                  id="customRadio4-2"
                                  v-model="tab3.consfeeCalTp"
                                  type="radio"
                                  name="customRadio4"
                                  class="custom-control-input"
                                  :value="1"
                                >
                                <label
                                  class="custom-control-label"
                                  for="customRadio4-2"
                                >계산서</label>
                              </div>
                              <div class="custom-control custom-radio">
                                <input
                                  id="customRadio4-3"
                                  v-model="tab3.consfeeCalTp"
                                  type="radio"
                                  name="customRadio4"
                                  class="custom-control-input"
                                  :value="3"
                                >
                                <label
                                  class="custom-control-label"
                                  for="customRadio4-3"
                                >세금계산서</label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          통장 사본
                        </th>
                        <td>
                          <div class="form-group pl-10px">
                            <input
                              type="text"
                              :value="tab3.bankbookFileItem.bankbookFileNm ? tab3.bankbookFileItem.bankbookFileNm : '선택된 파일 없음'"
                              class="pseudo_input"
                              readonly=""
                            >
                            <label
                              for="inputTypeFile3_1"
                            >파일 선택</label>
                            <input
                              id="inputTypeFile3_1"
                              :ref="tab3.bankbookFile"
                              type="file"
                              class="form-control-file"
                              @change="previewBankbook($event)"
                            >
                            <div
                              v-if="tab3.bankbookFileItem.bankbookFileUrl != null"
                              class="mt-5px w-100p"
                            >
                              <a
                                :href="tab3.bankbookFileItem.bankbookFileUrl"
                                target="_blank"
                                class="underline p-0"
                              >
                                {{ tab3.bankbookFileItem.bankbookFileNm }}
                              </a>
                              <span class="text-lightgray pl-5px">
                                ({{ (tab3.bankbookFileItem.bankbookFileSize/1024).toFixed(1) }}kb)
                              </span>
                              <button
                                type="button"
                                class="btn_tbl_icon fas fa-trash-alt"
                                title="삭제"
                                @click="deleteBankbookFile"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>


                      <tr v-if="tab3.consfeeCalTp !== 2">
                        <!-- 사업소득 선택시 안보임 : display:none; -->
                        <th scope="row">
                          사업자 등록증
                        </th>
                        <td>
                          <div class="form-group pl-10px">
                            <input
                              type="text"
                              :value="tab3.licenseFileItem.licenseFileNm ? tab3.licenseFileItem.licenseFileNm : '선택된 파일 없음'"
                              class="pseudo_input"
                              readonly=""
                            >
                            <label for="inputTypeFile3_2">파일 선택</label>
                            <input
                              id="inputTypeFile3_2"
                              :ref="tab3.licenseFile"
                              type="file"
                              class="form-control-file"
                              @change="previewLicense($event)"
                            >
                            <div
                              v-if="tab3.licenseFileItem.licenseFileUrl != null"
                              class="mt-5px w-100p"
                            >
                              <a
                                :href="tab3.licenseFileItem.licenseFileUrl"
                                target="_blank"
                                class="underline p-0"
                              >
                                {{ tab3.licenseFileItem.licenseFileNm }}
                              </a>
                              <span class="text-lightgray pl-5px">
                                ({{ (tab3.licenseFileItem.licenseFileSize/1024).toFixed(1) }}kb)
                              </span>
                              <button
                                type="button"
                                class="btn_tbl_icon fas fa-trash-alt"
                                title="삭제"
                                @click="deleteLicenseFile"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 정산 정보 end -->


                <!-- 계좌번호/주민등록번호 start -->
                <div class="mt-30px">
                  <h5>계좌번호 / 주민등록번호</h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col style="width:200px">
                    </colgroup>
                    <tbody>
                      <tr>
                        <th scope="row">
                          계좌번호
                        </th>
                        <td>
                          <div class="pl-10px">
                            <div class="text-danger mb-5px">
                              * 반드시 본인 정보 입력
                            </div>
                            <div class="custom-multi-select w-200px d-inline-block mr-10px align-middle">
                              <treeselect
                                v-model="tab3.bank"
                                :multiple="false"
                                :limit="0"
                                :options="tab3.selectBankList"
                                placeholder="선택"
                              />
                            </div>
                            <input
                              v-model="tab3.acc"
                              type="text"
                              class="form-control d-inline-block w-150px align-middle"
                              placeholder="계좌번호 입력"
                            >
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          주민등록번호
                        </th>
                        <td>
                          <div class="pl-10px">
                            <div class="text-danger mb-5px">
                              * 반드시 본인 정보 입력
                            </div>
                            <input
                              v-model="tab3.regNum.regNumStart"
                              type="text"
                              maxlength="6"
                              class="form-control d-inline-block w-150px text-center"
                              placeholder="주민번호 앞자리 입력"
                            >
                            <span class="d-inline-block px-5px">-</span>
                            <input
                              v-model="tab3.regNum.regNumEnd"
                              type="password"
                              maxlength="7"
                              class="form-control d-inline-block w-150px text-center"
                              placeholder="주민번호 뒷자리 입력"
                            >
                            <div class="text-lightgray mt-5px">
                              소득세법에 따른 세금신고 납부 의무 이행을 위해 수집하오니 양해 부탁드립니다.
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--// 계좌번호/주민등록번호 end -->
              </div>
              <!--// 상담 비용 설정 end -->




              <!-- 부가 정보 start -->
              <div
                id="tab_cont04"
                class="tab-pane container fade p-0 mt-30px w-500px"
                :class="{ active: v.tabIdx === 4, show: v.tabIdx === 4 }"
              >
                <table class="tbl_type01 mt-5px">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="text-left pl-15px"
                      >
                        <h5>현재 본인이 직접 운영 또는 소속 중인 상담센터가 있으신가요?</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="pl-15px">
                        <div class="custom-control custom-radio">
                          <input
                            id="customRadio7-1"
                            v-model="tab4.radio"
                            type="radio"
                            name="customRadio7"
                            class="custom-control-input"
                            value="1"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio7-1"
                          >네, 상담센터를 직접 운영하고 있습니다.</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input
                            id="customRadio7-2"
                            v-model="tab4.radio"
                            type="radio"
                            name="customRadio7"
                            class="custom-control-input"
                            value="2"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio7-2"
                          >네, 상담센터에 소속된 상담사로 활동 중입니다.</label>
                        </div>
                        <div class="custom-control custom-radio">
                          <input
                            id="customRadio7-3"
                            v-model="tab4.radio"
                            type="radio"
                            name="customRadio7"
                            class="custom-control-input"
                            value="3"
                          >
                          <label
                            class="custom-control-label"
                            for="customRadio7-3"
                          >아니오, 프리랜서로 자유롭게 활동 중입니다.</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>


                <!-- 소속 상담센터 등록 : 두번째 항목 선택시 노출 start -->
                <div class="mt-30px">
                  <h5>소속 상담센터 등록<span class="tit_desc">현재 소속된 상담센터를 등록해주세요.</span></h5>
                  <table class="tbl_type01 mt-5px">
                    <colgroup>
                      <col>
                      <col style="width:60px">
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          소속 상담센터
                        </th>
                        <th scope="col">
                          <button
                            type="button"
                            class="btn_tbl_icon fas fa-plus-circle"
                            title="추가"
                            @click="addCenter"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="tab4.centerList.length === 0">
                        <tr class="nodata">
                          <td
                            class="no_result"
                            colspan="2"
                          >
                            등록된 센터가 없습니다. 우측 + 버튼을 이용해 추가해 주세요
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(row, index) in tab4.centerList"
                          :key="index"
                        >
                          <td class="align-middle">
                            <div class="custom-multi-select">
                              <treeselect
                                v-model="row.consCenterId"
                                :multiple="false"
                                :limit="0"
                                :options="tab4.selectCenterList"
                                placeholder="선택"
                              />
                            <!--                              <virtual-select
                              :id="'expert-tab4-center-select'+index"
                              :selected-value="row.consCenterId"
                              :options="tab4.selectCenterList"
                              label-key="label"
                              value-key="id"
                              :placeholder="'전체'"
                            />-->
                            </div>
                          </td>
                          <td class="text-center align-middle">
                            <button
                              type="button"
                              class="btn_tbl_icon fas fa-minus-circle"
                              title="삭제"
                              @click="removeCenter(index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!--// 소속 상담센터 등록 end -->
              </div>
              <!--// 부가 정보 end -->
            </div>
            <!--// tab panes end -->
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="confirmDisabled && !fileChange && !editorChange"
            @click="saveData"
          >
            확인
          </button><!-- 내용을 수정했을 경우 disabled 삭제하여 버튼 활성화 -->
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal : 회원가입 end -->
</template>
<script>
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import ExpertJoinTemplate from "./ExpertJoinTemplate";
import ExpertJoinRec from "./ExpertJoinRec";
import ExpertJoinService from "../../services/expert/ExpertJoinService";
import localData from "@/api/localData";
import moment from "moment";
import WaveSurfer from "wavesurfer.js";
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import axios from "axios";

moment.locale("ko"); // 오전/오후 사용
export default {
  name: 'ExpertJoinPop',
  components: {
    editor: Editor,
  },
  data() {
    return {
      normalizerDepart(node) {
        return {
          id: node.cdmngId,
          label: node.codeNm
        }
      },
      normalizerTopic(node) {
        return {
          id: node.cdmngId,
          label: node.codeNm
        }
      },
      defaultOptions:{
        customHTMLRenderer: {
          htmlBlock: {
            iframe(node) {
              return [
                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                { type: 'html', content: node.childrenHTML },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
              ];
            },
          },
          htmlInline: {
            big(node, { entering }) {
              return entering
                ? { type: 'openTag', tagName: 'big', attributes: node.attrs }
                : { type: 'closeTag', tagName: 'big' };
            },
          },
        },
        /*hooks: {
          addImageBlobHook: (blob, callback) => {
            console.log('TESTING TESTING')
            const uploadedImageURL = this.uploadImage(blob)
            callback(uploadedImageURL, 'alt text')
            return false
          },
        },*/
        hideModeSwitch: false,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock'],
          ['scrollSync']
        ],
        plugins:[colorSyntax]
      },
      loginId: '',
      inteMbrId: '',
      v: {
        tabIdx: 1
      },
      confirmDisabled: true,
      fileChange: false,
      editorChange:false,
      startFlag:false,
      topicDisableFlag: true,
      departDisableFlag: true,
      tab1: { // 기본정보
        idCheck: false,
        birth: null,
        gen: 'XY',
        tel: '',
        email: '',
        mbrId: '',
        nm: '',
        pass: '',
        passCheck: '',
        profileFile: null,
        profileFileItem: {
          'profileFile': null,
          'profileFileUrl': null,
          'profileFileNm': null,
          'profileFileDesc': null,
          'profileFileSize': null,
          'profileFileMngId': null
        },
      },
      tab2: { // 상세정보
        checkDepartCdmngId1: '0',
        checkDepartCdmngId2: '0',
        checkDepartCdmngId3: '0',
        checkDepartCdmngId4: '0',
        checkNonfaceDepart1: '0',
        checkNonfaceDepart2: '0',
        checkNonfaceDepart3: '0',
        selectLicenseList:[],
        selectLicenseListTextOn: 0,
        selectLicenseListText: null,
        selectYearList:[],
        certList: [],
        educationList: [],
        selectEducationList:[
          {
            'id': '학사',
            'label':'학사'
          },
          {
            'id': '석사',
            'label':'석사'
          },
          {
            'id': '석사_재학',
            'label':'석사_재학'
          },
          {
            'id': '석사_수료',
            'label':'석사_수료'
          },
          {
            'id': '석사_졸업',
            'label':'석사_졸업'
          },
          {
            'id': '박사',
            'label':'박사'
          },
          {
            'id': '박사_재학',
            'label':'박사_재학'
          },
          {
            'id': '박사_수료',
            'label':'박사_수료'
          }
        ],
        careerList: [],
        careerDtConfOpt: {
          format: "YYYY-MM",
          useCurrent: false,
          maxDate: "2900-01",
          dayViewHeaderFormat:'YYYY년 MMMM',
          locale:'ko',
          showClear: true,
          icons: {
            time: "far fa-clock",
            date: "far fa-calendar",
            up: "fas fa-arrow-up",
            down: "fas fa-arrow-down",
            previous: "fas fa-chevron-left",
            next: "fas fa-chevron-right",
            today: "fas fa-calendar-check",
            clear: "far fa-trash-alt",
            close: "far fa-times-circle"
          }
        },
        selectTopic: null,
        tagTopicList:[],
        selectTopicList:[],
        selfTopic: null,
        tagDepartList:[],
        selectDepartList:[],
        selectDepart: null,
        selfDepart: null,
        forLangConsYn: -1,
        speechLang: null,
        easyWrYn: '-1', //deafult 간편 작성.
        introFile: null,
        introFileItem: {
          'introFile': null,
          'introFileUrl': null,
          'introFileNm': null,
          'introFileDesc': null,
          'introFileSize': null,
          'introFileMngId': null
        },
        nonfaceGrid:{
          data:[]
        },
        emplNotiYn: -1,
        resumeFileItem: {
          'resumeFile': null,
          'resumeFileUrl': null,
          'resumeFileNm': null,
          'resumeFileDesc': null,
          'resumeFileSize': null,
          'resumeFileMngId': null
        },
        resumeFile: null,
        careerFileItem: {
          'careerFile': null,
          'careerFileUrl': null,
          'careerFileNm': null,
          'careerFileDesc': null,
          'careerFileSize': null,
          'careerFileMngId': null
        },
        careerFile: null,
      },
      tab3: {
        bank: null,
        acc: null,
        defaultVoucherList: [],
        selectVoucherTpList:[
          {
            'id': 1,
            'label':'대면상담'
          },
          {
            'id': 2,
            'label':'채팅상담'
          },
          {
            'id': 3,
            'label':'대화상담'
          },
          {
            'id': 4,
            'label':'화상상담'
          }
        ],
        customVoucherList: [
          {
            name: '',
            voucherTp: '-1',
            tp: null,
            price: null,
            discnt: null,
            num: null,
            sale: '-',
            view: '-1',
            expln: ''
          },
        ],
        firstConsFreeYn: 1,
        consfeeCalTp: 2,
        licenseFileItem: {
          'licenseFile': null,
          'licenseFileUrl': null,
          'licenseFileNm': null,
          'licenseFileDesc': null,
          'licenseFileSize': null,
          'licenseFileMngId': null
        },
        licenseFile: null,
        bankbookFileItem: {
          'bankbookFile': null,
          'bankbookFileUrl': null,
          'bankbookFileNm': null,
          'bankbookFileDesc': null,
          'bankbookFileSize': null,
          'bankbookFileMngId': null
        },
        bankbookFile: null,
        selectBankList:[
          {'id': 'NH농협', 'label': 'NH농협' },
          {'id': 'KB국민', 'label': 'KB국민' },
          {'id': '카카오뱅크', 'label': '카카오뱅크' },
          {'id': '신한', 'label': '신한' },
          {'id': '우리', 'label': '우리' },
          {'id': 'IBK기업', 'label': 'IBK기업' },
          {'id': '하나', 'label': '하나' },
          {'id': '새마을', 'label': '새마을' },
          {'id': '대구', 'label': '대구' },
          {'id': '부산', 'label': '부산' },
          {'id': '케이뱅크', 'label': '케이뱅크' },
          {'id': '우체국', 'label': '우체국' },
          {'id': '신협', 'label': '신협' },
          {'id': 'SC제일', 'label': 'SC제일' },
          {'id': '경남', 'label': '경남' },
          {'id': '수협', 'label': '수협' },
          {'id': '광주', 'label': '광주' },
          {'id': '전북', 'label': '전북' },
          {'id': '토스뱅크', 'label': '토스뱅크' },
          {'id': '저축은행', 'label': '저축은행' },
          {'id': '중국공상', 'label': '중국공상' },
          {'id': 'JP모간', 'label': 'JP모간' },
          {'id': 'BNP파리바', 'label': 'BNP파리바' },
          {'id': '씨티', 'label': '씨티' },
          {'id': '제주', 'label': '제주' },
          {'id': 'KDB산업', 'label': 'KDB산업' },
          {'id': 'SBI저축은행', 'label': 'SBI저축은행' },
          {'id': '산림조합', 'label': '산림조합' },
          {'id': 'BOA', 'label': 'BOA' },
          {'id': 'HSBC', 'label': 'HSBC' },
          {'id': '중국', 'label': '중국' },
          {'id': '도이치', 'label': '도이치' },
          {'id': '중국건설', 'label': '중국건설' },
        ],
        regNum: {
          regNumStart: null,
          regNumEnd: null
        },
      },
      tab4: {
        selectCenterList: [],
        radio: 1, // 1:센터장, 2:상담사, 3:프리랜서
        centerList: [], // 센터 리스트
        deleteCenterList: [],  // 센터 삭제 리스트
      },
      stDtConfOpt: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        maxDate: "2900-01-01",
        dayViewHeaderFormat:'YYYY년 MMMM',
        locale:'ko',
        showClear: true,
        icons: {
          time: "far fa-clock",
          date: "far fa-calendar",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
          today: "fas fa-calendar-check",
          clear: "far fa-trash-alt",
          close: "far fa-times-circle"
        }
      },
      //녹음데이터
      recognize: null,
      message: null,
      isRecorder: false,
      recordDisabled: true,
      progress: 0,
      /* audio
      btnAudio: new Audio(
        require("@/assets/audio/zapsplat_multimedia_button_click_007_53868.mp3")
      ),*/
      second: 0,
       // 오디오
      audioContext: null,
      gumStream: null,
      rec: null,
      input: null,
      bufSize: null,
      microphone: null,
      processor: null,
      res: null,
      bufferArray: null,
      
      file: {},
      room: {
        cr_seq: null,
        server_id: null,
        cr_name: null,
        cr_type: null,
        cr_topic: null,
        pub_yn: null,
        cr_password: null,
        reg_id: null,
        reg_dt: null,
        mod_dt: null,
        close_dt: null,
        aiab_cr_user: null
      },

      btnTimer: null,
      
      waveCheck: 0,

       // wavesurfer
      wavesurfer: null,
      isLoading: false,
      isRecorderDisabled: false,
      isTextPlaying: false,
      waveFlag: false
    }
  },
  watch: {
    tab1: {
      deep: true,
      handler() {
        let disabled = true;
        /*필수값 체크*/
        if(!this.checkTab1Data()) {
          disabled = true;
        }
        /*프로필 파일 체크*/
        if(!this.tab1.profileFileItem.profileFile && !this.tab1.profileFileItem.profileFileMngId) {
          disabled = true;
        }
        this.confirmDisabled = disabled === true;
      }
    },
    progress: function(val) {
      if (val == 0) {
        this.waitRequest = false;
        this.recordDisabled = false;
      }
    },
    file: function(val) {
      if (val) {
        try {
          const file = new File([val.blob], val.name);
          this.wavesurfer.loadBlob(file);
        } catch (e) {
          alert(e);
        }
      } else {
        // this.destroyWaveSurfer();
      }
    },
  },
  mounted(){
    if(localData.getInteMbrId()!=null){
      this.loginId = localData.getInteMbrId();
    }
    this.fetchLicenseList();  // 라이센스 리스트
    this.fetchYearList();   // 연도 리스트
    this.fetchTopicList(); // 상담주제 리스트
    this.fetchDepartList(); // 전문분야 리스트
    this.fetchVoucherCodeList(); // 기본이용권 명칭
    this.fetchCenterList(); // Tab4 센터 리스트,
    this.tab2.nonfaceGrid.data = this.settingNonfaceScheduleList();// 비대면 상담가능 일정
  },
  methods:{
    formFileDown() {
      axios({
        url:this.$http.defaults.baseURL+'/api/formFile/downloadFile',
        method: 'GET',
        responseType: 'blob',
        params: {
          'regId': this.loginId,
          'customerType': 'EX_EXPERT_RESUME'
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const contentDisposition = response.headers['content-disposition']; // 파일 이름
        let fileName = 'unknown';
        if (contentDisposition) {
          const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
          if (fileNameMatch)
            [ , fileName ] = fileNameMatch.split('=');
        }
        this.taskFileNm = decodeURIComponent(escape(fileName));
        if(this.taskFileNm){
          this.taskFileNm = this.taskFileNm.replace(/^\"|\"$/g,'');
        }
        link.href = url;
        link.setAttribute('download', this.taskFileNm ? this.taskFileNm : '엑셀다운로드.xlsx');
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch(error => {
        alert(error.message)
      });
    },
    addCenter() {
      this.tab4.centerList.push({
        consCenterId: null,
        insertCheck: 1,
      });
    },
    removeCenter(index) {
      this.tab4.centerList.splice(index, 1);
    },
    fetchCenterList() {
      ExpertJoinService.expertJoinCenterList().then(response=> {
        this.tab4.selectCenterList = [];
        const {data} = response.data;
        if(data) {
          data.forEach((item, index) => {
            this.tab4.selectCenterList.push({
              id: item.consCenterId,
              label: item.centerNm,
            });
          });
        }
      });
    },
    inputEmplYn(index) {
      if(this.tab2.careerList[index].emplYn == 1) {
        this.tab2.careerList[index].leaveDt = null;
      }
    },
    checkListBlankNull(data) {
      return data && data.toString().replaceAll(' ', '') !== ''
    },
    checkTab1Data() {
      let check = true;
      let checkList = [
        this.tab1.mbrId, this.tab1.nm, this.tab1.pass, this.tab1.passCheck,
        this.tab1.birth, this.tab1.gen, this.tab1.tel, this.tab1.email
      ];
      checkList.forEach((item, index) => {
        if(!this.checkListBlankNull(item)) {
          check = false;
        }
      });
      return check;
    },
    settingReqTp(cdSsub) {
      let result = 0;
      if(cdSsub) {
        cdSsub = cdSsub.replaceAll(' ', '').trim();
        switch (cdSsub) {
          case '대면상담':result = 1; break;
          case '채팅상담': result = 2; break;
          case '전화상담': result = 3; break;
          case '화상상담': result = 4; break;
        }
      }
      return result;
    },
    fetchVoucherCodeList() {
      ExpertJoinService.expertJoinVoucherCodeList().then(response=> {
        const {data} = response.data;
        if(data && data.length > 0) {
          data.forEach((item, index) => {
            item.reqTp = this.settingReqTp(item.cdSsub);
            this.tab3.defaultVoucherList.push({
              cdmngId: item.cdmngId,
              name: item.codeNm,
              tp: item.cdSsub,
              price: null,
              discnt: null,
              sale: '-',
              view: '-1',
              reqTp: item.reqTp,
              reqVoucherTp: 1,
              reqNum: 1,
              cdDivOrd: parseInt(item.cdDivOrd),
              voucherNum: item.cdLsub
            });
          });
          this.tab3.defaultVoucherList.sort((a, b) => {
            return parseFloat(a.cdDivOrd) - parseFloat(b.cdDivOrd);
          });
        }
      });
    },
    inputDefVoucherDisCnt(index) {
      let defVoucherInfo = this.tab3.defaultVoucherList[index];
      if(defVoucherInfo.price && defVoucherInfo.discnt) {
        if(Number(defVoucherInfo.price) < Number(defVoucherInfo.discnt)) {
          defVoucherInfo.discnt = defVoucherInfo.price;
        }
        defVoucherInfo.sale = ((100 - (defVoucherInfo.discnt / defVoucherInfo.price * 100)).toFixed(0)) > 0
          ? (100 - (defVoucherInfo.discnt / defVoucherInfo.price * 100)).toFixed(0)  + '%'
          : '0%';
      }
    },
    inputCusVoucherDisCnt(index) {
      let cusVoucherInfo = this.tab3.customVoucherList[index];
      if(cusVoucherInfo.price && cusVoucherInfo.discnt) {
        if(Number(cusVoucherInfo.price) < Number(cusVoucherInfo.discnt)) {
          cusVoucherInfo.discnt = cusVoucherInfo.price;
        }
        cusVoucherInfo.sale = ((100 - (cusVoucherInfo.discnt / cusVoucherInfo.price * 100)).toFixed(0)) > 0
          ? (100 - (cusVoucherInfo.discnt / cusVoucherInfo.price * 100)).toFixed(0) + '%'
          : '0%';
      }
    },
    previewBankbook(event) {
      let inputFile = event.target;
      this.tab3.bankbookFileItem.bankbookFile = inputFile.files[0];
      if( inputFile.files && inputFile.files[0] ){
        this.tab3.bankbookFileItem.bankbookFileNm = inputFile.files[0].name;
        this.tab3.bankbookFileItem.bankbookFileSize = inputFile.files[0].size;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab3.bankbookFileItem.bankbookFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputFile.files[0]);
      }
    },
    deleteBankbookFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.tab3.bankbookFileItem.bankbookFile = null;
        this.tab3.bankbookFileItem.bankbookFileUrl = null;
        this.tab3.bankbookFileItem.bankbookFileNm = null;
        this.tab3.bankbookFileItem.bankbookFileDesc = null;
        this.tab3.bankbookFileItem.bankbookFileSize = null;
        this.tab3.bankbookFileItem.bankbookFileMngId = null;
      }
    },
    previewLicense(event) {
      let inputFile = event.target;
      this.tab3.licenseFileItem.licenseFile = inputFile.files[0];
      if( inputFile.files && inputFile.files[0] ){
        this.tab3.licenseFileItem.licenseFileNm = inputFile.files[0].name;
        this.tab3.licenseFileItem.licenseFileSize = inputFile.files[0].size;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab3.licenseFileItem.licenseFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputFile.files[0]);
      }
    },
    deleteLicenseFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.tab3.licenseFileItem.licenseFile = null;
        this.tab3.licenseFileItem.licenseFileUrl = null;
        this.tab3.licenseFileItem.licenseFileNm = null;
        this.tab3.licenseFileItem.licenseFileDesc = null;
        this.tab3.licenseFileItem.licenseFileSize = null;
        this.tab3.licenseFileItem.licenseFileMngId = null;
      }
    },
    removeCustomVoucher(index){
      this.tab3.customVoucherList.splice(index, 1);
    },
    addCustomVoucher() {
      this.tab3.customVoucherList.push({
        name: '',
        voucherTp: '-1',
        tp: null,
        price: null,
        discnt: null,
        num: null,
        sale: '-',
        view: '-1',
        expln: ''
      });
    },
    previewCareer(event) {
      let inputFile = event.target;
      this.tab2.careerFileItem.careerFile = inputFile.files[0];
      if( inputFile.files && inputFile.files[0] ){
        this.tab2.careerFileItem.careerFileNm = inputFile.files[0].name;
        this.tab2.careerFileItem.careerFileSize = inputFile.files[0].size;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab2.careerFileItem.careerFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputFile.files[0]);
      }
    },
    deleteCareerFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.tab2.careerFileItem.careerFile = null;
        this.tab2.careerFileItem.careerFileUrl = null;
        this.tab2.careerFileItem.careerFileNm = null;
        this.tab2.careerFileItem.careerFileDesc = null;
        this.tab2.careerFileItem.careerFileSize = null;
        this.tab2.careerFileItem.careerFileMngId = null;
      }
    },
    previewResume(event) {
      let inputFile = event.target;
      this.tab2.resumeFileItem.resumeFile = inputFile.files[0];
      if( inputFile.files && inputFile.files[0] ){
        this.tab2.resumeFileItem.resumeFileNm = inputFile.files[0].name;
        this.tab2.resumeFileItem.resumeFileSize = inputFile.files[0].size;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab2.resumeFileItem.resumeFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputFile.files[0]);
      }
    },
    deleteResumeFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.tab2.resumeFileItem.resumeFile = null;
        this.tab2.resumeFileItem.resumeFileUrl = null;
        this.tab2.resumeFileItem.resumeFileNm = null;
        this.tab2.resumeFileItem.resumeFileDesc = null;
        this.tab2.resumeFileItem.resumeFileSize = null;
        this.tab2.resumeFileItem.resumeFileMngId = null;
      }
    },
    settingNonfaceScheduleList() { // dayweek -1 /1
      return [
        { 'time':'sthh08', 'dayweek1':-1, 'dayweek2':-1, 'dayweek3':-1, 'dayweek4':-1, 'dayweek5':-1, 'dayweek6':-1, 'dayweek7':-1},
        { 'time':'sthh12', 'dayweek1':-1, 'dayweek2':-1, 'dayweek3':-1, 'dayweek4':-1, 'dayweek5':-1, 'dayweek6':-1, 'dayweek7':-1},
        { 'time':'sthh16', 'dayweek1':-1, 'dayweek2':-1, 'dayweek3':-1, 'dayweek4':-1, 'dayweek5':-1, 'dayweek6':-1, 'dayweek7':-1},
        { 'time':'sthh20', 'dayweek1':-1, 'dayweek2':-1, 'dayweek3':-1, 'dayweek4':-1, 'dayweek5':-1, 'dayweek6':-1, 'dayweek7':-1},
        { 'time':'sthh00', 'dayweek1':-1, 'dayweek2':-1, 'dayweek3':-1, 'dayweek4':-1, 'dayweek5':-1, 'dayweek6':-1, 'dayweek7':-1},
        { 'time':'sthh04', 'dayweek1':-1, 'dayweek2':-1, 'dayweek3':-1, 'dayweek4':-1, 'dayweek5':-1, 'dayweek6':-1, 'dayweek7':-1}
      ];
    },
    deleteIntroFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.tab2.introFileItem.introFile = null;
        this.tab2.introFileItem.introFileUrl = null;
        this.tab2.introFileItem.introFileNm = null;
        this.tab2.introFileItem.introFileDesc = null;
        this.tab2.introFileItem.introFileSize = null;
        this.tab2.introFileItem.introFileMngId = null;
      }
    },
    previewIntroFile(event) {
      let inputImg = event.target;
      this.tab2.introFileItem.introFile = inputImg.files[0];
      if( inputImg.files && inputImg.files[0] ){
        this.tab2.introFileItem.introFileNm = inputImg.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab2.introFileItem.introFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputImg.files[0]);
      }
    },
    fetchDepartList() {
      ExpertJoinService.expertJoinDepartList().then(response=> {
        this.tab2.selectDepartList = [];
        const {data} = response.data;
        if(data) {
          data.forEach((item, index) => {
            if(item.codeNm && item.codeNm.indexOf('기타') === -1) {
              this.tab2.selectDepartList.push(item);
            }
          });
        }
      });
    },
    removeDepartTag(index) {
      if(confirm('['+this.tab2.tagDepartList[index].codeNm+'] 전문분야를 삭제하시겠습니까?')) {
        let idx = this.tab2.selectDepart.findIndex(n => n === this.tab2.tagDepartList[index].cdmngId);
        if ( idx !== -1) {
          this.tab2.selectDepart.splice(idx,1);
        }
        this.tab2.tagDepartList.splice(index, 1);
      }
    },
    addSelfDepart() {
      if(this.tab2.selfDepart) {
        this.tab2.tagDepartList.push({
          cdmngId: 631,
          codeNm: this.tab2.selfDepart
        });
        this.tab2.selfDepart = null;
      }
    },
    clearDepart(node) {
      if(node.length === 0){
        this.tab2.tagDepartList = [];
      }
    },
    deselDepart(node) {
      let idx = this.tab2.tagDepartList.findIndex(n => n.cdmngId === node.cdmngId);
      if (idx !== -1) {
        this.tab2.tagDepartList.splice(idx,1);
      }
      let index = this.tab2.selectDepart.findIndex(n => n === node.cdmngId);
      if (index !== -1) {
        this.tab2.selectDepart.splice(index,1);
      }
    },
    selDepart(node) {
      if(node.cdmngId == "631"){
        this.departDisableFlag = false;
      }else{
        this.tab2.selfDepart = '';
        this.departDisableFlag = true;
        if(this.tab2.tagDepartList.length < 5){
          if(this.tab2.tagDepartList.includes(node.cdmngId)) {
            alert('중복된 전문분야입니다.');
          }else {
            this.tab2.tagDepartList.push(node);
          }
        }else{
          alert('상담주제는 최대 5개까지 선택 가능합니다.');
        }
      }
    },
    fetchTopicList() {
      ExpertJoinService.expertJoinTopicList().then(response=> {
        this.tab2.selectTopicList = [];
        const {data} = response.data;
        if(data && data.length > 0) {
          this.tab2.selectTopicList = data;
        }
      });
    },
    removeTopicTag(index) {
      if(confirm('['+this.tab2.tagTopicList[index].codeNm+'] 상담주제를 삭제하시겠습니까?')) {
        let idx = this.tab2.selectTopic.findIndex(n => n === this.tab2.tagTopicList[index].cdmngId);
        if ( idx !== -1) {
          this.tab2.selectTopic.splice(idx,1);
        }
        this.tab2.tagTopicList.splice(index, 1);
      }
    },
    addSelfTopic() {
      if(this.tab2.tagTopicList.length > 5) {
        alert('상담주제는 최대 5개까지 선택 가능합니다.');
      }else {
        if(this.tab2.tagTopicList.filter(n=>n.cdmngId === 572).length > 0) {
          alert('상담주제 직접 입력은 1개만 가능합니다.');
        }else {
          if(this.tab2.selfTopic) {
            this.tab2.tagTopicList.push({
              cdmngId: 572,
              codeNm: this.tab2.selfTopic
            });
            this.tab2.selfTopic = null;
          }
        }
      }
    },
    clearTopic(node) {
      if(node.length === 0){
        this.tab2.tagTopicList = [];
      }
    },
    deselTopic(node) {
      let idx = this.tab2.tagTopicList.findIndex(n => n.cdmngId === node.cdmngId);
      if (idx !== -1) {
        this.tab2.tagTopicList.splice(idx,1);
      }
      let index = this.tab2.selectTopic.findIndex(n => n === node.cdmngId);
      if (index !== -1) {
        this.tab2.selectTopic.splice(index,1);
      }
    },
    selTopic(node) {

      // this.tab2.tagTopicList.forEach((item) => {
      //   if(item.cdmngId == "572"){
      //     this.selfTopicFlag = true;
      //   }
      // });

      // if(this.selfTopicFlag){
      //     this.tab2.selectTopic.splice(this.tab2.selectTopicList.length,1); // 체크박스 해제,
      // }

      if(node.cdmngId == "572"){
        this.topicDisableFlag = false;
      }else{
        this.tab2.selfTopic = '';
        this.topicDisableFlag = true;
        if(this.tab2.tagTopicList.length < 5){
          if(this.tab2.tagTopicList.includes(node.cdmngId)) {
            alert('중복된 상담주제입니다.');
          }else {
            this.tab2.tagTopicList.push(node);
          }
        }else{
          alert('상담주제는 최대 5개까지 선택 가능합니다.');
        }
      }
    },

    
    addCareer() {
      this.tab2.careerList.push({
        joinDt: null,
        leaveDt: null,
        compNm: null,
        taskCon: null,
        emplYn: -1,
      });
    },
    removeCareer(index) {
      this.tab2.careerList.splice(index, 1);
    },
    addEducation() {
      this.tab2.educationList.push({
        school: null,
        major: null,
        degree: null,
        degreeAcqYear: null
      });
    },
    removeEducation(index) {
      this.tab2.educationList.splice(index, 1);
    },
    openBrWindow(imgUrl, name){
      if(!imgUrl) return;
      imgUrl = this.$http.defaults.baseURL + imgUrl;
      let image = new Image();
      image.src = imgUrl;
      let w = window.open("",name,'width=539,height=341');
      w.document.write(image.outerHTML);
    },
    deleteCertFile(index) {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.tab2.certList[index].certificateFile.certFile = null;
        this.tab2.certList[index].certificateFile.certFileUrl = null;
        this.tab2.certList[index].certificateFile.certFileNm = null;
        this.tab2.certList[index].certificateFile.certFileDesc = null;
        this.tab2.certList[index].certificateFile.certFileSize = null;
        this.tab2.certList[index].certificateFile.certFileMngId = null;
      }
    },
    previewCert(event, index) {
      let inputFile = event.target;
      this.tab2.certList[index].certificateFile.certFile = inputFile.files[0];
      if( inputFile.files && inputFile.files[0] ){
        this.tab2.certList[index].certificateFile.certFileNm = inputFile.files[0].name;
        this.tab2.certList[index].certificateFile.certFileSize = inputFile.files[0].size;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab2.certList[index].certificateFile.certFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputFile.files[0]);
      }
    },
    fetchYearList() {
      this.tab2.selectYearList = [];
      let year = moment().format('YYYY');
      for (let i = 1950; i <= year; i++) {
        this.tab2.selectYearList.push({
          label: i,
          id: i
        });
      }
      this.tab2.selectYearList.push({
        label: '정보없음',
        id: -1
      });
      this.tab2.selectYearList = this.tab2.selectYearList.reverse();
    },
    fetchLicenseList() {
      ExpertJoinService.expertJoinLicenseList().then(response=> {
        this.tab2.selectLicenseList = [];
        const {data} = response.data;
        data.forEach(item => {
          this.tab2.selectLicenseList.push({
            id: item.cdmngId,
            label: item.code
          });
        });
      });
    },
    addCert() {
      this.tab2.certList.push({
        textOn: '0',
        text: null,
        expCertId: null,
        mainViewYn: -1,
        cdmngId: null,
        certNm: null,
        certIssueDt: null,
        certificate: null,
        certificateFile: {
          'certFile': null,
          'certFileUrl': null,
          'certFileNm': null,
          'certFileDesc': null,
          'certFileSize': null,
          'certFileMngId': null
        }
      });
    },
    removeCert(expCertId, index) {
      this.tab2.certList.splice(index, 1);
    },
    changeLicense(cert, index) {
      this.tab2.certList[index].text = null;
      if(this.tab2.certList[index].cdmngId === 683) {
        this.tab2.certList[index].textOn = '1';
      }else {
        this.tab2.certList[index].textOn = '0';
      }
    },
    previewProfileFile(event) {
      this.fileChange = true;
      if(!this.checkTab1Data()) {
        this.fileChange = false;
      }
      let inputImg = event.target;
      this.tab1.profileFileItem.profileFile = inputImg.files[0];
      if( inputImg.files && inputImg.files[0] ){
        this.tab1.profileFileItem.profileFileNm = inputImg.files[0].name;
        let reader = new FileReader();
        reader.onload = (e) => {
          this.tab1.profileFileItem.profileFileUrl = e.target.result;
        }
        reader.readAsDataURL(inputImg.files[0]);
      }
    },
    deleteProfileFile() {
      if(confirm('파일을 삭제하시겠습니까?')) {
        this.fileChange = false;
        this.tab1.profileFileItem.profileFile = null;
        this.tab1.profileFileItem.profileFileUrl = null;
        this.tab1.profileFileItem.profileFileNm = null;
        this.tab1.profileFileItem.profileFileDesc = null;
        this.tab1.profileFileItem.profileFileSize = null;
        this.tab1.profileFileItem.profileFileMngId = null;
      }
    },
    alertTab1Value(){
      alert('필수 항목을 입력해주세요.');
    },
    showExpertJoinTemplate() {
      this.$modal.show(
        ExpertJoinTemplate,{
        },
        {
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    showExpertJoinRec() {
      this.$modal.show(
        ExpertJoinRec, {
          loginId: this.loginId,
          type:'join'
      }, {
        height: 'auto'
      }, {
        'before-open': () => {},
        'before-close': () => {},
      })
    },
    checkId() {
      ExpertJoinService.expertJoinTab1IdCheck({mbrId: this.tab1.mbrId}).then(response=>{
        const {data} = response.data;
        if(data.idCheck) {
          this.alertTab1('사용할 수 있는 아이디입니다.');
        }else {
          this.alertTab1('중복된 아이디입니다.');
        }
        this.tab1.idCheck = data.idCheck === true;
      }).catch(err => {
        this.page.loading = false;
      })
    },
    moveTab(tabNum) {
      if(tabNum < this.v.tabIdx) {
        this.alertTab1('이전 탭으로 이동할 수 없습니다.\n 로그인 후 정보수정을 이용해주세요.');
        return;
      }

      if(tabNum === 1){
        this.v.tabIdx = 1;
      }else if(tabNum === 2) {
        if(!this.inteMbrId) {
          this.alertTab1('기본 정보를 등록해주세요.');
          return;
        }
        this.v.tabIdx = 2;
      }else if(tabNum === 3) {
        if(!this.inteMbrId) {
          this.alertTab1('기본 정보를 등록해주세요.');
          return;
        }
        this.v.tabIdx = 3;
      }else if(tabNum === 4) {
       if(!this.inteMbrId) {
          this.alertTab1('기본 정보를 등록해주세요.');
          return;
        }
        this.v.tabIdx = 4;
      }
    },
    saveData() {
      if(this.v.tabIdx === 1){
        let idCheck =  /^[a-zA-Z0-9_ㄱ-ㅎㅏ-ㅣ가-힣]*$/;
        if(!idCheck.test(this.tab1.mbrId)){
          alert("아이디는 한글, 영어, 숫자, _ 만 입력 가능합니다. 다시 입력해주세요.");
          this.$refs.mbrId.focus();
          return;
        }
        if(!this.tab1.idCheck) {
          this.alertTab1('아이디 중복체크를 해주세요.');
          return;
        }
        if(this.tab1.pass !== this.tab1.passCheck) { // 비밀번호 = 비밀번호 확인
          this.alertTab1('비밀번호를 확인해주세요.');
          return;
        }
        if(!this.checkTab1Value()) { // 필수값 체크.
          this.alertTab1('필수값을 입력해주세요.');
          return;
        }

        if(this.tab1.tel){
          if(this.tab1.tel.indexOf('-') == -1){
            alert("휴대폰 번호를 - 포함해서 입력해주세요.");
            return;
          }
        }
      }
      this.saveCheck();
    },
    checkTab1Value() {
      return this.checkBlankNull(this.tab1.mbrId) && this.checkBlankNull(this.tab1.nm) && this.checkBlankNull(this.tab1.pass)
      && this.checkBlankNull(this.tab1.birth) && this.checkBlankNull(this.tab1.tel) && this.checkBlankNull(this.tab1.email)
      && this.tab1.profileFileItem.profileFile !== null
    },
    checkBlankNull(data) {
      return data && data.replaceAll(' ', '') !== ''
    },
    saveCheck() {
      switch (this.v.tabIdx) {
        case 1:
          this.saveTab1();
          break;
        case 2:
          this.saveTab2();
          break;
        case 3:
          this.saveTab3();
          break;
        case 4:
          this.saveTab4();
          break;
      }
    },
    saveTab1() {
      let tab1Form = new FormData();
      //프로필 사진
      if(this.tab1.profileFileItem.profileFile) {
        tab1Form.append('expertTab1.profileImg', this.tab1.profileFileItem.profileFile);
      }
      // 전문가 ID
      tab1Form.append('expertTab1.mbrId', this.tab1.mbrId);
      // 전문가 inteMbrId - TODO:: 임시. 2022-02-14 확인 필요
      tab1Form.append('expertTab1.inteMbrId', '20.000.'+this.tab1.mbrId);
      // 비밀번호
      tab1Form.append('expertTab1.pwd', this.tab1.pass);
      // 이름
      tab1Form.append('expertTab1.nm', this.tab1.nm);
      // 생년월일
      tab1Form.append('expertTab1.birth',this.tab1.birth? this.tab1.birth.replaceAll('-', '') : '');
      // 성별
      tab1Form.append('expertTab1.gen', this.tab1.gen);
      // 휴대폰
      tab1Form.append('expertTab1.tel', this.tab1.tel? this.tab1.tel : '');
      // 이메일
      tab1Form.append('expertTab1.email', this.tab1.email? this.tab1.email : '');
      // 작성자
      tab1Form.append('expertTab1.regId', this.loginId);
      tab1Form.append('expertTab1.modId', this.loginId);

      ExpertJoinService.expertJoinTab1Create(tab1Form).then(response => {
        const data = response.data;
        this.inteMbrId = data.data.inteMbrId;

        if(data.resultCode === 'ERROR') {
          alert('등록을 실패했습니다.\n관리자에게 문의해주세요.');
        }else {
          if(confirm('회원정보가 등록되었습니다.\n상세정보를 계속 입력하시겠습니까?')) {
            this.v.tabIdx = 2;
          }else {
            alert('회원가입이 완료되었습니다.');
            this.close();
          }
        }
      })
    },
    alertTab1(text) {
      alert(text);
    },
    settingReqNonfaceScheduleList() {
      return [
        {'dayWeek':1,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
        {'dayWeek':2,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
        {'dayWeek':3,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
        {'dayWeek':4,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
        {'dayWeek':5,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
        {'dayWeek':6,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
        {'dayWeek':7,'sthh08':-1,'sthh12':-1,'sthh16':-1,'sthh20':-1,'sthh00':-1,'sthh04':-1},
      ];
    },

    checkEducationVal() {
      let result = false;

      for(let i=0; i<this.tab2.educationList.length; i++){
        if(!this.tab2.educationList[i].school || !this.tab2.educationList[i].major 
          || !this.tab2.educationList[i].degree || !this.tab2.educationList[i].degreeAcqYear) {
            let alertText = '', check = '';
            if(!this.tab2.educationList[i].school) {
              alertText = '학교명을 입력해주세요.'
              check = 'school';
            }else if(!this.tab2.educationList[i].major) {
              alertText = '전공을 입력해주세요.'
              check = 'major';
            }else if(!this.tab2.educationList[i].degree) {
              alertText = i+1 +'번째 행의 학위를 선택해주세요.'
              check = 'degree';
            }else if(!this.tab2.educationList[i].degreeAcqYear) {
              alertText = i+1 +'번째 행의 취득년도를 선택해주세요.'
              check = 'degreeAcqYear';
            }
              
            alert(alertText);
            if(check === 'school') {
              this.$refs[`education_${i}_school`][0].focus();
            }else if(check === 'major') {
              this.$refs[`education_${i}_major`][0].focus();
            }else if(check === 'degree') {
              //this.$refs[`education_${i}_degree`][0].focus();
            }else if(check === 'degreeAcqYear') {
              //this.$refs[`education_${i}_degreeAcqYear`][0].focus();
            }

            result = true;
            break;
          }
      }

      return result;
    },

    checkCareerVal() {
      let result = false;

      for(let i=0; i<this.tab2.careerList.length; i++){
        if(!this.tab2.careerList[i].joinDt || (this.tab2.careerList[i].emplYn == -1 && !this.tab2.careerList[i].leaveDt)
          || !this.tab2.careerList[i].compNm || !this.tab2.careerList[i].taskCon) {
            let alertText = '', check = '';
            if(!this.tab2.careerList[i].compNm) {
              alertText = '조직명을 입력해주세요.'
              check = 'compNm';
            }else if(!this.tab2.careerList[i].taskCon) {
              alertText = '직책 및 업무내용을 입력해주세요.'
              check = 'taskCon';
            }else if(!this.tab2.careerList[i].joinDt) {
              alertText = i+1 +'번째 행의 입사년월을 선택해주세요.'
              check = 'joinDt';
            }else if(!this.tab2.careerList[i].leaveDt) {
                alertText = i+1 +'번째 행의 퇴사년월을 선택해주세요.'
                check = 'leaveDt';
            }
              
            alert(alertText);
            if(check === 'compNm') {
              this.$refs[`career_${i}_compNm`][0].focus();
            }else if(check === 'taskCon') {
              this.$refs[`career_${i}_taskCon`][0].focus();
            }

            result = true;
            break;
          }
      }

      return result;
    },

    saveTab2() {
      

      let checkValidEducation = false;
      checkValidEducation = this.checkEducationVal();
      if(checkValidEducation){
        return;
      }

      let checkValidCareer = false;
      checkValidCareer = this.checkCareerVal();
      if(checkValidCareer){
        return;
      }

      let tab2Form = new FormData();
      tab2Form.append(`ExpertTab2.inteMbrId`, this.inteMbrId);
      tab2Form.append(`ExpertTab2.regId`,  this.loginId);
      tab2Form.append(`ExpertTab2.modId`,  this.loginId);

      // 자격증 정보 리스트 - 대표 자격증, 자격증, 발급년도, 자격증파일
      this.tab2.certList.forEach((item, index) => {
        if(item.cdmngId && item.expCertId === null) {
          this.tab2.selectLicenseList.forEach((item2, index2) => {
            if(item.cdmngId === item2.id) {
              tab2Form.append(`ExpertTab2.certList[${index}].inteMbrId`, this.inteMbrId);
              tab2Form.append(`ExpertTab2.certList[${index}].certNm`, item2.label);
              tab2Form.append(`ExpertTab2.certList[${index}].certIssueDt`, item.certIssueDt);
              tab2Form.append(`ExpertTab2.certList[${index}].mainViewYn`, item.mainViewYn);
              tab2Form.append(`ExpertTab2.certList[${index}].regId`,  this.loginId);
              tab2Form.append(`ExpertTab2.certList[${index}].cdmngId`,  item.cdmngId);

              if(item.certificateFile.certFile) {
                tab2Form.append(`ExpertTab2.certList[${index}].certificate`,  item.certificateFile.certFile);
              }
            }
          });
        }
      });

      // 이력서
      if(this.tab2.resumeFileItem.resumeFile) {
        tab2Form.append('ExpertTab2.resume', this.tab2.resumeFileItem.resumeFile);
      }

      // 경력 증명서
      if(this.tab2.careerFileItem.careerFile) {
        tab2Form.append('ExpertTab2.career', this.tab2.careerFileItem.careerFile);
      }

      // 전문가 구분 -  tpDiv: 11 심리상담, 12 언어치료, 13 발달치료, 14 재무상담
      let expertFieldIndex = 0;
      if(this.tab2.checkDepartCdmngId1 === '1') { //전문가 구분 + 심리상담 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 11);
        expertFieldIndex++;
      }
      if(this.tab2.checkDepartCdmngId2 === '1') { //전문가 구분 + 언어치료 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 12);
        expertFieldIndex++;
      }
      if(this.tab2.checkDepartCdmngId3 === '1') { //전문가 구분 + 발달치료 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 13);
        expertFieldIndex++;
      }
      if(this.tab2.checkDepartCdmngId4 === '1') { //전문가 구분 + 재무상담 체크
        tab2Form.append(`ExpertTab2.expertFieldList[${expertFieldIndex}].tpDiv`, 14);
        expertFieldIndex++;
      }

      // 제공가능 비대면 상담 - tpDiv: 21 전화상담, 22 채팅상담, 23 화상상담
      let nonFaceListIndex = 0;
      if(this.tab2.checkNonfaceDepart1 === '1') {
        tab2Form.append(`ExpertTab2.nonFaceList[${expertFieldIndex}].tpDiv`, 21);
        nonFaceListIndex++;
      }
      if(this.tab2.checkNonfaceDepart2 === '1') {
        tab2Form.append(`ExpertTab2.nonFaceList[${expertFieldIndex}].tpDiv`, 22);
        nonFaceListIndex++;
      }
      if(this.tab2.checkNonfaceDepart3 === '1') {
        tab2Form.append(`ExpertTab2.nonFaceList[${expertFieldIndex}].tpDiv`, 23);
        nonFaceListIndex++;
      }

      // 최종 학력 리스트 - 학교명, 전공, 학위, 취득년도
      this.tab2.educationList.forEach((item, index) => {
        if(item.school) {
          tab2Form.append(`ExpertTab2.educationList[${index}].school`, item.school);
          tab2Form.append(`ExpertTab2.educationList[${index}].major`, item.major);
          tab2Form.append(`ExpertTab2.educationList[${index}].degree`, item.degree);
          tab2Form.append(`ExpertTab2.educationList[${index}].degreeAcqYear`, item.degreeAcqYear);
        }
      });

      // 상담 관련 경력 리스트 - 입사년도, 퇴사년도, 재직중여부, 회사명, 직책 및 업무내용
      this.tab2.careerList.forEach((item, index) => {
        if(item.compNm) {
          tab2Form.append(`ExpertTab2.careerList[${index}].joinDt`, this.settingCareerReqDt(item.joinDt) ? this.settingCareerReqDt(item.joinDt) : -1);
          tab2Form.append(`ExpertTab2.careerList[${index}].leaveDt`, this.settingCareerReqDt(item.leaveDt) ? this.settingCareerReqDt(item.leaveDt) : -1);
          tab2Form.append(`ExpertTab2.careerList[${index}].compNm`, item.compNm);
          tab2Form.append(`ExpertTab2.careerList[${index}].taskCon`, item.taskCon);
          tab2Form.append(`ExpertTab2.careerList[${index}].emplYn`, item.emplYn); // 재직중 추가.
        }
      });

      // 상담주제 태그 리스트 - 전문분야와 달리 직접 입력만 inputTag 에,
      this.tab2.tagTopicList.forEach((item, index) => {
        tab2Form.append(`ExpertTab2.tagList[${index}].cdmngId`, item.cdmngId);
        tab2Form.append(`ExpertTab2.tagList[${index}].ord`, index);
        if(item.cdmngId === 572) {
          tab2Form.append(`ExpertTab2.tagList[${index}].inputTag`, item.codeNm? item.codeNm : '');
        }
      });

      // 전문분야 리스트 departList
      this.tab2.tagDepartList.forEach((item, index) => {
        tab2Form.append(`ExpertTab2.departList[${index}].cdmngId`, item.cdmngId);
        tab2Form.append(`ExpertTab2.departList[${index}].codeNm`, item.codeNm);
        tab2Form.append(`ExpertTab2.departList[${index}].othCodeNm`, '');
      });

      // 외국어 상담 - 외국어 가능 여부(1, -1), 가능 외국어, 외국어 금액
      tab2Form.append(`ExpertTab2.forLangConsYn`, this.tab2.forLangConsYn);
      tab2Form.append(`ExpertTab2.speechLang`, this.tab2.speechLang? this.tab2.speechLang : '');

      // 전문가 상세 소개
      if(this.tab2.easyWrYn) {
        tab2Form.append(`ExpertTab2.easyWrYn`, this.tab2.easyWrYn);
      }
      if(this.tab2.easyWrYn === '-1') { // 간편작성
        if(this.tab2.easyWrMainTit) {
          tab2Form.append(`ExpertTab2.easyWrMainTit`, this.tab2.easyWrMainTit);
        }
        if(this.tab2.easyWrSubTit1) {
          tab2Form.append(`ExpertTab2.easyWrSubTit1`, this.tab2.easyWrSubTit1);
        }
        if(this.tab2.easyWrCon1) {
          tab2Form.append(`ExpertTab2.easyWrCon1`, this.tab2.easyWrCon1);
        }
        if(this.tab2.easyWrSubTit2) {
          tab2Form.append(`ExpertTab2.easyWrSubTit2`, this.tab2.easyWrSubTit2);
        }
        if(this.tab2.easyWrCon2) {
          tab2Form.append(`ExpertTab2.easyWrCon2`, this.tab2.easyWrCon2);
        }
        if(this.tab2.introFileItem.introFile) {
          tab2Form.append('ExpertTab2.introFile', this.tab2.introFileItem.introFile);
        }
      }else { // 세부작성 - 에디터
        if(this.$refs.toastuiEditor.invoke('getHTML') != null && this.$refs.toastuiEditor.invoke('getHTML') != ''){
          tab2Form.append('ExpertTab2.intro',this.$refs.toastuiEditor.invoke('getHTML'));
        }
      }

      // 채용 공고 수신여부
      tab2Form.append(`ExpertTab2.emplNotiYn`, this.tab2.emplNotiYn);
      
      // 녹음
      if(this.file && this.file.blob && this.file.name) {
        const file = new File([this.file.blob], this.file.name);
        tab2Form.append('ExpertTab2.voiceFile', file);
        tab2Form.append('ExpertTab2.recordingTime', this.second.toFixed(2) * 100); //곱하기 100 해서 저장 (보여줄때 100나눠서 보여줘야 됨)
      }
      
      // 비대면 상담 가능 일정
      let reqScheduleList = this.settingReqNonfaceScheduleList();
      this.tab2.nonfaceGrid.data.forEach((item, index) => {
        if(item.time === 'sthh08') {
          if(item.dayweek1 === '1') {reqScheduleList[0].sthh08 = 1;}
          if(item.dayweek2 === '1') {reqScheduleList[1].sthh08 = 1;}
          if(item.dayweek3 === '1') {reqScheduleList[2].sthh08 = 1;}
          if(item.dayweek4 === '1') {reqScheduleList[3].sthh08 = 1;}
          if(item.dayweek5 === '1') {reqScheduleList[4].sthh08 = 1;}
          if(item.dayweek6 === '1') {reqScheduleList[5].sthh08 = 1;}
          if(item.dayweek7 === '1') {reqScheduleList[6].sthh08 = 1;}
        }
        if(item.time === 'sthh12') {
          if(item.dayweek1 === '1') {reqScheduleList[0].sthh12 = 1;}
          if(item.dayweek2 === '1') {reqScheduleList[1].sthh12 = 1;}
          if(item.dayweek3 === '1') {reqScheduleList[2].sthh12 = 1;}
          if(item.dayweek4 === '1') {reqScheduleList[3].sthh12 = 1;}
          if(item.dayweek5 === '1') {reqScheduleList[4].sthh12 = 1;}
          if(item.dayweek6 === '1') {reqScheduleList[5].sthh12 = 1;}
          if(item.dayweek7 === '1') {reqScheduleList[6].sthh12 = 1;}
        }
        if(item.time === 'sthh16') {
          if(item.dayweek1 === '1') {reqScheduleList[0].sthh16 = 1;}
          if(item.dayweek2 === '1') {reqScheduleList[1].sthh16 = 1;}
          if(item.dayweek3 === '1') {reqScheduleList[2].sthh16 = 1;}
          if(item.dayweek4 === '1') {reqScheduleList[3].sthh16 = 1;}
          if(item.dayweek5 === '1') {reqScheduleList[4].sthh16 = 1;}
          if(item.dayweek6 === '1') {reqScheduleList[5].sthh16 = 1;}
          if(item.dayweek7 === '1') {reqScheduleList[6].sthh16 = 1;}
        }
        if(item.time === 'sthh20') {
          if(item.dayweek1 === '1') {reqScheduleList[0].sthh20 = 1;}
          if(item.dayweek2 === '1') {reqScheduleList[1].sthh20 = 1;}
          if(item.dayweek3 === '1') {reqScheduleList[2].sthh20 = 1;}
          if(item.dayweek4 === '1') {reqScheduleList[3].sthh20 = 1;}
          if(item.dayweek5 === '1') {reqScheduleList[4].sthh20 = 1;}
          if(item.dayweek6 === '1') {reqScheduleList[5].sthh20 = 1;}
          if(item.dayweek7 === '1') {reqScheduleList[6].sthh20 = 1;}
        }
        if(item.time === 'sthh00') {
          if(item.dayweek1 === '1') {reqScheduleList[0].sthh00 = 1;}
          if(item.dayweek2 === '1') {reqScheduleList[1].sthh00 = 1;}
          if(item.dayweek3 === '1') {reqScheduleList[2].sthh00 = 1;}
          if(item.dayweek4 === '1') {reqScheduleList[3].sthh00 = 1;}
          if(item.dayweek5 === '1') {reqScheduleList[4].sthh00 = 1;}
          if(item.dayweek6 === '1') {reqScheduleList[5].sthh00 = 1;}
          if(item.dayweek7 === '1') {reqScheduleList[6].sthh00 = 1;}
        }
        if(item.time === 'sthh04') {
          if(item.dayweek1 === '1') {reqScheduleList[0].sthh04 = 1;}
          if(item.dayweek2 === '1') {reqScheduleList[1].sthh04 = 1;}
          if(item.dayweek3 === '1') {reqScheduleList[2].sthh04 = 1;}
          if(item.dayweek4 === '1') {reqScheduleList[3].sthh04 = 1;}
          if(item.dayweek5 === '1') {reqScheduleList[4].sthh04 = 1;}
          if(item.dayweek6 === '1') {reqScheduleList[5].sthh04 = 1;}
          if(item.dayweek7 === '1') {reqScheduleList[6].sthh04 = 1;}
        }
      });

      reqScheduleList.forEach((item, index) => {
        tab2Form.append(`ExpertTab2.scheduleList[${index}].dayWeek`, item.dayWeek);
        tab2Form.append(`ExpertTab2.scheduleList[${index}].sthh08`, item.sthh08);
        tab2Form.append(`ExpertTab2.scheduleList[${index}].sthh12`, item.sthh12);
        tab2Form.append(`ExpertTab2.scheduleList[${index}].sthh16`, item.sthh16);
        tab2Form.append(`ExpertTab2.scheduleList[${index}].sthh20`, item.sthh20);
        tab2Form.append(`ExpertTab2.scheduleList[${index}].sthh00`, item.sthh00);
        tab2Form.append(`ExpertTab2.scheduleList[${index}].sthh04`, item.sthh04);
      });

      ExpertJoinService.expertJoinTab2Create(tab2Form).then(response => {
        const data = response.data;

        if(data.resultCode === 'ERROR') {
          alert('등록을 실패했습니다.\n관리자에게 문의해주세요.');
        }else {
          if(confirm('상세정보가 등록되었습니다.\n상담 비용 설정을 계속 입력하시겠습니까?')) {
            this.v.tabIdx = 3;
          }else {
            alert('회원가입이 완료되었습니다.');
            this.close();
          }
        }
      })
    },
    settingCareerReqDt(data) {
      if(data) {
        return data.replace('-', '');
      }else {
        return null;
      }
    },

    checkBankValue(){
      let result = false;
        if(this.tab3.acc != null &&  (typeof this.tab3.bank == "undefined"  || this.tab3.bank == null)){
            alert('은행을 선택하여 주세요.');
            result = true;
        }
      return result;
    },
    checkVoucherValue() {
      let result = false;
      for(let i=0; i<this.tab3.defaultVoucherList.length; i++) {
        if(this.tab3.defaultVoucherList[i].cdmngId !== 667)  { /*공감봇이 아니면*/
          if(this.tab3.defaultVoucherList[i].view == 1
            && (!this.tab3.defaultVoucherList[i].price || !this.tab3.defaultVoucherList[i].discnt)) {
            let alertText = '', check = '';
            if(!this.tab3.defaultVoucherList[i].price) {
              alertText = '이용권 정가를 입력해주세요.'
              check = 'price';
            }else if(!this.tab3.defaultVoucherList[i].discnt) {
              alertText = '이용권 할인가를 입력해주세요.'
              check = 'discnt'
            }

            alert(alertText);
            if(check === 'price') {
              this.$refs[`default_voucher_${i}_price`][0].focus();
            }else if(check === 'discnt') {
              this.$refs[`default_voucher_${i}_discnt`][0].focus();
            }
            result = true;
            break;
          }
        }
      }
      return result;
    },
    checkVoucherValueCus() {
      let result = false;
      for(let i=0; i<this.tab3.customVoucherList.length; i++) {
        if(this.tab3.customVoucherList[i].cdmngId !== 667)  { /*공감봇이 아니면*/
          if(!this.tab3.customVoucherList[i].price || !this.tab3.customVoucherList[i].discnt
            || !this.tab3.customVoucherList[i].name || !this.tab3.customVoucherList[i].expln
            || !this.tab3.customVoucherList[i].num || !this.tab3.customVoucherList[i].tp) {
            let alertText = '', check = '';
            if(!this.tab3.customVoucherList[i].price) {
              alertText = '이용권 정가를 입력해주세요.'
              check = 'price';
            }else if(!this.tab3.customVoucherList[i].discnt) {
              alertText = '이용권 할인가를 입력해주세요.'
              check = 'discnt';
            }else if(!this.tab3.customVoucherList[i].name){
              alertText = '맞춤 이용권 명칭을 입력해주세요.'
              check = 'name';
            }else if(!this.tab3.customVoucherList[i].expln){
              alertText = '맞춤 이용권 설명을 입력해주세요.'
              check = 'expln';
            }else if(!this.tab3.customVoucherList[i].num){
              alertText = '상담횟수를 입력해주세요.'
              check = 'num';
            }else if(!this.tab3.customVoucherList[i].tp){
              alertText = i+1 +'번째 행의 상담방식을 선택해주세요.'
              check = 'tp';
            }

            alert(alertText);
            if(check === 'price') {
              this.$refs[`custom_voucher_${i}_price`][0].focus();
            }else if(check === 'discnt') {
              this.$refs[`custom_voucher_${i}_discnt`][0].focus();
            }else if(check === 'name') {
              this.$refs[`custom_voucher_${i}_name`][0].focus();
            }else if(check === 'expln') {
              this.$refs[`custom_voucher_${i}_expln`][0].focus();
            }else if(check === 'num') {
              this.$refs[`custom_voucher_${i}_num`][0].focus();
            }
            result = true;
            break;
          }
        }
      }
      return result;
    },
    saveTab3() {
      let checkValid = false;
      checkValid = this.checkVoucherValue();
      if(checkValid){
        return;
      }

      let checkValidCus = false;
      checkValidCus = this.checkVoucherValueCus();
      if(checkValidCus){
        return;
      }

      let checkValidBank = false;
      checkValidBank = this.checkBankValue();
      if(checkValidBank){
        return;
      }

      let tab3Form = new FormData();
      tab3Form.append(`ExpertTab3.inteMbrId`, this.inteMbrId);
      tab3Form.append(`ExpertTab3.regId`,  this.loginId);
      tab3Form.append(`ExpertTab3.modId`,  this.loginId);

      tab3Form.append(`ExpertTab3.firstConsFreeYn`,  this.tab3.firstConsFreeYn);
      tab3Form.append(`ExpertTab3.bank`,  this.tab3.bank? this.tab3.bank : '');
      tab3Form.append(`ExpertTab3.acc`,  this.tab3.acc? this.tab3.acc : '');
      tab3Form.append(`ExpertTab3.regNum`,  this.tab3.regNum.regNumStart + this.tab3.regNum.regNumEnd);
      tab3Form.append(`ExpertTab3.consfeeCalTp`, this.tab3.consfeeCalTp);

      // 통장 사본
      if(this.tab3.bankbookFileItem.bankbookFile) {
        tab3Form.append('ExpertTab3.bankCopy', this.tab3.bankbookFileItem.bankbookFile);
      }

      // 사업자 등록증
      if(this.tab3.licenseFileItem.licenseFile) {
        tab3Form.append('ExpertTab3.license', this.tab3.licenseFileItem.licenseFile);
      }

      // 기본 이용권
      this.tab3.defaultVoucherList.forEach((item, index) => {
        tab3Form.append(`ExpertTab3.voucherList[${index}].inteMbrId`,  this.inteMbrId);
        tab3Form.append(`ExpertTab3.voucherList[${index}].cdmngId`,  item.cdmngId);
        tab3Form.append(`ExpertTab3.voucherList[${index}].voucherPrice`,  item.price? item.price : 0);
        tab3Form.append(`ExpertTab3.voucherList[${index}].voucherDiscnt`,  item.discnt? item.discnt : 0);
        tab3Form.append(`ExpertTab3.voucherList[${index}].voucherSenum`,  0);
        tab3Form.append(`ExpertTab3.voucherList[${index}].voucherNm`,  item.name);
        tab3Form.append(`ExpertTab3.voucherList[${index}].ord`,  index+1);
        tab3Form.append(`ExpertTab3.voucherList[${index}].expln`,  null);
        tab3Form.append(`ExpertTab3.voucherList[${index}].mdtalkViewYn`, item.view);
        tab3Form.append(`ExpertTab3.voucherList[${index}].voucherTp`, item.reqVoucherTp? item.reqVoucherTp : 0);
        tab3Form.append(`ExpertTab3.voucherList[${index}].tp`, item.reqTp? item.reqTp : 0);
        tab3Form.append(`ExpertTab3.voucherList[${index}].voucherNum`, item.voucherNum? item.voucherNum : 0);
      });

      // 맞춤 이용권
      let customVoucherListIndex = this.tab3.defaultVoucherList.length;
      this.tab3.customVoucherList.forEach((item, index) => {
        if(item.name && item.tp) {
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].inteMbrId`,  this.inteMbrId); // 맞춤은 cdmngId 0 고정.
          tab3Form.append(`ExpertTab3.voucherList[${index}].cdmngId`,  0); // 맞춤은 cdmngId 0 고정.
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].voucherPrice`,  item.price? item.price : 0);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].voucherDiscnt`,  item.discnt? item.discnt : 0);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].voucherSenum`,  0); // 이용권회기수?
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].voucherNm`,  item.name);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].ord`,  customVoucherListIndex+index+1);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].expln`,  item.expln? item.expln : '');
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].mdtalkViewYn`, item.view);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].voucherTp`, item.voucherTp);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].tp`, item.tp);
          tab3Form.append(`ExpertTab3.voucherList[${customVoucherListIndex}].voucherNum`, item.num);
          customVoucherListIndex++;
        }
      });

      ExpertJoinService.expertJoinTab3Create(tab3Form).then(response => {
        const data = response.data;

        if(data.resultCode === 'ERROR') {
          alert('등록을 실패했습니다.\n관리자에게 문의해주세요.')
        }else {
          if(confirm(('상담 비용 설정이 등록되었습니다.\n부가정보를 계속 입력하시겠습니까?'))) {
            this.v.tabIdx = 4;
          }else {
            alert('회원가입이 완료되었습니다.');
            this.close();
          }
        }
      })
    },
    saveTab4() {
      let tab4Form = new FormData();
      tab4Form.append('expertTab4.radio', this.tab4.radio);

      if(this.tab4.deleteCenterList && this.tab4.deleteCenterList.length > 0) {
        this.tab4.deleteCenterList.forEach((item, index) => {
          tab4Form.append(`expertTab4.deleteCenterList[${index}].inteMbrId`, this.inteMbrId);
          tab4Form.append(`expertTab4.deleteCenterList[${index}].consCenterId`, item.consCenterId);
        })
      }
      if(this.tab4.centerList && this.tab4.centerList.length > 0) {
        this.tab4.centerList.forEach((item, index) => {
          tab4Form.append(`expertTab4.centerList[${index}].insertCheck`, item.insertCheck);
          tab4Form.append(`expertTab4.centerList[${index}].inteMbrId`, this.inteMbrId);
          tab4Form.append(`expertTab4.centerList[${index}].consCenterId`, item.consCenterId);
        });
      }

      ExpertJoinService.expertJoinTab4Update(tab4Form).then(response => {
        const data = response.data;
        if(data.resultCode === 'ERROR') {
          alert('등록을 실패했습니다.\n관리자에게 문의해주세요.')
        }else {
          alert('회원가입이 완료되었습니다.');
          this.close();
        }
      })
    },
    //목소리 녹음
    onRecorder() {
      clearInterval(this.btnTimer);
      this.btnTimer = setTimeout(() => {
        if (!this.recognize) {
          if (this.message == null) {
            if (this.isRecorder) {
              this.stopRecording();
              //this.btnAudio.play();
            } else {
              this.startRecording();
              //this.btnAudio.play();
            }
          } else {
            // document.getElementById("messageForm").reset();
            this.isRecorderDisabled = true;
            this.stopRecording(true, true);
            this.secondReset(); //초 리셋
            //this.btnAudio.play();
            setTimeout(() => {
              this.isRecorderDisabled = false;
              this.startRecording();
            }, 100);
          }
        }
      }, 200);
    },
    startRecording() {
      this.startRecordingStart();
    },
    startRecordingStart() {
      var constraints = { audio: true, video: false };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.startUserMedia)
        .catch(err => {
          console.log(err);
          console.log(err.name);

          if (
            err.name === "NotFoundError" ||
            err.name === "DevicesNotFoundError"
          ) {
            // required track is missing
            // constraints 매개변수 조건에 맞는 미디어 트랙이 없는 경우 이 에러가 발생합니다.
          } else if (
            err.name === "NotReadableError" ||
            err.name === "TrackStartError"
          ) {
            // webcam or mic are already in use
            // 사용자가 접근 권한을 부여했고 조건에 맞는 미디어 트랙도 있지만 어떤 이유로든 장치에 액세스 할 수 없어서 운영체제, 브라우저, 웹 페이지 레벨에서 하드웨어 에러가 발생하여 이 에러가 발생합니다.
          } else if (
            err.name === "OverconstrainedError" ||
            err.name === "ConstraintNotSatisfiedError"
          ) {
            // constraints can not be satisfied by avb. devices
          } else if (
            err.name === "NotAllowedError" ||
            err.name === "PermissionDeniedError"
          ) {
            // permission denied in browser
            // 사용자가 브라우저 설정을 통해 장치에 대한 접근권한을 차단하였거나 장치 사용 권한 요청에 거부한 경우 이 에러가 발생합니다. 이 외에도 어떤 식으로든 장치에 대한 접근을 차단하였다면 이 에러가 발생합니다.
          } else if (err.name === "TypeError" || err.name === "TypeError") {
            // empty constraints object
          } else {
            // other errors
          }

          // alert("마이크가 없습니다. 마이크를 연결해 주세요 : " + err);
          alert(
            "마이크에 대한 접근 권한이 없습니다.\n휴대폰 설정을 통해 해당 브라우저에 대한  마이크 접근 권한을 허용해 주세요"
          );
          this.isRecorder = false;
          // DOMException: Requested device not found
        });
    },
    startUserMedia(stream) {
      this.waveCheck = 0;

      if (!this.audioContext) {
        this.audioContext = new AudioContext();
      }
      this.bufSize = 4096;

      this.gumStream = stream;
      this.microphone = this.audioContext.createMediaStreamSource(stream);
      this.processor = this.audioContext.createScriptProcessor(
        this.bufSize,
        1,
        1
      );
      /*this.res = new Resampler(
        this.audioContext.sampleRate,
        16000,
        1,
        this.bufSize
      );*/

      this.bufferArray = [];

      this.processor.onaudioprocess = event => {
        try {
          const outBuf = event.inputBuffer.getChannelData(0); //변경함 (resample 제거)

          this.bufferArray.push.apply(this.bufferArray, outBuf);

          this.second = this.bufferArray.length / this.audioContext.sampleRate; //초
          if (60 <= this.second) {
            alert(
              "녹음이 끝나면 녹음버튼을 다시 눌러야 저장됩니다. \n다시 녹음해 주세요."
            );
            console.log("60초");
            // 셀바스 실시간 STT 종료
            this.stopRecording(true);
          }
        } catch (e) {
          console.log('에러',e)
          this.stopRecording(true);
        }
      };

      if (this.processor && this.microphone) {
        this.bufferArray = [];
        this.microphone.connect(this.processor);
        this.processor.connect(this.audioContext.destination);

        this.isRecorder = true;
      } else {
        this.isRecorder = false;
      }
    },
    secondReset() {
      this.second = 0;
    },
    stopRecording(cancel = false, isRecorder = false) {

      // alert(this.waveCheck);
      if (this.processor && this.microphone) {
        this.microphone.disconnect();
        this.processor.disconnect();
        this.gumStream.getAudioTracks()[0].stop();
        console.log("stopRecording processor disconnect");
        // this.file
      }

      if (cancel) {
        this.message = null;
        this.second = 0;
        this.gumStream = null;
        this.microphone = null;
        this.processor = null;
        this.res = null;
        this.bufSize = null;
        this.bufferArray = [];
        if (isRecorder) {
          this.isRecorder = true;
        }
        setTimeout(() => {
          if (isRecorder) {
            this.isRecorder = true;
          } else {
            this.isRecorder = false;
          }

          this.file = null;
        }, 100);
      } else {
        //create the wav blob and pass it on to createDownloadLink
        // this.rec.exportWAV(this.createDownloadLink);
        var pcm = new Float32Array(this.bufferArray);

        var dataview = this.encodeWAV(pcm);
        var audioBlob = new Blob([dataview], { type: "audio/wav" });

        this.recognize = "recognize";
        this.createDownloadLink(audioBlob);
      }
    },
    encodeWAV(samples) {
      console.log("encodeWAV ====");
      var numChannels = 1;
      var sampleRate = this.audioContext.sampleRate;
      var buffer = new ArrayBuffer(44 + samples.length * 2);
      var view = new DataView(buffer);

      /* RIFF identifier */
      this.writeString(view, 0, "RIFF");
      /* RIFF chunk length */
      view.setUint32(4, 36 + samples.length * 2, true);
      /* RIFF type */
      this.writeString(view, 8, "WAVE");
      /* format chunk identifier */
      this.writeString(view, 12, "fmt ");
      /* format chunk length */
      view.setUint32(16, 16, true);
      /* sample format (raw) */
      view.setUint16(20, 1, true);
      /* channel count */
      view.setUint16(22, numChannels, true);
      /* sample rate */
      view.setUint32(24, sampleRate, true);
      /* byte rate (sample rate * block align) */
      view.setUint32(28, sampleRate * 4, true);
      /* block align (channel count * bytes per sample) */
      view.setUint16(32, numChannels * 2, true);
      /* bits per sample */
      view.setUint16(34, 16, true);
      /* data chunk identifier */
      this.writeString(view, 36, "data");
      /* data chunk length */
      view.setUint32(40, samples.length * 2, true);

      this.floatTo16BitPCM2(view, 44, samples);

      return view;
    },
    writeString(view, offset, string) {
      for (var i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    floatTo16BitPCM2(output, offset, input) {
      for (var i = 0; i < input.length; i++, offset += 2) {
        var s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      }
    },
    createDownloadLink(blob) {
      var url = URL.createObjectURL(blob);

      var name = moment().format("DDMMYYYYhhmmss") + ".wav";
      this.file = {
        url,
        blob,
        name
      };
      this.message = this.senarioMsgText;
      this.isRecorder = false;
      this.recognize = null;

      this.gumStream = null;
      this.microphone = null;
      this.processor = null;
      this.res = null;
      this.bufSize = null;
      this.bufferArray = [];
        
      this.createWaveSurfer();
      if (this.second < 0.5) {
        this.message = null;
        this.recognize = null;

        this.gumStream = null;
        this.microphone = null;
        this.processor = null;
        this.res = null;
        this.bufSize = null;
        this.bufferArray = [];

        this.secondReset();
        alert(
          "음성녹음이 정상적으로 되지 않은것 같습니다.\n다시 녹음해 주세요"
        );
      }
    },
    createWaveSurfer() {
      if(this.wavesurfer==null){
        this.waveFlag = true;
        this.wavesurfer = WaveSurfer.create({
          container: "#waveform",
          hideScrollbar: true,
          waveColor: "#827fc9",
          progressColor: "#525398",
          cursorColor: "#ff0000",
          barWidth: 3,
          height: "55"
        });
      }
      /*
      this.wavesurfer.load(
        "https://ia902606.us.archive.org/35/items/shortpoetry_047_librivox/song_cjrg_teasdale_64kb.mp3"
      );
      */

      this.wavesurfer.on("error", err => {
        console.error(err);
        this.isLoading = false;
      });

      this.wavesurfer.on("loading", () => {
        this.isLoading = true;
      });

      this.wavesurfer.on("ready", () => {
        this.isLoading = false;
      });
    },
    destroyWaveSurfer() {
      if (this.wavesurfer) {
        this.wavesurfer.playPause();
        this.wavesurfer.destroy();
        // this.wavesurfer.cl
      }
    },
    //목소리 녹음 삭제
    deleteVoiceFile(){
      this.file = null;
      if (this.wavesurfer) {
        if(this.wavesurfer.isPlaying()){
          this.wavesurfer.playPause();
        }
        this.wavesurfer.destroy();
        this.secondReset();
      }
      this.wavesurfer = null;
    },
    close(){
      this.$emit("close");
    }
  }
}
</script>
