<template>
  <!-- modal : 비밀번호 수정 start -->
  <div
    id="pass_reset"
    class="modal fade type_w400 show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="pass_reset"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            비밀번호 수정
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <!-- 항목 : 기존 비밀번호 start -->
            <div class="h-40">
              <label class="type_flex align-items-center">
                <p class="w-120px pr-10">현재 비밀번호</p>
                <div class="flex_item">
                  <input
                    v-model="m.existPwd"
                    type="password"
                    class="form-control"
                    placeholder="현재 비밀번호 입력"
                  >
                </div>
              </label>
            </div>
            <!--// 항목 : 기존 비밀번호 end -->

            <!-- 항목 : 새 비밀번호 start -->
            <div class="h-40">
              <label class="type_flex align-items-center">
                <p class="w-120px pr-10">새 비밀번호</p>
                <div class="flex_item">
                  <input
                    v-model="m.pwd"
                    type="password"
                    class="form-control"
                    placeholder="새 비밀번호 입력"
                  >
                </div>
              </label>
            </div>
            <!--// 항목 : 새 비밀번호 end -->

            <!-- 항목 : 새 비밀번호 확인 start -->
            <div class="h-40">
              <label class="type_flex align-items-center">
                <p class="w-120px pr-10">새 비밀번호 확인</p>
                <div class="flex_item">
                  <input
                    v-model="m.pwdCheck"
                    type="password"
                    class="form-control"
                    placeholder="새 비밀번호 재 입력"
                    @input="inputPwd"
                  >
                </div>
              </label>
              <div
                v-if="pwdCom === false && m.pwd && m.pwdCheck"
                class="pl-120px mt-5px text-danger mt-5px"
              >
                새 비밀번호가 일치하지 않습니다.
              </div>
            </div>
            <!--// 항목 : 새 비밀번호 확인 end -->
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="confirmDisabled"
            @click="saveData"
          >
            저장
          </button><!-- 내용을 수정했을 경우 disabled 삭제하여 버튼 활성화 -->
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal : 비밀번호 수정 end -->
</template>
<script>
import localData from "@/api/localData";
import ExpertInfoService from "../../services/expert/ExpertInfoService";

export default {
  name: 'ExpertPassChange',
  components: {
  },
  props:{
  },
  data(){
    return{
      loginId: '',
      pwdCom: false,
      keyList: ['existPwd', 'pwd', 'pwdCheck'],
      confirmDisabled: true,
      m: {
        existPwd: '',
        pwd: '',
        pwdCheck: '',
      },
      store: {
        existPwd: '',
        pwd: '',
        pwdCheck: '',
      }
    }
  },
  computed:{

  },
  watch: {
    m: {
      deep: true,
      handler() {
        let disabled = true;
        this.keyList.some(key=>{
          if((this.store[key] != null && this.m[key] != this.store[key])||(this.store[key] == null && this.m[key])){
            disabled = false;
            return true;
          }
        })

        // 비밀번호 동일 체크
        if(!this.pwdCom) {
          disabled = true;
        }

        this.confirmDisabled = disabled === true;
      }
    },
  },
  destroyed(){

  },
  created(){

  },
  mounted(){
    if(localData.getInteMbrId()!=null){
      this.loginId = localData.getInteMbrId();
    }
    this.store = JSON.parse(JSON.stringify(this.m));
  },
  methods:{
    spaceCheck(data) {
      return data ? data.replaceAll(' ', '').trim() : null;
    },
    inputPwd() {
      this.pwdCom = this.spaceCheck(this.m.pwd) === this.spaceCheck(this.m.pwdCheck);
    },
    updatePassChange() {
      let params = {
        inteMbrId: this.loginId,
        existPwd: this.m.existPwd,
        pwd: this.m.pwd
      };
      ExpertInfoService.expertInfoPassChange(params).then(response => {
        const { resultCode, description } = response.data;
        let resultText = '';
        if(resultCode === 'OK') {
          alert('변경 되었습니다.');
          this.close();
        }else if(resultCode === 'ERROR') {
          if(description === 'Invalid password') {
            alert('현재 비밀번호가 일치하지 않습니다.');
          }else {
           alert('변경을 실패했습니다.');
          }
        }
      })
    },
    saveData() {
      if(confirm('비밀번호를 변경 하시겠습니까?')) {
        this.updatePassChange();
      }
    },
    close() {
      this.$emit("close");
    },
  }
}

</script>
