import http from '@/api/http';

export default {
    
  // 상담축어록관리 엑셀 다운로드 bat
  verbaMainExcelDown(search={},params={}) {
    return http.post('/api/verba/batch/excel/MainDownload',search,{params:params});
  },
  // 상담분석결과 엑셀 다운로드 bat
  verbaAnalExcelDown(search={},params={}) {
    return http.post('/api/verba/batch/excel/AnalDownload',search,{params:params});
  },
  // 배치 상태 확인
  commonJobNoti(params={}) {
    return http.get('/api/common/jobNoti',{params:params});
  },
  // commonJobNoti 결과 taskStatus : "C" 일경우 다운로드 실행
  commonExcelDown(params={}) {
    return http.get('/api/common/excel/download',{params:params},{responseType: 'blob'});
  },
  
}