<template>
  <!-- modal :  가져오기 start -->
  <div
    id="upload"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="upload"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            가져오기
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">
              ×
            </span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <!-- 항목 : 파일 포맷 start -->
            <div class="row h-40">
              <label class="type_flex align-items-center">
                <p class="w-100px pr-10">
                  파일 포맷
                </p>
                <div class="flex_item">
                  <select class="form-control">
                    <option>Excel</option>
                    <option>JSON</option>
                  </select>
                </div>
              </label>
            </div>
            <!--// 항목 : 파일 포맷 end -->

            <!-- 항목 : 첨부파일 start -->
            <div class="row h-40 type_flex align-items-center">
              <p class="w-100px pr-10">
                첨부파일
              </p>
              <div class="flex_item row form-group">
                <template v-if="importFile != null">
                  <input
                    v-model="importFile.name"
                    type="text"
                    class="pseudo_input"
                    readonly
                  >
                </template>
                <template v-else>
                  <input
                    type="text"
                    placeholder="선택된 파일 없음"
                    class="pseudo_input"
                    readonly
                  >
                </template>
                <label for="inputTypeFile">
                  파일 선택
                </label>
                <input
                  id="inputTypeFile"
                  ref="importFile"
                  type="file"
                  class="form-control-file"
                  @change="previewFile"
                >
              </div>
            </div>
            <!--// 항목 : 첨부파일 end -->	


            <!-- 항목 : 사전 초기화 start -->
            <div class="row pl-100">
              <div class="custom-control custom-checkbox">
                <input
                  id="customCheck1"
                  type="checkbox"
                  class="custom-control-input"
                >
                <label
                  class="custom-control-label"
                  for="customCheck1"
                >
                  사전 초기화
                </label>
              </div>
            </div>
            <!--// 항목 : 사전 초기화 end -->

            <!-- 항목 : 경고 start -->
            <div class="row pl-95">
              <div
                class="error_msg"
                style="display:inline-block;"
              >
                <i class="icons icon-ic-warning" />중복 데이터는 업데이트 됩니다.
              </div>
            </div>
            <!--// 항목 : 경고 end -->

            <!-- 가져오기 결과 : 업로드 후 노출 start -->
            <hr>
            <div>
              <h5 class="ft16 font-weight-medium mb-14">
                가져오기 결과
              </h5>
              <div class="row h-40 type_flex align-items-center">
                <p class="w-100px">
                  전체 건수
                </p>
                <div class="flex_item">
                  1,233건<span class="pl-10">
                    (중복 23건)
                  </span>
                </div>
              </div>

              <div class="row type_flex">
                <p class="w-100px pt-6">
                  중복 데이터
                </p>
                <div class="flex_item">
                  <ul class="list-group list-group-depth type_upload">
                    <li class="list-group-item">
                      ACTIVITY_PACKAGE_NAME
                    </li>
                    <li class="list-group-item">
                      ACTIVITY_PACKAGE_NAME
                    </li>
                    <li class="list-group-item">
                      ACTIVITY_PACKAGE_NAME
                    </li>
                    <li class="list-group-item">
                      ACTIVITY_PACKAGE_NAME
                    </li>
                    <li class="list-group-item">
                      ACTIVITY_PACKAGE_NAME
                    </li>
                    <li class="list-group-item">
                      ACTIVITY_PACKAGE_NAME
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--// 가져오기 결과 end -->
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="saveData"
          >
            확인
          </button>
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal :  가져오기 end -->
</template>
<script>
export default {
    name: 'FormUl',
    props: {
      id: {
        type: Number,
        default: null,
        required: false
      },
    },
    data() {
        return {
          importFile: null,
        }
    },
    methods:{
      previewFile() {
        if (0 < this.$refs.importFile.files.length) {
          this.importFile = this.$refs.importFile.files[0];
          console.log('파일',this.importFile);
        } else {
          this.importFile = null;
        }
        // this.files = this.$refs.importFile.files
      },
      saveData(){
        let form = new FormData();
        form.append('custId','test1');
        form.append('logo',this.importFile);
        form.append('regId','test1');
        console.log(this.importFile)
        for (let value of form.values()) {
          console.log(value);
        }
        this.$http
          .post('/customer/createWithUpload', form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      },
      close(){
          this.$emit("close");
      }
    }
}
</script>