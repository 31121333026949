import http from '@/api/http';

export default {
  // SelectBox 전문가구분
  expertComInfoExpTpList() {
    return http.get('/api/code/findAllList?cdDiv=전문가구분');
  },
  // SelectBox 자격정보
  expertComInfoLicenseList() {
    return http.get('/api/code/findAllList?cdDiv=자격정보');
  },
  // SelectBox 전문분야
  expertComInfoDepartList() {
    return http.get('/api/code/findAllList?cdDiv=전문분야');
  },
  // SelectBox 상담주제
  expertComInfoTopicList() {
    return http.get('/api/code/findAllList?cdDiv=상담주제');
  },
  // 비밀번호 초기화
  sendTemplateEmail(params={}) {
    return http.post('/api/email/template/send-each-mail', params);
  },
  // 비밀번호 변경
  expertInfoPassChange(params) {
    return http.post('/api/common/passChange?inteMbrId='+params.inteMbrId+'&existPwd='+params.existPwd+'&pwd='+params.pwd);
  },
  // 기본이용권표준코드
  expertComInfoVoucherCodeList() {
    return http.get('/api/code/findAllList?cdDiv=기본이용권표준코드');
  },
  // Tab1 기본 정보 등록
  expertComInfoTab1Create(form) {
    return http.post('/api/exp/my/createTab1',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab2 상세 정보 등록
  expertComInfoTab2Create(form) {
    return http.put('/api/exp/my/updateTab2',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab3 상담 비용 설정 등록
  expertComInfoTab3Create(form) {
    return http.put('/api/exp/my/updateTab3',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },



  // Tab1 기본 정보 조회
  expertComInfoTab1Read(inteMbrId={}) {
    return http.get('/api/exp/my/readTab1?inteMbrId='+inteMbrId);
  },

  // Tab1 기본 정보 수정 - 사내
  expertComInfoTab1Update(form) {
    return http.put('/api/exp/my/updateCompanyTab1',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },

  // Tab2 기본 정보 조회
  expertComInfoTab2Read(inteMbrId={}) {
    return http.get('/api/exp/my/readTab2?inteMbrId='+inteMbrId);
  },

  // Tab2 상세 정보 수정 - 사내
  expertComInfoTab2Update(form) {
    return http.put('/api/exp/my/updateCompanyTab2',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },


}
