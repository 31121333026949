<template>
  <div oncontextmenu="return false;">
    <ul
      :id="elementId"
      v-click-outside="onClickOutside"
      class="ctx-menu-vue"
    >
      <li
        v-for="(option, index) in optionAuth"
        :key="index"
        class="ctx-menu-vue__item"
        @click="optionClicked(option)"
      >
        <a
          :title="option.title"
          :class="option.click === true ? '' : 'a_disabled'"
        >
          {{ option.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { eventOff, eventOn } from './utils';

import Vue from 'vue';
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

export default {
  name: 'CtxMenu',
  props: {
    elementId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    closeOnScroll: {
      type: Boolean,
      default: true,
    },
    authorize: {
      type: String,
      default: null,
    },
    userAuthorize: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      item: null,
      menuWidth: null,
      menuHeight: null,
      disabled: true,
      titleText: '',
      optionAuth: [],
      auth: [
        {
          auth: 'D',
          authText: '삭제',
          dep: 4,
        },
        {
          auth: 'C',
          authText: '추가',
          dep: 3,
        },
        {
          auth: 'U',
          authText: '수정',
          dep: 2,
        },
        {
          auth: 'R',
          authText: '조회',
          dep: 1,
        },
      ]
    };
  },
  watch: {
    closeOnScroll(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      if (newValue && this.show) {
        this.addScrollEventListener();
      } else {
        this.removeScrollEventListener();
      }
    },
  },
  beforeDestroy() {
    if (this.closeOnScroll) {
      this.removeScrollEventListener();
    }
  },
  mounted() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      this.optionAuth = this.options;
      let authorizeLv = 0;

      this.auth.forEach((item, index) => {
        if(this.authorize === item.auth) {
          authorizeLv = item.dep;
        }
      });

      if(authorizeLv < this.auth.filter(n=>n.auth === 'C').map(n=>n.dep)) {
        this.optionAuth.forEach((item, index) => {
          if(item.slug === 'upload') {
            item.title = this.auth.filter(n=>n.auth === 'C').map(n=>n.authText)+' 권한이 없습니다.';
            item.click = false;
          }else {
            item.click = true;
          }
        });
      }else {
        this.optionAuth.forEach((item, index) => {
          item.click = true;
        });
      }
    },
    addScrollEventListener() {
      eventOn(window, 'scroll', this.hideContextMenu);
    },
    showMenu(event, item) {
      $('.ctx-menu-vue--active')
      .children('li')
      .hide();
      $('#' + this.elementId)
      .children('li')
      .show();

      this.item = item;
      const menu = document.getElementById(this.elementId);
      if (!menu) {
        return;
      }
      if (this.closeOnScroll) {
        this.addScrollEventListener();
      }

      if (!this.menuWidth || !this.menuHeight) {
        menu.style.visibility = 'hidden';
        menu.style.display = 'block';
        this.menuWidth = menu.offsetWidth;
        this.menuHeight = menu.offsetHeight;
        menu.removeAttribute('style');
      }
      if (this.menuWidth + event.clientX >= window.innerWidth) {
        menu.style.left = `${event.clientX - this.menuWidth + 2}px`;
      } else {
        menu.style.left = `${event.clientX - 2}px`;
        menu.style.left = `${event.clientX - this.menuWidth + 2}px`;
      }
      if (this.menuHeight + event.clientY >= window.innerHeight) {
        menu.style.top = `${event.clientY - this.menuHeight + 2}px`;
      } else {
        menu.style.top = `${event.clientY - 2}px`;
      }
      menu.style.right = 'auto';
      menu.style.position = 'fixed';
      menu.classList.add('ctx-menu-vue--active');
    },

    hideContextMenu() {
      const element = document.getElementById(this.elementId);

      if (element) {
        if (this.closeOnScroll) {
          this.removeScrollEventListener();
        }
        element.classList.remove('ctx-menu-vue--active');
      }
    },

    removeScrollEventListener() {
      eventOff(window, 'scroll', this.hideContextMenu);
    },

    onClickOutside() {
      this.hideContextMenu();
    },

    optionClicked(option) {
      this.hideContextMenu();
      if(option.click) {
        this.$emit('option-clicked', {
          item: this.item,
          option,
        });
      }
    },
  },
};
</script>

<style>
.a_disabled {
  cursor: default !important;
  opacity: 1;
  color: #ddd !important;
  background: #fff !important;
}

.ctx-menu-vue--active {
  display: block !important;
  z-index: 10000 !important;
}

/*.opt_menu {
  z-index: 9999 !important;
}*/

.ctx-menu-vue li a {
  cursor: pointer;
}
li.noclick {
  pointer-events: none;
  opacity: 0.4;
}
</style>
