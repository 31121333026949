<template>
  <div
    :id="id"
  />
</template>
<script>
export default {
  name: "VirtualSelect",
  props: {
    id: { type: String, default: "vir-select" },
    options: { type: Array, default: () => [] },
    valueKey: { type: String, default: "label" },
    labelKey: { type: String, default: "value" },
    descriptionKey: { type: String, default: "description" },
    aliasKey: { type: String, default: "alias" },
    placeholder: { type: String, default: "선택" },
    selectedValue: { type: [Array, String], default: () => null},
    multiple: { type: Boolean, default: false },
    search: { type: Boolean, default: true },
    showSelOptFirst:{ type: Boolean, default: false },
    searchPlaceholderText: { type: String, default: "검색" },
    noOptionsText: { type: String, default: "선택할 항목이 없습니다." },
    noSearchResultsText: { type: String, default: "입력하신 항목이 없습니다." },
    optionsSelectedText: { type: String, default: '개 선택'},
    dropboxWidth: { type: String, default: null},
    tooltipMaxWidth: { type: String, default: '300px'},
    tooltipFontSize: { type: String, default: '14px'},
    optionHeight: { type: String, default: '30px'},
    hideClearButton:  { type: String, default: 'false'}
  },
  watch: {
    options(array) {
      this.init(array);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init(options = []) {
      const {
        id, valueKey, labelKey, descriptionKey, aliasKey, placeholder,
        selectedValue, multiple, search, showSelOptFirst, searchPlaceholderText, noOptionsText,
        noSearchResultsText, optionsSelectedText, dropboxWidth, tooltipMaxWidth, tooltipFontSize,
        optionHeight, hideClearButton
      } = this;
      VirtualSelect.init({
        ele: `#${id}`,
        placeholder,
        options,
        search,
        multiple,
        searchPlaceholderText,
        noOptionsText,
        noSearchResultsText,
        optionsSelectedText,
        optionHeight,
        aliasKey,
        valueKey,
        labelKey,
        dropboxWidth,
        tooltipMaxWidth,
        tooltipFontSize,
        descriptionKey,
        selectedValue,
        hideClearButton,
        showSelectedOptionsFirst:showSelOptFirst,
      });
    },
  }
}
</script>
