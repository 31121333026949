var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ off: _vm.nav.fold },attrs:{"id":"wrapper"}},[(_vm.type === 'layout')?[_c('div',{staticClass:"left_area gray"},[_c('div',{staticClass:"left_head"},[_c('a',{staticClass:"btn_navi",attrs:{"href":"#","title":"메뉴 접기"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.navFold($event)}}}),_c('h1',[_vm._v("mind-on 전문가")]),_c('button',{staticClass:"btn_navi_close",attrs:{"type":"button","title":"메뉴 닫기"}})]),_c('ul',{staticClass:"navi"},_vm._l((_vm.menuList),function(row){return _c('li',{key:row.menuView,on:{"mouseover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, row)},"mouseout":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, row)},"click":_vm.menuhide}},[(row.children.length>0)?[_c('a',{class:{
                on: (_vm.foldCheck && row.active && _vm.onCheck)
                  || (!_vm.foldCheck&& _vm.mouse.over && _vm.mouse.children !== false && _vm.mouse.children.indexOf(row.menuView) !== -1 )
                  || (!_vm.foldCheck&& _vm.mouse.over && _vm.mouse.children === false && row.menuView == _vm.mouse.name),
                open: row.active,
                on_view: row.active,
                'fas fa-building': row.menuView === 'ExpEap',// - 마인드온 (EAP)
                'fas fa-user-friends': row.menuView === 'ExpCou',// - 마인드톡 (일반상담)
                'fas fa-robot': row.menuView === 'ExpAi',// - AI 상담분석
                'fas fa-user-circle': row.menuView === 'ExpMy',// - 마이 페이지
              },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviShow($event, row)}}},[_c('span',[_vm._v(" "+_vm._s(row.menuName)+" ")]),_c('i',{staticClass:"fas fa-angle-down"})]),_c('transition',{attrs:{"name":"slide"}},[((_vm.foldCheck && row.active) || (!_vm.foldCheck && _vm.mouse.over))?_c('div',{staticClass:"sub_wrap depth2_wrap",class:{ show: (_vm.foldCheck && row.active)}},[_c('ul',_vm._l((row.children),function(subMenu){return _c('li',{key:subMenu.menuView,on:{"mouseover":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, subMenu)},"mouseout":function($event){$event.preventDefault();$event.stopPropagation();return _vm.naviMouse($event, subMenu)}}},[_c('router-link',{class:{
                        on: _vm.menu.on === subMenu.menuView || _vm.menu.on.indexOf(subMenu.menuView) > -1
                      },attrs:{"to":{ path: subMenu.url }},nativeOn:{"click":function($event){return _vm.naviShow($event, subMenu)}}},[_vm._v(" "+_vm._s(subMenu.menuName)+" ")])],1)}),0)]):_vm._e()])]:[_c('router-link',{class:{
                on: (_vm.menu.on === row.menuView && _vm.foldCheck) ||!_vm.foldCheck && _vm.mouse.over&& row.menuView == _vm.mouse.name,
                on_view: (_vm.menu.currMenu === row.menuView && !_vm.foldCheck) ||!_vm.foldCheck && _vm.mouse.over&& row.menuView == _vm.mouse.name,
                'fas fa-tachometer-alt': row.menuView === 'ExpDash', // - 대시보드
                'fas fa-comments': row.menuView === 'ExpCoun', // - 나의 상담실
                'fas fa-calendar-check': row.menuView === 'ExpSch', // - 상담 일정 관리
              },attrs:{"to":{ path: row.url }},on:{"click":function($event){_vm.menu.currMenu = row.menuView}}},[_c('span',[_vm._v(_vm._s(row.menuName))])])]],2)}),0),_c('div',{staticClass:"navi_foot"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.showPersona}},[_vm._v(" 개인정보처리방침 ")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.showPolicy}},[_vm._v(" 이용약관 ")])])]),_c('div',{staticClass:"right_area"},[_c('div',{staticClass:"right_head"},[_c('button',{staticClass:"m-menu",attrs:{"type":"button","title":"메뉴 열기"}}),_c('h2',{staticClass:"float-left"},[(_vm.menu.navDepth.length>0)?[_vm._l((_vm.menu.navDepth),function(depth){return [_vm._v(" "+_vm._s(depth)+" > ")]})]:_vm._e(),_c('strong',[_vm._v(_vm._s(_vm.menu.navTitle))])],2),_c('button',{staticClass:"m-btn_config far fa-user",attrs:{"type":"button","title":"설정"}}),_c('ul',[_c('li',{staticClass:"center_btn"},[_c('button',{staticClass:"far fa-building",attrs:{"type":"button"},on:{"click":function($event){return _vm.showCenterPopup()}}},[_vm._v(" 내 센터 목록 ")])]),_c('li',{staticClass:"user_setup m-d-none"},[_c('button',{staticClass:"fas fa-user",attrs:{"type":"button","title":"정보 수정"},on:{"click":_vm.showExpertInfoEdit}},[_vm._v(" 정보 수정 ")])]),_c('li',{staticClass:"user_info"},[_c('span',[_vm._v("전문가 ("+_vm._s(_vm.dqMindUserId)+")")]),_vm._v("님 환영합니다. ")]),_c('li',{staticClass:"logout_btn"},[_c('button',{staticClass:"fas fa-sign-out-alt btn_type_gray",attrs:{"type":"button","title":"로그아웃"},on:{"click":_vm.logout}},[_vm._v(" 로그아웃 ")])])])]),_c('router-view',{attrs:{"save-user-data":_vm.saveUserData,"save-menu-data":_vm.saveMenuData}}),_vm._m(0)],1)]:[_c('router-view')]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dq_footer"},[_vm._v(" Copyright © "),_c('strong',[_vm._v("mind-on")]),_vm._v(". All rights reserved. ")])}]

export { render, staticRenderFns }