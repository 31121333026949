<template>
  <div
    id="wrapper"
    :class="{ off: nav.fold }"
  >
    <!-- left area start //isAuthenticated-->
    <template v-if="type === 'layout'">
      <div
        class="left_area gray"
      >
        <!-- left head start -->
        <div class="left_head">
          <a
            href="#"
            class="btn_navi"
            title="메뉴 접기"
            @click.prevent.stop="navFold($event)"
          />
          <h1>mind-on 전문가</h1>
          <button
            type="button"
            class="btn_navi_close"
            title="메뉴 닫기"
          /><!-- mobile element -->
        </div>
        <!--// left head end -->

        <!-- navi start : 활성화 a 에 .on 추가 -->
        <ul class="navi">
          <li
            v-for="row in menuList"
            :key="row.menuView"
            @mouseover.prevent.stop="naviMouse($event, row)"
            @mouseout.prevent.stop="naviMouse($event, row)"
            @click="menuhide"
          >
            <template v-if="row.children.length>0">
              <a
                href="#"
                :class="{
                  on: (foldCheck && row.active && onCheck)
                    || (!foldCheck&& mouse.over && mouse.children !== false && mouse.children.indexOf(row.menuView) !== -1 )
                    || (!foldCheck&& mouse.over && mouse.children === false && row.menuView == mouse.name),
                  open: row.active,
                  on_view: row.active,
                  'fas fa-building': row.menuView === 'ExpEap',// - 마인드온 (EAP)
                  'fas fa-user-friends': row.menuView === 'ExpCou',// - 마인드톡 (일반상담)
                  'fas fa-robot': row.menuView === 'ExpAi',// - AI 상담분석
                  'fas fa-user-circle': row.menuView === 'ExpMy',// - 마이 페이지
                }"
                @click.prevent.stop="naviShow($event, row)"
              >
                <span>
                  {{ row.menuName }}
                </span>
                <i class="fas fa-angle-down" />
              </a>
              <transition name="slide">
                <div
                  v-if="(foldCheck && row.active) || (!foldCheck && mouse.over)"
                  class="sub_wrap depth2_wrap"
                  :class="{ show: (foldCheck && row.active)}"
                >
                  <ul>
                    <li
                      v-for="subMenu in row.children"
                      :key="subMenu.menuView"
                      @mouseover.prevent.stop="naviMouse($event, subMenu)"
                      @mouseout.prevent.stop="naviMouse($event, subMenu)"
                    >
                      <router-link
                        :to="{ path: subMenu.url }"
                        :class="{
                          on: menu.on === subMenu.menuView || menu.on.indexOf(subMenu.menuView) > -1
                        }"
                        @click.native="naviShow($event, subMenu)"
                      >
                        {{ subMenu.menuName }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </transition>
            </template>
            <template v-else>
              <router-link
                :to="{ path: row.url }"
                :class="{
                  on: (menu.on === row.menuView && foldCheck) ||!foldCheck && mouse.over&& row.menuView == mouse.name,
                  on_view: (menu.currMenu === row.menuView && !foldCheck) ||!foldCheck && mouse.over&& row.menuView == mouse.name,
                  'fas fa-tachometer-alt': row.menuView === 'ExpDash', // - 대시보드
                  'fas fa-comments': row.menuView === 'ExpCoun', // - 나의 상담실
                  'fas fa-calendar-check': row.menuView === 'ExpSch', // - 상담 일정 관리
                }"
                @click="menu.currMenu = row.menuView"
              >
                <span>{{ row.menuName }}</span>
              </router-link>
            </template>
          </li>
        </ul>
        <!--// navi end -->

        <div class="navi_foot">
          <button
            type="button"
            @click="showPersona"
          >
            개인정보처리방침
          </button>
          <button
            type="button"
            @click="showPolicy"
          >
            이용약관
          </button>
        </div>
      </div>

      <div
        class="right_area"
      >
        <!-- right head start -->
        <div class="right_head">
          <button
            type="button"
            class="m-menu"
            title="메뉴 열기"
          /><!-- mobile element -->
          <h2 class="float-left">
            <template v-if="menu.navDepth.length>0">
              <template v-for="depth in menu.navDepth">
                {{ depth }} &gt;
              </template>
            </template>
            <strong>{{ menu.navTitle }}</strong>
          </h2>
          <button
            type="button"
            class="m-btn_config far fa-user"
            title="설정"
          /><!-- mobile element -->
          <ul>
            <li class="center_btn">
              <button
                type="button"
                class="far fa-building"
                @click="showCenterPopup()"
              >
                내 센터 목록
              </button>
            </li>
            <li class="user_setup m-d-none">
              <button
                type="button"
                class="fas fa-user"
                title="정보 수정"
                @click="showExpertInfoEdit"
              >
                정보 수정
              </button>
            </li>
            <li class="user_info">
              <span>전문가 ({{ dqMindUserId }})</span>님 환영합니다.
            </li>
            <li class="logout_btn">
              <button
                type="button"
                class="fas fa-sign-out-alt btn_type_gray"
                title="로그아웃"
                @click="logout"
              >
                로그아웃
              </button>
            </li>
          </ul>
        </div>
        <!--<router-link to="/login">
            Login
          </router-link> |
          <router-link to="/about">
            About
          </router-link>-->
        <router-view
          :save-user-data="saveUserData"
          :save-menu-data="saveMenuData"
        />
        <!-- footer start -->
        <div class="dq_footer">
          Copyright © <strong>mind-on</strong>. All rights reserved.
        </div>
        <!--// footer end -->
      </div>
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>
<script>
import LoginService from '@/services/LoginService';
import { mapGetters } from "vuex";
import EventBus from '@/eventBus/eventBus.js';
import localData from './api/localData';
import CenterPopup from "./components/common/CenterPopup";
import ExpertInfoEdit from "./components/expert/ExpertInfoEdit";
import ExpertComInfoEdit from "./components/expert/ExpertComInfoEdit";
import CommonService from "./services/common/CommonService";
import {deleteToken} from "@/utils/FirebaseService";
import router from "./router";

export default {
  name: "App",
  data() {
    return {
      type: '',
      menu: {
        fold: false,
        currMenu: '',
        on: '',
        onView: '',
        open: false,
        navTitle: '',
        navDepth: [],
      },
      onCheck: false,
      foldCheck: true,
      navTimeout: null,
      menuList: [],
      nav: {
        tree: [],
        loading: true,
        dep: "",
        fold: false,
      },
      mouse:{
        over: false,
        name: null,
        children: false
      },
      naviMouseTimeout: null,
      dqMindUserId:'',
      dqMindExpTp: '',
      saveMenuData: [],
      saveUserData:{
        companyCode: null,
        custId: null,
        deptNm: null,
        email: null,
        inteMbrId: null,
        mbrId: null,
        mbrTp: null,
        modId: null,
        nm: null,
        posNm: null,
        pwd: null,
        roleId: null,
        sign: null,
        tel: null,
        username: null,
      },
      tab: {
        main: ['ExpCouVou','ExpMyCon','ExpMyCom','ExpAiRec', 'ExpMyRev','ExpCoun'],
        sub: ['ExpCouVouCus','ExpMyConDetail','ExpMyConCal','ExpMyComDet','ExpAiRecRes', 'ExpMyRevTalk','ExpCounTalk']
      }
    }
  },
  computed:{
    ...mapGetters(["isAuthenticated"]),
  },
  watch:{
    $route: function () {
      this.type = this.$route.meta.type;
      if (this.type === 'layout') {
        this.changeNav();
        const meta = this.$route.meta;
        if(meta.navTitle){
          document.title = '전문가 관리도구>'+(meta.navDepth ? meta.navDepth.join('>') +'>': '')+meta.navTitle;
        }else{
          document.title = '전문가 관리도구';
        }
      }else{
          document.title = '전문가 관리도구';
        }
      if(localData.getUserId() != null){
        this.dqMindUserId = localData.getUserId();
      }
      if(localData.getSaveUserData() != null){
        this.saveUserData = localData.getSaveUserData();
        this.$route.params.saveuserdata = this.saveUserData;
      }
      if(localData.getSaveMenuData() != null){
        this.saveMenuData = localData.getSaveMenuData();
        this.$route.params.saveMenuData = this.saveMenuData;
      }
      if(localData.getExpTp() != null) {
        this.dqMindExpTp = localData.getExpTp();
      }
    }
  },
  created(){
    EventBus.$on('login', (data = {}) => {
      this.fetchCenterList('login');
      this.fetchMenuData();
    });
    EventBus.$on('ExpMyCenterChange', (data = {}) => {
      this.$router.go(0);//새로고침
    });
  },
  mounted() {
    if(this.$route.name !== 'Login' && localData.getUserId()){
      this.dqMindUserId = localData.getUserId();
      this.fetchMenuData();
      this.fetchCenterList();
    }
    this.type = this.$route.meta.type;
    if (this.type === 'layout') {
      this.changeNav();
    }
  },
  methods:{
    showPersona() {
      router.push({ name: 'PersonalInfo' })
    },
    showPolicy(){
      router.push({ name: 'AccessTerms' })
    },
    fetchCenterList(flag) {
      LoginService.getMyCenterList().then(response => {
        const data = response.data;
        /*let testData = [{cenDirYn: -1,
          centerNm: "삼성사내상담센터",
          consCenterId: 3,
          inteMbrId: "20.000.smaexp1",
          rel: -1
        }];
        localData.setMyCenterList(testData);
        if(localData.getSelMyCenterData() == null){
          localData.setSelMyCenterData(testData[0]);
        }*/
        if(data.data && data.data.length>0){
          localData.setMyCenterList(data.data);
          if(localData.getSelMyCenterData() == null){
            localData.setSelMyCenterData(data.data[0]);
          }
          if(flag=='login' && data.data.length>1){
            this.showCenterPopup('login');
          }
        }
      })
    },
    menuhide() {
      $('.left_area').removeClass('on');
      $('.m-menu').removeClass('on');
      $('#wrapper').removeClass('bg');
    },
    showExpertInfoEdit() {
      if(this.dqMindExpTp === '1') { // 외부
        this.$modal.show(
          ExpertInfoEdit,{
            loginId: this.dqMindUserId
          },
          {
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      }else { // 사내
        this.$modal.show(
          ExpertComInfoEdit,{
            loginId: this.dqMindUserId
          },
          {
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      }
    },
    showCenterPopup(flag=null) {
      this.$modal.show(
        CenterPopup,{
          type:flag
        },
        {
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    fetchMenuData(){
      LoginService.getMyMenuList().then(response => {
        const {data} = response;
        var routeName = this.$route.name;

        if ( Object.keys(this.$route.params).length !== 0 ) {
          if(this.tab.sub.filter(n=>n === routeName).length > 0) {
            this.tab.main.forEach((item, index) => {
              if(routeName.indexOf(item) !== -1) {
                routeName = item;
              }
            })
          }
        }else{
        }
        data.data.forEach((row, index) => {
          //AI상담분석 안쓰는 메뉴 임시 숨기기
          if(row.menuName === 'AI 상담분석'){
            row.menuName = 'AI 상담사';
          }
          if(row.menuView === "ExpAi"){
            row.children = [{
              children: [],
              lvl: "2",
              menuName: "AI 상담분석",
              menuView: "ExpAiRec",
              url: "/web/exp/ai/rec",
              urlAuth: ""
            }];
          }
          row.children.forEach((childrenRow, childrenIndex) => {
            // 선택 메뉴 체크
            if (routeName == childrenRow.menuView) {
              row.active = true;
            }
            if (childrenRow.hasOwnProperty("children")) {
              childrenRow.children.forEach((childrenRow2, childrenIndex2) => {
                // 선택 메뉴 체크
                if (routeName == childrenRow2.menuView) {
                  row.active = true;
                  childrenRow.active = true;
                }
              })
            }
          })
        })
        this.menuList = data.data;
      }).catch(err => {
        throw new Error(err)
      })
    },
    naviMouse(event, navi){
      if(this.nav.fold && event.type === 'mouseover'){
        clearTimeout(this.naviMouseTimeout);
      }
      this.naviMouseTimeout = setTimeout(() => {
        if(this.nav.fold && event.type === 'mouseover'){
          this.mouse.over = true;
          if(navi.lvl == '1'){
            this.mouse.name = navi.menuView;
            this.mouse.children = false;
          }else if(navi.lvl == '2'){
            this.mouse.name = navi.menuView.split(/(?=[A-Z])/)[0] + navi.menuView.split(/(?=[A-Z])/)[1];
            if(navi.hasOwnProperty('children') && navi.children.length>0){
              this.mouse.children = navi.menuView;
            }else{
              this.mouse.children = false;
            }
          }else if(navi.lvl == '3'){
            let childArray = navi.menuView.split(/(?=[A-Z])/);
            childArray.pop();
            this.mouse.children = childArray.join('');
            this.mouse.name = childArray[0];
          }
        }else if(this.nav.fold && event.type === 'mouseout'){
          if(this.mouse.children === false){
            this.mouse.name = null;
          }else{
            this.mouse.children = false;
            this.mouse.name = null;
            /*if(type==='child'){
              this.mouse.children = false;
              this.mouse.name = null;
            }*/
          }

        }
      })

    },
    naviShow(event, navi){
      if (this.nav.fold && event.type === "click") {
        return false;
      } else if (
        !this.nav.fold &&
        (event.type === "mouseover" || event.type === "mouseout")
      ) {
        return false;
      }
      if (
        (event.type !== "mouseover" && event.type !== "mouseout") ||
        this.nav.fold === true
      ) {
        // 타임아웃 추가
        if (event.type == "mouseover" || event.type == "click") {
          clearTimeout(this.navTimeout);
        }
        this.navTimeout = setTimeout(() => {
          if (navi.hasOwnProperty("active")) {
            if (event.type == "click") {
              navi.active = !navi.active;
              this.onCheck = !this.onCheck;
            } else if (event.type == "mouseout") {
              navi.active = false;

              this.onCheck = false;
            } else if (event.type == "mouseover") {
              navi.active = true;
              this.onCheck = true;
            }
          } else {
            navi.active = true;
            this.onCheck = true;
          }

          if (navi.active) {
            // 다른 메뉴 닫기
            this.menuList.forEach(row => {

              var routeName = this.$route.name;

              if ( Object.keys(this.$route.params).length !== 0 ) {
                if(this.tab.sub.filter(n=>n === routeName).length > 0) {
                  this.tab.main.forEach((item, index) => {
                    if(routeName.indexOf(item) !== -1) {
                      routeName = item;
                    }
                  })
                }
              } else {
              }
              // 1 depth
              if (navi.lvl == '1') {
                if (row.hasOwnProperty("active")) {
                  if (navi.menuView != row.menuView) {
                    row.active = false;
                  }
                }
              }
              row.children.forEach((childRow) => {
                if (navi.lvl == '2') {
                  if (childRow.hasOwnProperty("active")) {
                    if (navi.menuView != childRow.menuView) {
                      childRow.active = false;
                    }
                  }
                }
                if (navi.lvl == '1') {
                  childRow.active = false;
                  if (childRow.hasOwnProperty("children")) {
                    childRow.children.forEach((childRow2) => {
                      if (
                        routeName === childRow2.menuView &&
                        childRow.active === false
                      ) {
                        childRow.active = true;
                      }
                    });
                  }
                }
              });
            });
          }

          this.menuList = JSON.parse(JSON.stringify(this.menuList));
        }, 0);
      }
    },
    navFold(event) {
      this.nav.fold = !this.nav.fold;
      this.foldCheck = !this.foldCheck;
      if (this.foldCheck === false) {
        this.menuList.forEach((row, index) => {
          var routeName = this.$route.name;

          if ( Object.keys(this.$route.params).length !== 0 ) {
            if(this.tab.sub.filter(n=>n === routeName).length > 0) {
              this.tab.main.forEach((item, index) => {
                if(routeName.indexOf(item) !== -1) {
                  routeName = item;
                }
              })
            }
          } else {
          }
          if (row.hasOwnProperty("children")) {
            var rowName = "";
            row.children.forEach((childrenRow, childrenIndex) => {
              if (routeName === childrenRow.menuView) {
                row.active = true;
                rowName = row.menuView;
              } else {
                if (row.menuView !== rowName) {
                  row.active = false;
                } else {
                  row.active = true;
                }
              }
              if (childrenRow.hasOwnProperty("children")) {
                var name = "";
                childrenRow.children.forEach((childrenRow2, childrenIndex2) => {
                  if (routeName === childrenRow2.menuView) {
                    childrenRow.active = true;
                    row.active = true;
                    name = childrenRow.menuView;
                    rowName = row.menuView;
                  } else {
                    if (childrenRow.menuView !== name) {
                      childrenRow.active = false;
                    } else {
                      childrenRow.active = true;
                    }
                  }
                });
              }
            });
          }
        });
      }
    },
    clickMenu(event, row){
      if (
        event.ctrlKey ||
        event.shiftKey ||
        event.metaKey || // apple
        (event.button && event.button == 1) // middle click, >IE9 + everyone else
      ){
        window.open(row.MENU.URL,"_blank");
      }
    },
    changeNav(){
      //let routeName = routeName.charAt(0).toUpperCase() + routeName.slice(1);

      let noChildRouteName = this.$route.name;

      if(this.tab.sub.filter(n=>n === noChildRouteName).length > 0) {
        this.tab.main.forEach(item => {
          if(noChildRouteName.indexOf(item) !== -1) {
            noChildRouteName = item;
          }
        })
      }
      this.menu.currMenu = noChildRouteName;
      this.menu.on = this.menu.currMenu;
      this.menu.onView = this.menu.currMenu;
      const meta = this.$route.meta;
      if (meta.navDepth) {
        this.menu.navDepth = meta.navDepth;
      }else {
        this.menu.navDepth = [];
      }
      this.menu.navTitle = meta.navTitle;

      this.menuList.forEach((row, index) => {
        var routeName = this.$route.name;

        if ( Object.keys(this.$route.params).length !== 0 ) {
          if(this.tab.sub.filter(n=>n === routeName).length > 0) {
            this.tab.main.forEach((item, index) => {
              if(routeName.indexOf(item) !== -1) {
                routeName = item;
              }
            })
          }
        } else {
          if(this.tab.sub.filter(n=>n === routeName).length > 0) {
            this.tab.main.forEach((item, index) => {
              if(routeName.indexOf(item) !== -1) {
                routeName = item;
              }
            })
          }
        }

        if ( row.hasOwnProperty('children') ) {
          var rowName = '';
          row.children.forEach((childrenRow, childrenIndex) => {
            if ( routeName === childrenRow.menuView ) {
              row.active = true;
              rowName = row.menuView;
            } else {
              if (row.menuView !== rowName) {
                row.active = false;
              } else {
                row.active = true;
              }
            }

            if (childrenRow.hasOwnProperty("children")) {
              var name = "";
              childrenRow.children.forEach((childrenRow2, childrenIndex2) => {
                if (routeName === childrenRow2.menuView) {
                  childrenRow.active = true;
                  row.active = true;
                  name = childrenRow.menuView;
                  rowName = row.menuView;
                } else {
                  if (childrenRow.menuView !== name) {
                    childrenRow.active = false;
                  } else {
                    childrenRow.active = true;
                  }
                }
              });
            }
          });
        }
      });

    },
    logout(){
      LoginService.logout().then(response => {
        if(response.data && response.data.logout == true){
          deleteToken(localData.getInteMbrId(),20);
          localData.removeUserId();
          localData.removeInteMbrId();
          localData.removeExpTp();
          localData.removeSaveUserData();
          localData.removeSaveMenuData();
          localData.removeMyCenterList();
          localData.removeSelMyCenterData();
          this.$router.push({
            name: 'Login',
          });
        }
      }).catch(err => {
        throw new Error(err)
      })
    },
  }
}
</script>

<style>

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* vue select css */
.vs__selected-options {
  width: 0 !important;
}

.vs__selected {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: block !important;
  width: 100% !important;
}

.vue-treeselect__control,
.vs__dropdown-toggle {
  color: #333 !important;
  border-radius: 2px !important;
  height: 32px !important;
}

.vs__open-indicator {
  width: 20px;
  height: 20px;
}

.vs__open-indicator:after {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  display: inline-block;
  content: "";
  overflow: hidden;
  width: 20px;
  height: 20px;
  background: url(./assets/img/sp.png) 0 0 no-repeat;
  background-size: 300px auto;
  background-position: 0 -48px;
}
.vue-treeselect__limit-tip {
  display: inline-block;
  padding-top: 3px;
  padding-right: 5px;
  vertical-align: top;
  padding-left:5px;
}
.vue-treeselect__limit-tip-text {
  cursor: default;
  display: block;
  margin: 1px 0 !important;
  padding: 1px 0;
  color: #333 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 30px !important;
}
.vue-treeselect__multi-value-item {
  padding: 1px 0 !important;
}
/*virtual select*/
.vscomp-wrapper{
  font-family:'Noto Sans KR' !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.vscomp-toggle-button{
  color: #333 !important;
  border-radius: 2px !important;
  height: 32px !important;
}
.vscomp-search-container{
  height: 32px !important;
}
.vscomp-search-input{
  font-size: 13px !important;
}
.vscomp-ele {
  max-width: 100% !important;
}
.vscomp-wrapper.focused .vscomp-toggle-button, .vscomp-wrapper:focus .vscomp-toggle-button{
  box-shadow: none !important;
}
/* transition slide */
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 330px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
/* transition fade */

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* slide-out */
.slide-out-mask {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
}

.slide-out {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.5s ease;
}

.slide-out.view-left {
  -webkit-transform: translate3d(-70%, 0, 0);
  transform: translate3d(-70%, 0, 0);
}

.slide-out.view-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slide-out .left {
  background-color: #fff;
  width: 30%;
}

.slide-out .right {
  background-color: #fff;
  width: 70%;
}

.slide-out .left,
.slide-out .right {
  display: inline-block;
}

#slide-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-weight: 100;
  z-index: 2;
}

.slide-out-enter-active {
  animation: slide-out-in 0.5s;
}
.slide-out-leave-active {
  animation: slide-out-in 0.5s reverse;
}
@keyframes slide-out-in {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-70%, 0, 0);
    transform: translate3d(-70%, 0, 0);
  }
}
</style>
