const TEST_TOKEN_KEY = "test_token";

export const getToken = () => {
  return window.localStorage.getItem(TEST_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(TEST_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(TEST_TOKEN_KEY);
};
export default { getToken, saveToken, destroyToken };