import http from '@/api/http';

export default {
  // SelectBox 전문가구분
  expertInfoExpTpList() {
    return http.get('/api/code/findAllList?cdDiv=전문가구분');
  },
  // SelectBox 자격정보
  expertInfoLicenseList() {
    return http.get('/api/code/findAllList?cdDiv=자격정보');
  },
  // SelectBox 전문분야
  expertInfoDepartList() {
    return http.get('/api/code/findAllList?cdDiv=전문분야');
  },
  // SelectBox 상담주제
  expertInfoTopicList() {
    return http.get('/api/code/findAllList?cdDiv=상담주제');
  },
  // 기본이용권표준코드
  expertInfoVoucherCodeList() {
    return http.get('/api/code/findAllList?cdDiv=기본이용권표준코드');
  },
  // Tab1 기본 정보 등록
  expertInfoTab1Create(form) {
    return http.post('/api/exp/my/createTab1',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab2 상세 정보 등록
  expertInfoTab2Create(form) {
    return http.put('/api/exp/my/updateTab2',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab3 상담 비용 설정 등록
  expertInfoTab3Create(form) {
    return http.put('/api/exp/my/updateTab3',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 비밀번호 초기화
  sendTemplateEmail(params={}) {
    return http.post('/api/email/template/send-each-mail', params);
  },
  // 비밀번호 변경
  expertInfoPassChange(params) {
    return http.post('/api/common/passChange?inteMbrId='+params.inteMbrId+'&existPwd='+params.existPwd+'&pwd='+params.pwd);
  },

  // Tab1 기본 정보 조회
  expertInfoTab1Read(inteMbrId={}) {
    return http.get('/api/exp/my/readTab1?inteMbrId='+inteMbrId);
  },

  // Tab1 기본 정보 수정
  expertInfoTab1Update(form) {
    return http.put('/api/exp/my/updateTab1',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },

  // Tab2 기본 정보 조회
  expertInfoTab2Read(inteMbrId={}) {
    return http.get('/api/exp/my/readTab2?inteMbrId='+inteMbrId);
  },

  // Tab2 상세 정보 수정
  expertInfoTab2Update(form) {
    return http.put('/api/exp/my/updateTab2',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },

  // Tab3 상담 비용 설정
  expertInfoTab3Read(inteMbrId={}) {
    return http.get('/api/exp/my/readTab3?inteMbrId='+inteMbrId);
  },

  // Tab1 기본 정보 조회
  expertInfoTab4Read(inteMbrId={}) {
    return http.get('/api/exp/my/readTab4?inteMbrId='+inteMbrId);
  },

  // 외부 센터 목록 조회
  expertInfoCenterList() {
    return http.get('/api/consult-center/convention/center/findList?consCenterTp=Q');
  },

  // Tab4 부가정보 추가/수정
  expertInfoTab4Update(form) {
    return http.put('/api/exp/my/updateTab4',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },




}
