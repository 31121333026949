import http from '@/api/http';

export default {
  // SelectBox 전문가구분
  expertJoinExpTpList() {
    return http.get('/api/code/findAllList?cdDiv=전문가구분');
  },
  // SelectBox 자격정보
  expertJoinLicenseList() {
    return http.get('/api/code/findAllList?cdDiv=자격정보');
  },
  // SelectBox 전문분야
  expertJoinDepartList() {
    return http.get('/api/code/findAllList?cdDiv=전문분야');
  },
  // SelectBox 상담주제
  expertJoinTopicList() {
    return http.get('/api/code/findAllList?cdDiv=상담주제');
  },
  // 기본이용권표준코드
  expertJoinVoucherCodeList() {
    return http.get('/api/code/findAllList?cdDiv=기본이용권표준코드');
  },
  // Tab1 기본정보 등록
  expertJoinTab1Create(form) {
    return http.post('/api/exp/my/createTab1',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab1 아이디 중복체크
  expertJoinTab1IdCheck(params={}) {
    return http.get('/api/exp/my/idDupleCheck',{params:params});
  },
  // Tab2 상세정보 등록
  expertJoinTab2Create(form) {
    return http.post('/api/exp/my/createTab2',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab3 등록
  expertJoinTab3Create(form) {
    return http.post('/api/exp/my/createTab3',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // Tab4 부가정보 추가/수정
  expertJoinTab4Update(form) {
    return http.post('/api/exp/my/createTab4',form,{headers: {
        'Content-Type': 'multipart/form-data'
      }});
  },
  // 외부 센터 목록 조회
  expertJoinCenterList() {
    return http.get('/api/consult-center/convention/center/findList?consCenterTp=Q');
  },
}
