<template>
  <!-- modal : 목소리 녹음 start -->
  <div
    id="add_rec"
    class="modal fade type_w600 show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add_rec"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            목소리 녹음
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">
              ×
            </span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <div class="box-blue mt-5px p-20px record_control row align-items-center">
              <div class="col-7 text-center">
                <button
                  type="button"
                  class="btn btn-primary type_record btn-record"
                  title="녹음"
                  :class="{'on':isRecorder}"
                  @click="onRecorder"
                /><!-- 녹음버튼 클릭시 .on 추가 / 플레이시 disabled-->
                <button
                  type="button"
                  class="btn btn-primary type_record btn-play"
                  :class="{'on': wavesurfer && wavesurfer.isPlaying()}"
                  title="플레이"
                  :disabled="!wavesurfer || wavesurfer.isPlaying()"
                  @click.prevent.stop="wavesurfer.playPause()"
                /><!-- 플레이버튼 클릭시 .on 추가 / 녹음중일시 disabled -->
                <button
                  type="button"
                  class="btn btn-primary type_record btn-stop"
                  title="스톱"
                  :disabled="!wavesurfer || !wavesurfer.isPlaying()"
                  @click.prevent.stop="wavesurfer.playPause()"
                /><!-- 녹음 또는 플레이시 활성 -->
              </div>
              <div class="col-5">
                <ul class="list_dot list_b5px">
                  <li>녹음된 음성 길이 : <span class="text-danger">{{ second ? second.toFixed(2) : 0 }}초</span></li>
                </ul>
              </div>
              <div class="mt-20px w-100p h-50px">
                <!--녹음 파형 위치 (w100% x h50px)-->
                <div id="waveform" />
              </div>
            </div>
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="saveData"
          >
            저장
          </button><!-- 내용을 수정했을 경우 disabled 삭제하여 버튼 활성화 -->
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal : 목소리 녹음 end -->
</template>
<script>
import moment from "moment";
import EventBus from '@/eventBus/eventBus.js';
// WaveSurfer
import WaveSurfer from "wavesurfer.js";
import ExpertInfoService from "../../services/expert/ExpertInfoService";
import ExpertJoinService from "../../services/expert/ExpertJoinService";

export default {
  name: 'ExpertJoinRec',
  components: {

  },
  props:{
    loginId: {
        type: String,
        default: null,
        required: false
    },
    type: {
        type: String,
        default: null,
        required: false
    },
  },
  data(){
    return{
      recognize: null,
      message: null,
      isRecorder: false,
      recordDisabled: true,
      progress: 0,
      /* audio
      btnAudio: new Audio(
        require("@/assets/audio/zapsplat_multimedia_button_click_007_53868.mp3")
      ),*/
      second: 0,
       // 오디오
      audioContext: null,
      gumStream: null,
      rec: null,
      input: null,
      bufSize: null,
      microphone: null,
      processor: null,
      res: null,
      bufferArray: null,
      
      file: {},
      room: {
        cr_seq: null,
        server_id: null,
        cr_name: null,
        cr_type: null,
        cr_topic: null,
        pub_yn: null,
        cr_password: null,
        reg_id: null,
        reg_dt: null,
        mod_dt: null,
        close_dt: null,
        aiab_cr_user: null
      },

      btnTimer: null,
      
      waveCheck: 0,

       // wavesurfer
      wavesurfer: null,
      isLoading: false,
      isRecorderDisabled: false,
      isTextPlaying: false,
      
    }
  },
  computed:{
    isPlaying() {
      if (!this.wavesurfer) return false;
      return this.wavesurfer.isPlaying();
    },
    recordState: function() {
      // 검토중 .standby / 녹음중 .recording / 재생중 .playing / 음성인식중 .recognize
      if (this.isRecorder && 0 < this.second) {
        if (this.recognize) {
          return {
            state: "recognize",
            class: "recognize",
            text: "음성인식중"
          };
        } else {
          return {
            state: "recording",
            class: "recording",
            text: "녹음중"
          };
        }
      } else {
        if (this.isPlaying) {
          return {
            state: "playing",
            class: "playing",
            text: "재생중" // this.isPlaying ? "재생" : "중지"
          };
        } else {
          return {
            state: "standby",
            class: "standby",
            text: "대기중"
          };
        }
      }
    }
  },
  watch:{
    progress: function(val) {
      if (val == 0) {
        this.waitRequest = false;
        this.recordDisabled = false;
      }
    },
    file: function(val) {
      if (val) {
        try {
          const file = new File([val.blob], val.name);
          this.wavesurfer.loadBlob(file);
        } catch (e) {
          alert(e);
        }

        /* 자동재생 빼기
        setTimeout(() => {
          this.wavesurfer.play();
        }, 100);
        */
      } else {
        // this.destroyWaveSurfer();
      }
    },
  },
  destroyed(){

  },
  created(){

  },
  // 검토중 .standby / 녹음중 .recording / 재생중 .playing / 음성인식중 .recognize
  mounted(){

  },
  methods:{
    onRecorder() {
      clearInterval(this.btnTimer);
      this.btnTimer = setTimeout(() => {
        if (!this.recognize) {
          if (this.message == null) {
            if (this.isRecorder) {
              this.stopRecording();
              //this.btnAudio.play();
            } else {
              this.startRecording();
              //this.btnAudio.play();
            }
          } else {
            // document.getElementById("messageForm").reset();
            this.isRecorderDisabled = true;
            this.stopRecording(true, true);
            this.secondReset(); //초 리셋
            //this.btnAudio.play();
            setTimeout(() => {
              this.isRecorderDisabled = false;
              this.startRecording();
            }, 100);
          }
        }
      }, 200);
    },
    startRecording() {
      this.startRecordingStart();
    },
    startRecordingStart() {
      var constraints = { audio: true, video: false };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(this.startUserMedia)
        .catch(err => {
          console.log(err);
          console.log(err.name);

          if (
            err.name === "NotFoundError" ||
            err.name === "DevicesNotFoundError"
          ) {
            // required track is missing
            // constraints 매개변수 조건에 맞는 미디어 트랙이 없는 경우 이 에러가 발생합니다.
          } else if (
            err.name === "NotReadableError" ||
            err.name === "TrackStartError"
          ) {
            // webcam or mic are already in use
            // 사용자가 접근 권한을 부여했고 조건에 맞는 미디어 트랙도 있지만 어떤 이유로든 장치에 액세스 할 수 없어서 운영체제, 브라우저, 웹 페이지 레벨에서 하드웨어 에러가 발생하여 이 에러가 발생합니다.
          } else if (
            err.name === "OverconstrainedError" ||
            err.name === "ConstraintNotSatisfiedError"
          ) {
            // constraints can not be satisfied by avb. devices
          } else if (
            err.name === "NotAllowedError" ||
            err.name === "PermissionDeniedError"
          ) {
            // permission denied in browser
            // 사용자가 브라우저 설정을 통해 장치에 대한 접근권한을 차단하였거나 장치 사용 권한 요청에 거부한 경우 이 에러가 발생합니다. 이 외에도 어떤 식으로든 장치에 대한 접근을 차단하였다면 이 에러가 발생합니다.
          } else if (err.name === "TypeError" || err.name === "TypeError") {
            // empty constraints object
          } else {
            // other errors
          }

          // alert("마이크가 없습니다. 마이크를 연결해 주세요 : " + err);
          alert(
            "마이크에 대한 접근 권한이 없습니다.\n휴대폰 설정을 통해 해당 브라우저에 대한  마이크 접근 권한을 허용해 주세요"
          );
          this.isRecorder = false;
          // DOMException: Requested device not found
        });
    },
    startUserMedia(stream) {
      this.waveCheck = 0;

      if (!this.audioContext) {
        this.audioContext = new AudioContext();
      }
      this.bufSize = 4096;

      this.gumStream = stream;
      this.microphone = this.audioContext.createMediaStreamSource(stream);
      this.processor = this.audioContext.createScriptProcessor(
        this.bufSize,
        1,
        1
      );
      /*this.res = new Resampler(
        this.audioContext.sampleRate,
        16000,
        1,
        this.bufSize
      );*/

      this.bufferArray = [];

      this.processor.onaudioprocess = event => {
        try {
          const outBuf = event.inputBuffer.getChannelData(0); //변경함 (resample 제거)

          this.bufferArray.push.apply(this.bufferArray, outBuf);

          this.second = this.bufferArray.length / this.audioContext.sampleRate; //초
          if (60 <= this.second) {
            alert(
              "녹음이 끝나면 녹음버튼을 다시 눌러야 저장됩니다. \n다시 녹음해 주세요."
            );
            console.log("60초");
            // 셀바스 실시간 STT 종료
            this.stopRecording(true);
          }
        } catch (e) {
          console.log('에러',e)
          this.stopRecording(true);
        }
      };

      if (this.processor && this.microphone) {
        this.bufferArray = [];
        this.microphone.connect(this.processor);
        this.processor.connect(this.audioContext.destination);

        this.isRecorder = true;
      } else {
        this.isRecorder = false;
      }
    },
    secondReset() {
      this.second = 0;
    },
    stopRecording(cancel = false, isRecorder = false) {

      // alert(this.waveCheck);
      if (this.processor && this.microphone) {
        this.microphone.disconnect();
        this.processor.disconnect();
        this.gumStream.getAudioTracks()[0].stop();
        console.log("stopRecording processor disconnect");
        // this.file
      }

      if (cancel) {
        this.message = null;
        this.second = 0;
        this.gumStream = null;
        this.microphone = null;
        this.processor = null;
        this.res = null;
        this.bufSize = null;
        this.bufferArray = [];
        if (isRecorder) {
          this.isRecorder = true;
        }
        setTimeout(() => {
          if (isRecorder) {
            this.isRecorder = true;
          } else {
            this.isRecorder = false;
          }

          this.file = null;
        }, 100);
      } else {
        //create the wav blob and pass it on to createDownloadLink
        // this.rec.exportWAV(this.createDownloadLink);
        var pcm = new Float32Array(this.bufferArray);

        var dataview = this.encodeWAV(pcm);
        // console.log("dataview =============");
        // console.log(dataview);
        var audioBlob = new Blob([dataview], { type: "audio/wav" });

        this.recognize = "recognize";
        this.createDownloadLink(audioBlob);
      }
    },
    encodeWAV(samples) {
      console.log("encodeWAV ====");
      var numChannels = 1;
      var sampleRate = this.audioContext.sampleRate;
      var buffer = new ArrayBuffer(44 + samples.length * 2);
      var view = new DataView(buffer);

      /* RIFF identifier */
      this.writeString(view, 0, "RIFF");
      /* RIFF chunk length */
      view.setUint32(4, 36 + samples.length * 2, true);
      /* RIFF type */
      this.writeString(view, 8, "WAVE");
      /* format chunk identifier */
      this.writeString(view, 12, "fmt ");
      /* format chunk length */
      view.setUint32(16, 16, true);
      /* sample format (raw) */
      view.setUint16(20, 1, true);
      /* channel count */
      view.setUint16(22, numChannels, true);
      /* sample rate */
      view.setUint32(24, sampleRate, true);
      /* byte rate (sample rate * block align) */
      view.setUint32(28, sampleRate * 4, true);
      /* block align (channel count * bytes per sample) */
      view.setUint16(32, numChannels * 2, true);
      /* bits per sample */
      view.setUint16(34, 16, true);
      /* data chunk identifier */
      this.writeString(view, 36, "data");
      /* data chunk length */
      view.setUint32(40, samples.length * 2, true);

      this.floatTo16BitPCM2(view, 44, samples);

      return view;
    },
    writeString(view, offset, string) {
      for (var i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    floatTo16BitPCM2(output, offset, input) {
      for (var i = 0; i < input.length; i++, offset += 2) {
        var s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      }
    },
    createDownloadLink(blob) {
      var url = URL.createObjectURL(blob);

      var name = moment().format("DDMMYYYYhhmmss") + ".wav";
      this.file = {
        url,
        blob,
        name
      };
      this.message = this.senarioMsgText;
      this.isRecorder = false;
      this.recognize = null;

      this.gumStream = null;
      this.microphone = null;
      this.processor = null;
      this.res = null;
      this.bufSize = null;
      this.bufferArray = [];
        
      this.createWaveSurfer();
      if (this.second < 0.5) {
        this.message = null;
        this.recognize = null;

        this.gumStream = null;
        this.microphone = null;
        this.processor = null;
        this.res = null;
        this.bufSize = null;
        this.bufferArray = [];

        this.secondReset();
        alert(
          "음성녹음이 정상적으로 되지 않은것 같습니다.\n다시 녹음해 주세요"
        );
      }
    },
    createWaveSurfer() {
      if(this.wavesurfer==null){
        this.wavesurfer = WaveSurfer.create({
          container: "#waveform",
          hideScrollbar: true,
          waveColor: "#827fc9",
          progressColor: "#525398",
          cursorColor: "#ff0000",
          barWidth: 3,
          height: "55"
        });
      }
      /*
      this.wavesurfer.load(
        "https://ia902606.us.archive.org/35/items/shortpoetry_047_librivox/song_cjrg_teasdale_64kb.mp3"
      );
      */

      this.wavesurfer.on("error", err => {
        console.error(err);
        this.isLoading = false;
      });

      this.wavesurfer.on("loading", () => {
        this.isLoading = true;
      });

      this.wavesurfer.on("ready", () => {
        this.isLoading = false;
      });
    },
    destroyWaveSurfer() {
      if (this.wavesurfer) {
        this.wavesurfer.playPause();
        this.wavesurfer.destroy();
        // this.wavesurfer.cl
      }
    },
    saveData(){
      let tab2Form = new FormData();
      tab2Form.append(`ExpertTab2.inteMbrId`, this.loginId);
      tab2Form.append(`ExpertTab2.regId`,  this.loginId);
      tab2Form.append(`ExpertTab2.modId`,  this.loginId);
      // 녹음
      if(this.file && this.file.blob && this.file.name) {
        const file = new File([this.file.blob], this.file.name);
        tab2Form.append('ExpertTab2.voiceFile', file);
        tab2Form.append('ExpertTab2.recordingTime', this.second.toFixed(2) * 100); //곱하기 100 해서 저장 (보여줄때 100나눠서 보여줘야 됨)
      }else{
        alert('녹음 해주세요.');
        return;
      }
      ExpertInfoService.expertInfoTab2Create(tab2Form).then(response => {
        const data = response.data;
        alert(data.resultCode === 'ERROR' ? '저장을 실패했습니다.' : '저장 되었습니다.');
        EventBus.$emit('ExpertJoinRec',{});
        this.close();
      }).catch(err => {
      })
    },
    close() {
      this.$emit("close");
    },
  }
}

</script>
