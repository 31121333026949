<template>
  <!-- modal : 템플릿 등록/수정 start -->
  <div
    id="template_apply"
    class="modal fade type_w700 show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="template_apply"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <!-- modal title start -->
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            템플릿 수정
          </h5>
          <button
            type="button"
            class="close"
            @click="close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <!--// modal title end -->

        <!-- modal body start -->
        <div class="modal-body">
          <div class="inner_box">
            <div class="p-10 h-400px">
              <editor
                ref="toastuiEditor"
                height="400px"
                :options="defaultOptions"
                initial-edit-type="wysiwyg"
              />
            </div>
          </div>
        </div>
        <!--// modal body end -->

        <!-- modal footer start -->
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="close"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="saveData"
          >
            확인
          </button><!-- 내용을 수정했을 경우 disabled 삭제하여 버튼 활성화 -->
        </div>
        <!--// modal footer end -->
      </div>
    </div>
  </div>
  <!--// modal : 템플릿 등록/수정 end -->
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

export default {
  name: 'ExpertJoinTemplate',
  components: {
    editor: Editor,
  },
  data(){
    return{
      defaultOptions:{
        customHTMLRenderer: {
          htmlBlock: {
            iframe(node) {
              return [
                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                { type: 'html', content: node.childrenHTML },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
              ];
            },
          },
          htmlInline: {
            big(node, { entering }) {
              return entering
                ? { type: 'openTag', tagName: 'big', attributes: node.attrs }
                : { type: 'closeTag', tagName: 'big' };
            },
          },
        },
        hooks: {
          addImageBlobHook: (blob, callback) => {
            const uploadedImageURL = this.uploadImage(blob)
            callback(uploadedImageURL, 'alt text')
            return false
          },
        },
        hideModeSwitch: false,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['table', 'image', 'link'],
          ['code', 'codeblock'],
          ['scrollSync']
        ],
        plugins:[colorSyntax]
      }
    }
  },
  computed:{

  },
  destroyed(){

  },
  created(){

  },
  mounted(){

  },
  methods:{
    saveData() {

    },
    close() {
      this.$emit("close");
    },
  }
}
</script>


