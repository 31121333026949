$(function(){

//메뉴 반응형
	$(document).on('click', '.m-menu', function(){
		if($('#wrapper').hasClass('off')){
			$('#wrapper').removeClass('off');
			$('.btn_navi').attr('title','메뉴 접기');
			$('.navi').find('.on_view').removeClass('on_view').addClass('on');
			$('.navi').find('.open').removeClass('open');
			$('.navi').find('a.on').parents('.sub_wrap').show().prev('a').addClass('open on_view');
			$('.navi').find('.depth3_wrap').find('a.on').closest('.sub_wrap').prev('a').removeClass('on');
		}
		$(this).addClass('on');
		$('.left_area').addClass('on');
		$('#wrapper').addClass('bg');
	});

	$(document).on('click', '.btn_navi_close', function(){
		m_menu_hide();
	});

	$('.navi li').not(':has(.sub_wrap)').click(function(){
		m_menu_hide();
	});

	function m_menu_hide() {
		$('.left_area').removeClass('on');
		$('.m-menu').removeClass('on');
		$('#wrapper').removeClass('bg');	
	};

//m-설정 클릭
	$(document).on('click', '.m-btn_config', function() {
		var this_btn = $(this)
		m_config_click(this_btn);
		return false;
	});

	function m_config_click(this_btn){
		if(this_btn.hasClass('on')){
			m_config_hide(this_btn)
		}else{
			m_config_show(this_btn)
		}
		return false;
	}

	function m_config_hide(this_btn){
		this_btn.removeClass('on');
		this_btn.next('ul').hide();
	}

	function m_config_show(this_btn){
		this_btn.addClass('on')
		this_btn.next('ul').fadeIn(100);
	}

//배경 클릭
	$('html').click(function(event) {

		var window_width = $('html').outerWidth();
		var this_btn = $('.m-btn_config')
		
		if(!$(event.target).is(this_btn) && window_width <= 767){
			m_config_hide(this_btn)
		}
	 });

//리사이즈
	$(window).resize(function(){
		var window_width = $('html').outerWidth();
		if (window_width <= 767){
			if(!$('.m-menu').hasClass('on')){
				$('.left_area').removeClass('on');
			};
			var this_btn = $('.m-btn_config')
			m_config_hide(this_btn)
			m_hide_modal();

		}else{
			$('.left_area').addClass('on');
			$('.m-menu').removeClass('on');
			$('#wrapper').removeClass('bg');
			var this_btn = $('.m-btn_config')
			m_config_show(this_btn);
			search_hide();
		}
	});

//모달 감추기
	function m_hide_modal(){
		$('.m-hide-modal').modal('hide');
	};

//검색 창
	$(document).on('click', '.m-btn_search', function(){
		search_show();
	});
	$(document).on('click', '.btn_search_close', function(){
		search_hide();
	});

	function search_show(){
		$('.search_wrap').addClass('on');
		$('.content').addClass('off');
	};
	function search_hide(){
		$('.search_wrap').removeClass('on');
		$('.content').removeClass('off');
	};

});






window.onload = function() {

//목록 버튼 반응형 (옵션버튼)
	$(document).find('.tbl_head').each(function(e){

		$($(this).find('.tbl_btnset > button:not(.m-btn)').get().reverse()).each(function(){				
			var btnset = $(this).prop('outerHTML');
			var opt_btnset = '<li class="m-opt_btn">'+btnset+'</li>'

			$(this).closest('.tbl_head').find('.opt_menu ul').prepend(opt_btnset);
		})

	});


//반응형 div 테이블
	$('.d-table.responsive').each(function(){
		$(this).find('.d-table-row-group .d-table-cell').each(function(){
			var attr_tit = $(this).attr('th-text');
			var attr_tit_th = '<span class="attr_th"><span>'+attr_tit+'</span></span>';

			if(attr_tit != null){
				$(this).prepend(attr_tit_th);						
			};

		});
	});
};
